@use "../../../../../../assets/scss/colors";
@use "../../../../../../assets/scss/typography";

.insurance-step-3 {
  background: transparent;
  width: 100%;
}

.insurance-content-step-3 {
  width: 100%;
}

.Pv-kikkin-proposal {
  width: 570px;
  height: auto;
  min-height: 1362px;
  float: right;
  padding: 0;
  margin: 0 auto;
  margin-top: 40px;
}

.Pv-1-proposal {
  width: 570px;
  height: 135px;
  background: var(--k4n-tertiary-color);
  border-radius: 28px 28px 0px 0px;
  text-align: left;
}

.Pv-2-proposal {
  width: 570px;
  height: auto;
  min-height: 1190px;
  background: var(--k4n-secondary-light-color);
  border-radius: 20px 20px 28px 28px;
  text-align: left;
  position: relative;
  top: -40px;
}

.Pv-3-proposal {
  width: 312px;
  height: 165px;
  background: var(--k4n-tertiary-color);
  border-radius: 20px 20px 28px 28px;
  position: relative;
  top: -37.1%;
}

.Pv-1-title-proposal {
  width: 163px;
  height: 24px;
  font-family: typography.$font-bold;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 120%;
  /* identical to box height, or 24px */
  color: #ffffff;
  position: relative;
  top: 24px;
  left: 24px;
}

.Pv-2-title-proposal {
  width: 522px !important;
  height: 35px;
  font-family: typography.$font-bold;
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 120%;
  color: var(--k4n-primary-color);
  margin: 0 auto !important;
  padding: 0px 0px 6px 0px !important;
  border-bottom: 1px solid var(--k4n-secondary-color);
  transform: rotate(0.07deg);
}

.Pv-2-title-3-div-proposal {
  width: 522px !important;
  height: 60px;
  margin: 0 auto !important;
  padding: 0px 0px 6px 0px !important;
  border-bottom: 1px solid var(--k4n-secondary-color);
  transform: rotate(0.07deg);
}

.Pv-2-title-3-proposal {
  width: 263px;
  max-width: 263px;
  height: 60px;
  font-family: typography.$font-bold;
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 120%;
  color: var(--k4n-primary-color);
}

.Mx-wd-title-3 {
  text-align: left;
}

.Item-pv-2-3-proposal {
  width: 100%;
  height: 38px;
}

.Brk-spaces-proposal {
  white-space: break-spaces !important;
}

.Pv-3-title-proposal {
  width: 132px !important;
  height: 24px;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 120%;
  color: var(--k4n-secondary-color);
  position: relative;
  left: 24px;
  padding: 0;
  white-space: nowrap;
}

.Pv-3-symbol-proposal {
  width: 30px;
  height: 24px;
  font-style: normal;
  font-weight: bold;
  font-size: 20px !important;
  line-height: 120%;
  color: var(--k4n-secondary-color);
  padding: 0;
  margin-right: 5px;
  white-space: nowrap;
}

.Pv-3-symbol-small-proposal {
  width: 30px;
  height: 24px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px !important;
  line-height: 120%;
  color: var(--k4n-secondary-color);
  position: relative;
  left: 24px;
  padding: 0;
  white-space: nowrap;
}

.Pv-3-value-proposal {
  width: 64px !important;
  height: 24px;
  font-style: normal;
  font-weight: bold;
  font-size: 20px !important;
  line-height: 120%;
  color: #ffffff;
  padding: 0;
  white-space: nowrap;
  font-family: typography.$font-main;
}

.Pv-3-value-small-proposal {
  width: 72px !important;
  height: 24px;
  font-style: normal;
  font-weight: bold;
  font-size: 16x !important;
  line-height: 120%;
  color: #ffffff;
  position: relative;
  left: 24px;
  padding: 0;
  white-space: nowrap;
}

.Pv-1-subtitle-proposal {
  width: 144px;
  height: 22px;
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 120%;
  /* identical to box height, or 22px */
  color: #ffffff;
}

.Pv-1-subtitle-div-proposal {
  position: relative;
  top: 30px;
  left: 24px;
}

.Item-div-proposal {
  text-align: left;
  padding-left: 24px !important;
  padding-right: 0px !important;
}

.Valor-div-proposal {
  text-align: right;
  padding-right: 24px !important;
}

.Valor-mensalidade-proposal {
  padding-right: 19px !important;
  text-align: right;
}

.Div-item-2-proposal {
  height: 48px;
}

.Item-proposal {
  width: 60px;
  height: 19px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  font-family: typography.$font-main;
  line-height: 120%;
  white-space: pre-wrap;
  color: var(--k4n-primary-color);
}

.Check-span {
  position: relative;
  left: 36px;
  bottom: 3px;
  display: block;
}

.Pos-left-check {
  position: relative;
  left: -5%;
  margin-bottom: 103px;
}

.Valor-item-proposal {
  width: 100%;
  height: 17px;
  font-family: typography.$font-bold;
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 120%;
  // white-space: nowrap;
  color: var(--k4n-primary-color);
}

.Form-check-proposal-mobile {
  display: inline-flex;
}

.Ft-sz-14 {
  font-size: 14px !important;
}

.Container-item-proposal {
  width: 100% !important;
  margin: 32px 0 0 0 !important;
}

.Container-item-vigencia-proposal {
  width: 100% !important;
  margin: 24px 0 0 0 !important;
}

.Container-3-proposal {
  margin-top: 41px !important;
  width: 100%;
  margin-left: 0 !important;
}

.Container-valor-total-proposal {
  margin-top: 14px !important;
  width: 100%;
  margin-left: 0 !important;
}

.Col-mensalidade-proposal {
  padding: 0;
  text-align: left;
  padding-left: 24px !important;
  padding-right: 0px !important;
}

.Container-title-proposal {
  width: 100%;
  padding-top: 32px !important;
  margin: 0 auto !important;
}

.Container-title-2-proposal {
  width: 100%;
  margin: 0 auto !important;
  padding-top: 56px;
}

.Container-title-3-proposal {
  width: 263px !important;
  height: 48px !important;
  margin: 0 auto !important;
  padding-top: 47px;
}

.Container-top-proposal {
  margin-top: 32px !important;
}

.Txt-mensalidade-proposal {
  width: 118px;
  height: 24px;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 120%;
  color: rgba(255, 255, 255, 0.6);
  font-family: "typography.$font-bold;";
}

.Txt-valor-total-proposal {
  width: 121px;
  height: 19px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 120%;
  color: rgba(255, 255, 255, 0.6);
}

.Center-vertically-proposal {
  display: flex;
  align-items: center;
  justify-content: right;
}

.inline-flx-proposal {
  display: inline-flex;
}

.Pv-1-proposal-mobile {
  height: 135px;
  background: var(--k4n-tertiary-color);
  border-radius: 28px 28px 0px 0px;
  text-align: left;
}

.Pv-2-proposal-mobile {
  height: auto;
  min-height: 1135px;
  background: var(--k4n-secondary-light-color);
  border-radius: 20px 20px 28px 28px;
  text-align: left;
  position: relative;
  top: -40px;
  padding-bottom: 30px;
}

.Pv-kikkin-proposal-mobile {
  padding-top: 40px;
  padding-bottom: 10px;
  height: auto;
  margin: 0 auto !important;
}

.Pv-3-proposal-mobile {
  width: 312px;
  height: 220px;
  background: var(--k4n-tertiary-color);
  border-radius: 20px 20px 28px 28px;
  position: relative;
  top: -29.3%;
}

.Pv-1-title-proposal-mobile {
  width: 163px;
  height: 24px;
  font-family: typography.$font-bold;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 120%;
  /* identical to box height, or 24px */
  color: #ffffff;
  position: relative;
  top: 24px;
  left: 24px;
}

.Pv-2-title-proposal-mobile {
  width: 264px !important;
  height: 35px;
  font-family: typography.$font-bold;
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 120%;
  color: var(--k4n-primary-color);
  margin: 0 auto !important;
  padding: 0px 0px 6px 0px !important;
  border-bottom: 1px solid var(--k4n-secondary-color);
  transform: rotate(0.07deg);
}

.No-bottom-border {
  border-bottom: none !important;
}

.Pd-top-27 {
  padding-top: 27px !important;
}

.Pd-top-40 {
  padding-top: 40px !important;
}

.Pd-top-32 {
  padding-top: 32px !important;
}

.Termos-div-proposal-mobile {
  text-align: left;
  padding-left: 24px !important;
  padding-right: 17px !important;
  width: 271px !important;
  height: 76px !important;
}

.Pv-3-title-proposal-mobile {
  width: 132px !important;
  height: 24px;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 120%;
  color: var(--k4n-secondary-color);
  position: relative;
  left: 24px;
  padding: 0;
  white-space: nowrap;
}

.Pv-3-symbol-proposal-mobile {
  width: 30px;
  height: 24px;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 120%;
  color: var(--k4n-secondary-color);
  margin-right: 5px;
  padding: 0;
  white-space: nowrap;
}

.ft-sz-16 {
  font-size: 16px !important;
}

.Pv-3-col-vlr-total-proposal {
  text-align: right;
}

.Pv-3-value-proposal-mobile {
  width: 64px;
  height: 24px;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 120%;
  color: #ffffff;
  padding: 0;
  white-space: nowrap;
}

.Pv-1-subtitle-proposal-mobile {
  height: 22px;
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 120%;
  /* identical to box height, or 22px */
  color: #ffffff;
}

.Pv-1-subtitle-div-proposal-mobile {
  position: relative;
  top: 25px;
  left: 10px;
  display: flex;
}

.Item-div-proposal-mobile {
  text-align: left;
  padding-left: 24px !important;
  padding-right: 0px !important;
}

.Valor-div-proposal-mobile {
  text-align: right;
  padding-right: 24px !important;
}

.Div-item-2-proposal-mobile {
  height: 48px;
}

.Center-vertically-proposal-mobile {
  display: flex;
  align-items: center;
  justify-content: right;
}

.Item-proposal-mobile {
  width: 60px;
  height: 19px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  font-family: typography.$font-main;
  line-height: 120%;
  color: var(--k4n-primary-color);
}

.Valor-item-proposal-mobile {
  width: 65px;
  height: 17px;
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 120%;
  white-space: nowrap;
  color: var(--k4n-primary-color);
}

.Valor-item-mobile-break-line {
  width: 65px;
  height: 17px;
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 120%;
  color: var(--k4n-primary-color);
}

.Container-item-proposal-mobile {
  width: 100% !important;
  margin: 24px 0 0 0 !important;
}

.Pv-2-title-3-div-proposal-mobile {
  width: 264px !important;
  height: 60px;
  margin: 0 auto !important;
  padding: 0px 0px 6px 0px !important;
  border-bottom: 1px solid var(--k4n-secondary-color);
  transform: rotate(0.07deg);
}

.Container-3-proposal-mobile {
  margin-top: 41px !important;
  width: 100%;
  margin-left: 0 !important;
}

.Container-3-mg-14-proposal {
  width: 100%;
  margin-top: 14px !important;
  margin-left: 0 !important;
}

.Col-mensalidade-proposal-mobile {
  padding: 0;
  text-align: left;
  padding-left: 24px !important;
  padding-right: 0px !important;
}

.Container-title-proposal-mobile {
  width: 100%;
  padding-left: 10px;
  padding-top: 16px;
  margin: 0 !important;
}

.Container-title-2-proposal-mobile {
  width: 100%;
  padding-left: 10px;
  padding-top: 40px;
  margin: 0 !important;
}

.Container-top-proposal-mobile {
  margin-top: 32px !important;
}

.Txt-mensalidade-proposal-mobile {
  width: 118px;
  height: 24px;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 120%;
  color: rgba(255, 255, 255, 0.6);
  font-family: "typography.$font-bold;";
}

.Txt-valor-total-proposal-mobile {
  width: 121px;
  height: 19px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 120%;
  color: rgba(255, 255, 255, 0.6);
}

.Valor-mensalidade-proposal-mobile {
  padding-right: 15px !important;
  text-align: right;
}

.Meu-seguro {
  color: var(--k4n-primary-color);
  cursor: pointer;
}

.Next-step-4-btn,
.Next-step-4-btn:hover,
.Next-step-4-btn:focus {
  width: 264px;
  height: 70px;
  background: var(--k4n-secondary-color) !important;
  border-radius: 28px !important;
  padding-left: 32px !important;
  border: none !important;
  padding-right: 32px !important;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 35px;
}

.spinner-next-step4-btn {
  color: white;
}

.Mg-auto-submit-step-4 {
  width: 264px;
  height: 70px;
  margin: 0 auto;
}

.Mg-auto-submit-step-4-mobile {
  width: 264px;
  height: 70px;
  margin: 0 auto;
  margin-top: 40px;
}

.Item-proposal-check-mobile {
  font-style: normal;
  font-weight: 600;
  font-size: 14px !important;
  font-family: typography.$font-main;
  line-height: 120%;
  color: var(--k4n-primary-color);
}

.Pd-bottom-130 {
  padding-bottom: 132px;
}

.Mg-bottom-56 {
  margin-bottom: 56px !important;
}

.Body-modal-purchase {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.H1-modal-purchase {
  font-size: 25px;
  line-height: 120%;
  color: var(--k4n-secondary-color);
  margin: 15px 0;
  text-align: center;
}

.Btn-modal-purchase {
  width: 137px;
  height: 70px;
  background: var(--k4n-secondary-color);
  border-radius: 28px;
  border: none;
  float: right;
  margin-top: 24px;
  margin-left: 200px;
}

.Btn-modal-purchase:hover,
.Btn-modal-purchase:active,
.Btn-modal-purchase:focus {
  background: #fcc065;
}

.Txt-btn-modal-purchase {
  font-size: 20px;
  line-height: 120%;
  color: var(--k4n-primary-color);
  padding-right: 14px;
}

.modal-sucess-purchase-boleto {
  display: flex;
  justify-content: center;
}

.insurance-step4-boleto-href {
  font-family: typography.$font-bold;
  color: var(--k4n-secondary-color);
  font-size: 18px;
  line-height: 120%;
}

.insurance-step4-boleto-href:hover {
  font-family: typography.$font-bold;
  color: #fdd18f;
}

.insurance-disclaimer {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  font-family: typography.$font-main;
  line-height: 120%;
  color: var(--k4n-primary-color);
  padding: 24px;
}

.insurance-disclaimer-mobile {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  font-family: typography.$font-main;
  line-height: 120%;
  color: var(--k4n-primary-color);
  padding: 24px;
}

.disclaimer-link {
  color: var(--k4n-primary-color);
}

@media screen and (max-width: 768px) {
  // .pix-modal-sucess-h1 {
  //   font-size: 26px;
  // }

  // .pix-modal-sucess-txt {
  //   font-size: 14px;
  // }

  .insurance-txt-code-boleto {
    font-size: 14px;
  }
}
