@use "../../../../../assets/scss/colors";
@use "../../../../../assets/scss/typography";

.payment-container {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  top: 56px;
  bottom: 56px;
  flex-direction: column;
}
.payment-content {
  margin-top: 20px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  padding: 16px 24px;
  margin-bottom: 20px;
}
.payment-content-2 {
  margin-top: 20px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  padding: 16px 24px;
}
.payment-content-3 {
  margin-top: 20px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgb(0 0 0 / 8%);
  border-radius: 16px;
  padding: 30px 30px;
}
.payment-title-div {
  padding: 24px 52px;
}
.step-title {
  font-family: typography.$font-bold;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: var(--k4n-secondary-color);
}

.step-span {
  width: 188px;
  height: 18px;
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #0a0203;
}
.bar-code-div {
  padding: 0px 52px;
}
.bar-code-info {
  width: 100%;
  height: 55px;
  border: 1px solid var(--k4n-secondary-color);
  border-radius: 16px;
  display: flex;
  align-items: center;
  padding: 10px;
}
.bar-code-span {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #737373;
}
.usr-input {
  color: black !important;
  border-radius: 30px;
  border: none;
  width: 100%;
}
.usr-input::placeholder {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #737373;
}
.next-btn {
  width: 264px;
  background: var(--k4n-secondary-color);
  border-radius: 28px;
  border-color: transparent;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.next-btn:hover {
  opacity: 0.9;
}
.next-span {
  font-family: typography.$font-bold;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  color: var(--k4n-quaternary-color);
}
.boleto-info {
  width: 100%;
  background: #fcfcfc;
  border-radius: 16px;
  padding: 32px;
  margin-bottom: 20px;
  margin-top: 20px;
}
.payment-btn-div {
  width: 60%;
  height: 60px;
  float: right;
  gap: 30px;
  display: flex;
  justify-content: flex-start;
  flex-direction: row-reverse;
  margin-top: 30px;
  padding: 0px 52px;
}
.cancel-btn {
  background: transparent;
  width: 170px;
  border: 2px solid var(--k4n-secondary-color);
  border-radius: 28px;
}
.cancel-span {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  color: var(--k4n-secondary-color);
}
.payment-boleto-info-div {
  padding: 14px 52px;
}
.content-table-step2-div {
  padding: 0px 52px;
}

.txt-bold-20 {
  color: black;
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
}
.boleto-info-cols {
  display: flex;
  color: #0a0203;
}
.col-title {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 120%;
  color: #0a0203;
}
.row-span-title {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 18px;
  color: #737373;
}
.row-span-content {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #0a0203;
}
.row-span-title-18 {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  color: #737373;
}
.col-bill {
  display: flex;
}

.boleto-justify-center {
  display: flex;
  margin-top: 40px;
  justify-content: center;
}

.boleto-receipt-btn {
  width: 200px;
  height: 40px;
  background: var(--k4n-secondary-color);
  border-radius: 28px;
  border-color: transparent;
  color: var(--k4n-primary-color);
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 1000;
  font-size: 19px;
}
.payment-receipt {
  height: 60px;
  display: flex;
  align-content: center;
  align-items: center;
}
.section {
  height: 80px;
  border-bottom: 1px solid #dadada;
  margin-top: 10px;
}
.section-title {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  color: #0a0203;
}
.w700 {
  font-weight: 700;
  color: #0a0203;
}
.section-2-span {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  color: #737373;
}
.title {
  font-size: 20px;
}
.margin-right-4 {
  margin-right: 4px;
}

.table-div {
  height: 50px;
  border-bottom: 1px solid #dadada;
  margin-top: 40px;
  display: flex;
  align-items: center;
}
.padding-left-10 {
  padding-left: 10px;
}
.col-step-3 {
  display: grid;
  margin-bottom: 10px;
}
.table-div-content {
  margin-top: 30px;
  border-bottom: 1px solid #dadada;
  padding-bottom: 15px;
  display: flex;
  align-items: flex-end;
}
.display-flex {
  display: flex;
}
.row-title {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  color: #0a0203;
}
.margin-bottom-15 {
  margin-bottom: 15px;
}
.row-span {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 120%;
  color: #737373;
}
.margin-30 {
  margin-top: 30px;
  margin-bottom: 30px;
}
.section-span {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 120%;
  color: #737373;
}
.step-3-btns {
  height: 50px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 15px;
}
.close-btn {
  float: left;
  border: transparent;
  background: transparent;
}
.share-btn {
  float: right;
  border: transparent;
  background: transparent;
}
.payment-date {
  height: 100px;
}
.payment-step2-date {
  display: flex;
  align-items: center;
  margin-bottom: 9px;
}

.payment-step2-date-input {
  width: 52px !important;
  height: 25px !important;
}

.payment-step2-date-input:checked {
  background-color: var(--k4n-secondary-color);
  border-color: var(--k4n-secondary-color);
}
.payment-step2-message-input {
  width: 100%;
  height: 55px;
  border: 1px solid var(--k4n-secondary-color);
  border-radius: 16px;
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 120%;
  color: #0a0203;
  padding: 0 13px;
  margin-bottom: 24px;
}

.payment-step2-message-input::placeholder {
  color: #0a0203;
  outline: none;
  border: none;
}

textarea:focus,
.payment-step2-message-input:focus {
  color: #0a0203;
  outline: none;
  border-radius: 16px;
}
.payment-step2-input-calender {
  background-image: url(../../../../../assets/images/calendar.svg);
  background-repeat: no-repeat;
  background-position: 90%;
}
.payment-receipt-capture {
  padding: 42px 68px;
}

.payment-spinner {
  margin: 0 auto;
  display: block;
  width: 2rem;
  height: 2rem;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
  color: #fff;
}

.payment-input-label {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 18px;
  color: #737373;
  position: absolute;
  // padding-left: 26px;
  // padding-top: 3px;
  transform: translate(3px, -17px);
  display: none;
}

.payment-show-input {
  display: block !important;
}
