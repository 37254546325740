@use "../../../../../assets/scss/typography";

.payment-data-data-container {
  padding: 0 98px 40px;
  text-align: start;
  max-width: 1200px;
  margin-right: auto;
  color: var(--k4n-primary-color);
}

.payment-data-data-input {
  /* max-width: 315px; */
  height: 69px;
  border-radius: 28px;
  color: black;
  padding: 0 36px;
  border: 2px var(--k4n-secondary-color) solid;
}

.payment-data-data-label {
  font-family: typography.$font-bold;
  padding-top: 9px;
}

.pill {
  width: 125px;
  padding: 5px 0;
  margin: 0;
  box-sizing: border-box;
  border-radius: 12px;
  background-color: var(--k4n-secondary-color);
  text-align: center;
  color: var(--k4n-primary-color);
  font-family: typography.$font-bold;
}

.Card-flag-master {
  height: 17px;
  margin: 26px 0;
}

.Card-flag-visa {
  height: 17px;
  width: 40px;
  position: absolute;
  margin: 26px -19px;
}

.card-flag-img {
  max-width: 100%;
  max-height: 30px;
}

.card-flag-div {
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 315px;
  height: 69px;
  background: var(--k4n-tertiary-color);
  border-radius: 28px;
  border: none;
  color: white;
  padding: 0 36px;
}

.payment-data-cursor-p {
  cursor: pointer;
}

.Payment-date-button {
  position: relative;
  width: 82px;
  height: 32px;
  border: 1px solid var(--k4n-secondary-color);
  background-color: var(--k4n-primary-color);
  box-sizing: border-box;
  border-radius: 12px;
  margin-top: 24px;
  margin-right: 24px;

  /* Text */

  font-family: typography.$font-main;
  font-size: 16px;
  line-height: 22px;
  color: white;
}

.Payment-date-button:hover,
.Payment-date-button:focus {
  background-color: var(--k4n-secondary-color);
  color: var(--k4n-tertiary-color);
  font-family: typography.$font-bold;
  box-shadow: none;
  border-color: var(--k4n-tertiary-color);
}

.Save-data-btn {
  width: 150px;
  height: 54px;
  background: var(--k4n-secondary-color);
  border-radius: 28px;
  border: none;
  position: absolute;
  top: 88%;
  right: 7%;
}

.Save-data-btn-txt {
  font-family: typography.$font-bold;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 120%;
  color: var(--k4n-primary-color);
}

@media (max-width: 768px) {
  .payment-data-data-container {
    padding: 0 30px 40px;
    display: flex;
    justify-content: center;
  }

  .pill {
    width: 70px;
  }
}

.Boleto-href {
  font-family: typography.$font-bold;
  color: var(--k4n-secondary-color);
  font-size: 18px;
  line-height: 120%;
  cursor: pointer;
}

.insurance-method-payment {
  font-family: typography.$font-bold;
  color: var(--k4n-secondary-color);
  font-size: 18px;
  line-height: 120%;
}

.Boleto-href:hover {
  font-family: typography.$font-bold;
  color: var(--k4n-secondary-color);
  opacity: 0.6;
}

.Data-content {
  color: white;
}

.Toggle-active-step4 {
  border: 1px solid var(--k4n-secondary-color);
  background-color: var(--k4n-secondary-color);
  color: var(--k4n-tertiary-color);
  font-family: typography.$font-bold;
}

@media screen and (max-width: 768px) {
  .card-flag-div {
    max-width: 240px;
    height: 90px;
  }
}
