@use "../../../../../assets/scss/colors";
@use "../../../../../assets/scss/typography";

.pix-register-container {
  width: 100%;
  display: flex;
  height: auto;
  position: relative;
  top: 56px;
  bottom: 56px;
  flex-direction: column;
}

.pix-register-content {
  position: relative;
  margin-top: 15px;
  width: 100%;
  height: auto;
  min-height: 687px;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  padding: 0 68px;
}

.pix-register-content-title {
  // width: 163px;
  height: 29px;
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  color: var(--k4n-primary-color);
  margin-top: 48px;
  padding: 20px 0 20px;
}

.content-table-register {
  width: 100%;
  height: 67px;
  display: flex;
  border-bottom: 1px solid #dadada;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  cursor: pointer;
}

.pix-register-show-content {
  position: relative;
  margin-top: 15px;
  width: 100%;
  height: auto;
  min-height: 399px;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  padding: 0 68px;
}

.pix-register-show-title {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 120%;
  color: #737373;
  margin: 0;
  margin-top: 16px;
  padding-bottom: 24px;
  border-bottom: 1px solid #dadada;
}

.pix-register-check {
  margin-top: 64px;
  display: flex;
  align-items: center;
}

.pix-register-check-input {
  width: 52px !important;
  height: 25px !important;
}

.pix-register-check-input:checked {
  background-color: var(--k4n-secondary-color);
  border-color: var(--k4n-secondary-color);
}

.pix-register-check-txt {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 120%;
  color: #737373;
  margin-left: 15px;
}

.pix-register-span {
  width: 80px;
  height: 40px;
  color: #ffffff;
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 120%;
  background-color: var(--k4n-secondary-color);
  border-radius: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pix-register-span-disabled {
  width: 120px !important;
  background: #c0c0bf !important;
}

.pix-register-key {
  display: inline-flex;
  align-items: center;
  margin-top: 64px;
}
