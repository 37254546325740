@use "../../../../../assets/scss/colors";
@use "../../../../../assets/scss/typography";

.transfer-container {
  margin-top: 56px;
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.panel-content {
  margin-top: 20px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  padding: 16px 24px;
}

.panel-title {
  width: 100%;
  height: 80px;
  border-bottom: 1px #dadada solid;
  display: flex;
  align-items: center;
}

.title-blue {
  font-family: typography.$font-bold;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 22px;
  color: var(--k4n-secondary-color);
}

.transfer-value-div {
  width: 100%;
  height: 100px;
  border-bottom: 1px #dadada solid;
  display: flex;
  align-items: center;
  gap: 20px;
}

.transfer-value-box {
  width: 204px;
  height: 55px;
  border: 1px solid var(--k4n-secondary-color);
  border-radius: 16px;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  align-items: center;
}

.transfer-value-label {
  color: black !important;
  border: none;
  font-family: typography.$font-main;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
}

.transfer-value-input {
  color: black !important;
  border: none;
  width: 100%;
  font-family: typography.$font-main;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  background-color: transparent;
}

.transfer-value-input::placeholder {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  color: #0a0203;
}

.info-span-bold-16 {
  font-family: typography.$font-main;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  color: #0a0203;
}

.info-span-grey-16 {
  font-family: typography.$font-main;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  color: #737373;
}

.transfer-step-1-btn {
  float: right;
  display: flex;
  height: 100px;
  align-items: flex-end;
}

.transfer-submit-btn {
  width: 264px;
  height: 60px;
  background: var(--k4n-secondary-color);
  border-radius: 28px;
  border-color: transparent;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.transfer-submit-btn:hover {
  opacity: 0.9;
}

.transfer-submit-btn-txt {
  font-family: typography.$font-bold;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  color: var(--k4n-quaternary-color);
}

.transfer-step1-error {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: #ed6465;
  text-align: left;
  margin-bottom: 0px;
  margin-top: 5px;
  padding-left: 20px;
}
