@use "../../../../../../../assets/scss/colors";
@use "../../../../../../../assets/scss/typography";

.insurance-step3-boleto {
  padding: 24px;
  border-radius: 28px;
  background-color: #fbfbfb;
  float: left;
  text-align: left;
}

.insurance-step3-boleto-h1 {
  font-family: typography.$font-bold;
  color: var(--k4n-primary-color);
  margin: 0;
  font-size: 16px;
  line-height: 140%;
  margin-left: 4px;
  padding: 0;
}

.insurance-step3-boleto-span {
  width: 24px;
  height: 24px;
  background-color: var(--k4n-secondary-color);
  border-radius: 17px;
  border: 1px solid var(--k4n-secondary-color);
  font-family: typography.$font-bold;
  font-size: 16px;
  color: var(--k4n-primary-color);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}

.insurance-step3-boleto-txt {
  font-family: typography.$font-main;
  color: var(--k4n-primary-color);
  margin: 0;
  width: 255px;
  font-size: 16px;
  line-height: 140%;
}

.insurance-codigo-barras-copiado {
  position: absolute;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  max-width: 310px;
  width: 100%;
  height: 70px;
  margin: 0;
  margin-top: 46px;
  background: var(--k4n-secondary-light-color);
  border-radius: 28px;
  font-family: typography.$font-main;
  color: var(--k4n-primary-color);
}

.insurance-copy-text {
  font-family: typography.$font-main;
  color: var(--k4n-primary-color);
  padding: 0;
  width: 206px;
}

.insurance-copy-icon {
  width: auto;
  height: 32px;
  color: var(--k4n-primary-color);
  padding: 0;
  margin: 16px;
}

.insurance-boleto-spinner {
  color: var(--k4n-secondary-color);
  display: flex;
}

.justify-center {
  display: flex;
  justify-content: center;
}

/*  */

.Mg-top-24 {
  margin-top: 24px;
}

.Mg-top-30 {
  margin-top: 30px;
}

.No-padding {
  padding: 0;
}

.No-padding-less-left {
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 0;
}

.Hover-pointer {
  cursor: pointer;
}

.Txt-center {
  text-align: center;
}

.insurance-txt-code-boleto {
  color: white;
  word-wrap: break-word;
  margin-top: 24px;
  font-family: typography.$font-main;
  font-size: 16px;
  width: 100%;
  text-align: center;
}

.Txt-code-boleto-mobile {
  color: white;
  word-wrap: break-word;
  margin-top: 37px;
  font-family: typography.$font-main;
  font-size: 16px;
  position: relative;
  top: 32px;
}
