@use "../../../assets/scss/colors";
@use "../../../assets/scss/typography";

.modal-wrapper-custom {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 100;
  max-width: 100%;
  margin: 0 auto;
}

.modal-wrapper-custom-mobile {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end !important;
  align-items: center;
  z-index: 100;
  max-width: 100%;
  height: 100%;
  margin: 0 auto;
}

.modal-button {
  position: fixed !important;
  top: auto !important;
  right: auto !important;
  left: auto !important;
  bottom: 0 !important;
}

.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba($color: #000000, $alpha: 0.2);
}

.modal-container {
  background-color: var(--k4n-primary-color);
  padding: 32px;
  border-radius: 28px;
  z-index: 105;
  margin: 20px 0;
  max-width: 100%;
}

.modal-container-mobile {
  background-color: var(--k4n-primary-color);
  padding: 32px;
  z-index: 105;
  width: 100%;
  max-height: 100%;
  overflow-y: scroll;
  margin: auto;
  border-radius: 28px;
}

.modal-message {
  color: #ffffff;
  font-family: typography.$font-main;
  max-width: 700px;
}

.modal-title {
  color: var(--k4n-secondary-color);
  font-family: typography.$font-bold;
}

.flex-end {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
}

.flex-left {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
}

.custom-modal-close-button {
  width: 22px;
  height: 22px;
  /* background-image: url("../../../assets/images/close.svg"); 
  background-size: 12px 12px;*/
  cursor: pointer;
  color: var(--k4n-secondary-color);
  background-color: var(--k4n-secondary-color);
  mask-size: cover;
  -webkit-mask-image: url("../../../assets/images/close.svg");
  mask-image: url("../../../assets/images/close.svg");
}

@media screen and (max-width: 767px) {
  .modal-container {
    padding: 30px 20px;
  }
}
