@use "../../../../../assets/scss/colors";
@use "../../../../../assets/scss/typography";

.Content-channels-logged {
  padding-right: 98px !important;
  padding: 0;
  color: var(--k4n-primary-color);
  box-sizing: content-box;
}

.Channels-kikkin {
  width: 100%;
  height: fit-content;
  margin-top: 40px;
  display: inline-flex;
}

.Mg-left-70 {
  margin-left: 70px;
}

.Mg-top-40 {
  margin-top: 40px;
}

.Channels-kikkin-mobile {
  width: 100%;
  height: fit-content;
  margin-top: 40px;
  display: inline-grid;
}

.H1-Channels-title-div {
  width: 100%;
  margin-top: 32px;
  text-align: left;
}

.H1-Channels-title-div-mobile {
  width: 80%;
  margin-top: 32px;
  text-align: left;
}

.H1-Channels {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 120%;
}

.H1-Channels-mobile {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 120%;
}

.Channel-div {
  max-width: 300px;
  height: 369px;
  background: var(--k4n-primary-color);
  border-radius: 28px;
  padding-left: 24px;
  padding-right: 24px;
}

.Channel-div-title {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 120%;
  color: #ffffff;
}

.Channel-div-title-container {
  width: 100%;
  text-align: center;
  height: fit-content;
  padding-top: 24px;
}

.Channel-icon-div {
  margin-top: 80px;
  justify-content: center;
  text-align: center;
  display: grid;
}

.Channel-phone-img-div {
  width: 56px;
  height: 56px;
  max-width: 56px;
  max-height: 56px;
  margin: 0 auto;
}

.Channel-mail-img-div {
  width: 57px;
  height: 35px;
  max-width: 57px;
  max-height: 35px;
  margin: 0 auto;
}

.Channel-icon-txt {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: var(--k4n-secondary-color);
  margin-top: 24px;
}

.Channel-info {
  width: 100%;
  height: fit-content;
  text-align: left;
  margin-top: 50px;
  border-top: 1px solid var(--k4n-secondary-color);
}

.Channel-info-txt {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: var(--k4n-secondary-color);
  position: relative;
  top: 12px;
}

@media screen and (max-width: 768px) {
  .Content-channels-logged {
    padding-right: 0 !important;
    margin: auto;
  }
  .Channels-kikkin {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .Channel-div {
    margin-left: 0 !important;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .H1-Channels-title-div {
    text-align: center;
  }
}
