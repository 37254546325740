@use "../../../../../../assets/scss/colors";
@use "../../../../../../assets/scss/typography";

.boleto-manager-wrapper-mobile {
  background-color: transparent;
  width: 100%;
  height: 100%;
  margin: 0;
  display: inline-block;
}

.boleto-container-mobile {
  background-color: var(--k4n-primary-color);
  width: 100%;
  height: 100%;
}

.boleto-header-mobile {
  padding: 10% 5% 3%;
}

.boleto-header-mobile-btn {
  background-color: transparent;
  border: transparent;
  float: right;
  height: 15.02px;
}

.f-l {
  float: left;
}

.boleto-header-h1-mobile {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: var(--k4n-secondary-color);
  margin-top: 80px;
}

.boleto-table-content-mobile {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  border-top-left-radius: 26px;
  border-top-right-radius: 26px;
  padding: 8% 5% 0% 5%;
}

.boleto-options-btns-mobile-div {
  width: 100%;
  display: inline-flex;
  flex-direction: row;
  gap: 10px;
  overflow-y: hidden;
  margin: 10px 0;
  height: 60px;
  background: white;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
  align-items: center;
  padding: 0 10px;
}

.boleto-options-btns-mobile-div::-webkit-scrollbar {
  display: none;
}

.boleto-option-btn-mobile {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  color: #ffffff;
  background-color: var(--k4n-primary-color);
  box-shadow: 0px 4px 12px rgb(0 0 0 / 8%);
  border-radius: 12px;
  border-width: 0;
  padding: 11.5px 16px;
  white-space: nowrap;
}

.boleto-option-btn-mobile-disable {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  color: #737373;
  background-color: #ffffff;
  box-shadow: 0px 4px 12px rgb(0 0 0 / 8%);
  border-radius: 12px;
  border-width: 0;
  padding: 11.5px 16px;
  white-space: nowrap;
}

.boleto-item {
  width: 100%;
  height: auto;
  border-bottom: 1px solid rgba(10, 2, 3, 0.1);
  padding-top: 24px;
  padding-bottom: 24px;
}

.boleto-item-h3 {
  font-family: typography.$font-bold;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 120%;
  color: colors.$complementary-black;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.boleto-item-label {
  font-family: typography.$font-bold;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #737373;
}

.boleto-item-txt {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: #737373;
}

.boleto-item-arrow {
  margin-left: 40px;
}

.boleto-loading-mobile {
  width: 100%;
  height: 70px;
}

.mt-25 {
  margin-top: 25px;
}

.mb-not-found-mobile {
  width: 100%;
  margin: 0 auto;
  opacity: 0.6;
  text-align: center;
}

.boleto-manager-filter-error {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: #e34848;
  margin-left: 10px;
}

.modal-boleto-menu-mobile {
  top: 30px;
  width: 100%;
  height: auto;
  position: fixed;
  background-color: #ffffff;
  border-radius: 26px;
  padding: 5% 5%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  z-index: 10;
}

@media only screen and (max-height: 670px) {
  .modal-boleto-menu-mobile {
    gap: 18px !important;
  }
}

.modal-boleto-menu-close-mobile {
  width: 34px;
  display: flex;
  height: 34px;
  align-items: center;
  justify-content: flex-start;
  background: transparent;
  border: transparent;
}

.modal-boleto-title-h1-mobile {
  font-family: typography.$font-bold;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: var(--k4n-primary-light-color);
}

.date-filter-mobile-text {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 120%;
  color: #0a0203;
}

.date-filter-menu-btns-div {
  display: flex;
  gap: 16px 8px;
  flex-wrap: wrap;
  width: 70%;
}

.date-filter-option-mobile {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  color: #ffffff;
  background-color: var(--k4n-primary-color);
  box-shadow: 0px 4px 12px rgb(0 0 0 / 8%);
  border-radius: 12px;
  border-width: 0;
  padding: 11.5px 16px;
}

.date-filter-option-mobile-disable {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  color: #737373;
  background-color: #ffffff;
  box-shadow: 0px 4px 12px rgb(0 0 0 / 8%);
  border-radius: 12px;
  border-width: 0;
  padding: 11.5px 16px;
}

.date-filter-calendar-mobile {
  width: 90%;
  height: 60px;
  border-radius: 12px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  padding-left: 20px;
  box-shadow: 0px 4px 12px rgb(0 0 0 / 8%);
}

.date-filter-apply-mobile {
  display: flex;
  justify-content: center;
}

.modal-boleto-detail-subtitle {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #737373;
}

.modal-boleto-detail-subtitle-bold {
  font-family: typography.$font-bold;
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  color: colors.$complementary-black;
}

.mt-16 {
  margin-top: 16px;
}

.modal-boleto-detail-txt {
  font-family: typography.$font-bold;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 120%;
  color: colors.$complementary-black;
}

.modal-boleto-detail-value {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 120%;
  color: #737373;
  float: right;
  max-width: 100%;
  word-wrap: break-word;
}

.modal-boleto-detail-row {
  border-bottom: 1px solid rgba(10, 2, 3, 0.1);
  padding-bottom: 16px;
}

.modal-boleto-detail-small-value {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  text-align: right;
  color: #737373;
  float: right;
  max-width: 100%;
  word-wrap: break-word;
}

.mb-vertical-center {
  margin: 0;
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.mb-copy-txt {
  position: relative;
  top: -20px;
}

.mb-copy-div {
  width: 100%;
  height: 40px;
  background: var(--k4n-secondary-light-color);
  border-radius: 8px;
  padding: 9px 0px 0px 14px;
  margin-top: 15px;
  display: inline-flex;
}

.mb-copy-icon {
  width: 18.75px;
  height: 23.08px;
  min-width: 18.75px;
  min-height: 23.08px;
  cursor: pointer;
}

.mb-copy-value {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 120%;
  color: var(--k4n-primary-color);
  width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 9px;
}

.mb-pdf-download {
  font-family: typography.$font-bold;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 120%;
  color: var(--k4n-primary-color);
  background-color: var(--k4n-secondary-color);
  border: none;
  border-radius: 28px;
  width: 100%;
  height: 56px;
  text-align: center;
  text-decoration: none;
}

.mb-pdf-download:hover {
  color: var(--k4n-primary-color);
}

.mb-h-100 {
  height: 100%;
}

.mb-top-7 {
  position: relative;
  top: 7px;
}

.mb-dropdown-error-msg {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: #ed6465;
  text-align: center;
  margin: 0 auto;
  padding: 0;
}
