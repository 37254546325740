@use "../../../assets/scss/colors";
@use "../../../assets/scss/typography";

.favorite-row {
  display: flex;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid #dadada;
  justify-content: space-between;
  padding-top: 18px;
  padding-bottom: 18px;
  width: 97%;
}

.favorite-avatar-row {
  display: flex;
  height: 65px;
  align-items: center;
  cursor: pointer;
}

.no-favorites {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 32px;
}

.no-favorites-img {
}

.no-favorites-message {
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
}

.favorite-div {
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 25px;
}

.favorite-title {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  color: var(--k4n-primary-color);
}

.favorite-input {
  box-sizing: border-box;
  width: 100%;
  max-width: 381px;
  height: 60px;
  border: 2px solid var(--k4n-secondary-color);
  border-radius: 28px;
  font-family: typography.$font-main;
  font-weight: 700;
  font-size: 18px;
  line-height: 120%;
  padding-left: 32px;
  background-image: url(../../../assets/images/search_icon.svg);
  background-repeat: no-repeat;
  background-position: 90%;
}

/* .favorite-input::after {
  content: " ";
  background-color: var(--k4n-primary-color);
  mask-size: cover;
  mask-repeat: no-repeat;
  -webkit-mask-image: url("../../../assets/images/search_icon.svg");
  mask-image: url("../../../assets/images/search_icon.svg");
  height: 50px;
  width: 50px;
} */

.favorite-input::placeholder {
  color: var(--k4n-secondary-color);
  outline: none;
  border: none;
}

textarea:focus,
.favorite-input:focus {
  color: var(--k4n-secondary-color);
  border-radius: 28px;
  outline: none;
}

.favorite-input:focus::placeholder {
  color: transparent;
}

.favorite-avatar {
  width: 48px;
  height: 48px;
  background-color: #d5f5fa;
  border-radius: 30px;
  margin-right: 16px;
}

.favorite-name-avatar {
  width: 100%;
  height: 100%;
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: var(--k4n-primary-color);
  display: flex;
  justify-content: center;
  align-items: center;
}

.favorite-table-txt {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #737373;
}

.favorite-content-txt {
  width: 190px;
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  color: #0a0203;
}

.spinner-favorites {
  color: var(--k4n-secondary-color);
  margin-right: 10px;
  width: 24px;
  height: 24px;
  margin-left: 35px;
  margin-top: 20px;
}

.favorites-container {
  background: transparent;
  padding: 5% 0;
}

.favorites-container-active-mobile {
  position: relative;
  top: 0;
  left: 0;
  background: white;
  border-radius: 16px 16px 0 0;
  width: 100%;
  overflow-y: auto;
}

.favorites-container-inactive-mobile {
  position: relative;
  top: 0;
  left: 0;
  background: white;
  border-radius: 16px 16px 0 0;
  width: 100%;
}

.favorites-flex-direction-column {
  flex-direction: column;
}

.favorites-mobile-close-btn {
  position: relative;
  left: 0px;
  top: 55px;
  width: 18px;
  height: 18px;
  margin-top: 20px;
  outline: none;
  border: none;
  color: var(--k4n-secondary-color);
  background-color: var(--k4n-secondary-color);
  mask-size: contain;
  mask-repeat: no-repeat;
  transform: scaleX(-1);
  -webkit-mask-image: url("../../../assets/images/arrow.svg");
  mask-image: url("../../../assets/images/arrow.svg");
}

.favorite-row-click {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
}

.favorites-img-container {
  display: flex;
  margin-top: 15px;
}

.trash-container {
  display: flex;
}

.trash-img {
  width: 22.74px;
  height: 24.81px;
  display: flex;
}

.favorite-content {
  margin-top: 30px;
  overflow: auto;
  height: 300px;
  &::-webkit-scrollbar {
    width: 7px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #9999;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #888;
  }
}
