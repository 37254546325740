@use "../../../../../assets/scss/colors";
@use "../../../../../assets/scss/typography";

.Switcher-kikkin-logged {
  width: 100%;
  height: 77px;
  background: var(--k4n-secondary-light-color);
  padding-left: 40px;
  padding-right: 40px;
  display: flex;
  align-items: center;
  border-radius: 16px;
  padding: 0 32px;
}

.Switcher-kikkin-logged-mobile {
  height: 77px;
  background: var(--k4n-secondary-light-color);
  overflow: scroll;
  border-radius: 16px;
  display: flex;
}

.Switcher-row-logged {
  width: 100%;
  display: flex;
  align-items: center;
}

.Switcher-border-bottom {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 10px;
}

.Switcher-border-bottom-mobile {
  width: fit-content;
  height: 100%;
  padding-left: 10px;
  padding-right: 10px;
}

.Switcher-item-div {
  width: fit-content;
  padding: 0;
  cursor: pointer;
}

.Switcher-item-div-active {
  width: fit-content;
  padding: 0;
  color: var(--k4n-primary-color);
}

.Switcher-mg-left {
  margin-left: 40px;
}

.Switcher-item-text {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: var(--k4n-primary-color);
  white-space: nowrap;
  cursor: pointer;
}

.Switcher-item-text-active {
  font-family: typography.$font-bold;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 100%;
  color: var(--k4n-secondary-color);
  white-space: nowrap;
  cursor: pointer;
}

.Data-switcher-logged {
  width: 100%;
  height: 100px;
  background: transparent;
  padding: 0 98px;
}

@media (max-width: 768px) {
  .Data-switcher-logged {
    padding: 0 38px;
    overflow-x: scroll;
  }
}
