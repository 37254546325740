@use "../../../../../../../assets/scss/colors";
@use "../../../../../../../assets/scss/typography";

.pix-payment-step2-input-mobile {
  width: 100% !important;
  max-width: 312px !important;
  height: 67px !important;
  background: #fff2de !important;
  border: none !important;
  border-radius: 28px !important;
  margin-top: 76px;
}

.align-center-mobile {
  text-align: center;
}

.pix-payment-step3-value-mobile {
  font-family: typography.$font-bold;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 100%;
  color: var(--k4n-primary-color);
  margin-top: 40px;
}

.pix-payment-step3-h1-mobile {
  font-family: typography.$font-bold;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 120%;
  color: #0a0203;
}

.pix-payment-step3-txt-mobile {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 129%;
  color: #737373;
}

.bold {
  font-family: typography.$font-bold !important;
  color: #0a0203 !important;
}

.pix-payment-table-mobile {
  border-bottom: 1px solid #dadada;
  padding: 16px 0;
}

.margin-top-10 {
  margin-top: 10px;
}

.pix-payment-img {
  width: 22px !important;
  height: 22px !important;
}

.pix-payment-date-mobile {
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  border-bottom: 1px solid #dadada;
  padding: 16px 0;
  align-items: center;
}

.pix-payment-modal-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.size-24 {
  font-size: 24px;
  text-align: center;
  margin-bottom: 32px;
}

.react-datepicker {
  font-family: typography.$font-main !important;
  color: var(--k4n-primary-color);
}

.react-datepicker__day-name {
  font-family: typography.$font-bold !important;
}

.react-datepicker__header {
  background-color: #ffffff !important;
}

.react-datepicker__day--selected {
  background-color: var(--k4n-secondary-color) !important;
}

.react-datepicker__navigation-icon::before {
  border-color: var(--k4n-secondary-color) !important;
}

.pix-textarea-message-modal-mobile {
  width: 100%;
  max-width: 312px;
  height: 100%;
  min-height: 141px;
  background: #fff2de;
  border: none;
  border-radius: 28px;
  margin-top: 27px;
  padding: 18px 23px;
  color: #0a0203;
}

.pix-textarea-message-modal-mobile:focus {
  border-radius: 28px;
}

.nowrap-ellipsis {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  width: 60% !important;
}

.pix-payment-step3-message-mobile {
  width: 90%;
}

.pix-value-input-mobile {
  color: black !important;
  border: none;
  width: 100%;
  font-family: typography.$font-main;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  background-color: transparent;
}

.pix-value-input-mobile::placeholder {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  color: #0a0203;
}

.pix-value-label {
  color: black !important;
  border: none;
  font-family: typography.$font-main;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
}

.pix-value-box {
  width: 204px;
  height: 55px;
  border: 1px solid var(--k4n-secondary-color);
  border-radius: 16px;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  align-items: center;
}
