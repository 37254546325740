@use "../../../../assets/scss/colors";
@use "../../../../assets/scss/typography";

.text-yellow {
  color: var(--k4n-secondary-color);
}

.onboarding-cam-page {
  padding: 60px 120px;
}

.tips-text-title {
  font-size: 2rem;
  line-height: 2.3rem;
}

@media screen and (max-width: 768px) {
  .onboarding-cam-page {
    padding: 40px 35px;
  }
}
