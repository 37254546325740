@use "../../../../../../assets/scss/colors";
@use "../../../../../../assets/scss/typography";

.pix-payment-container {
  width: 100%;
  display: flex;
  height: auto;
  position: relative;
  top: 56px;
  bottom: 56px;
  flex-direction: column;
}

.pix-payment-container-title {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  color: #737373;
  margin: 0;
}

.pix-payment-content {
  position: relative;
  margin-top: 15px;
  width: 100%;
  height: auto;
  min-height: 910px;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  padding: 0 68px 24px 68px;
}

.pix-payment-content-title {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  color: var(--k4n-primary-color);
  margin-top: 48px;
}

.pix-payment-content-txt {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  color: #0a0203;
  margin-top: 17px;
  margin-bottom: 15px;
}

.pix-payment-contact {
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 113px;
}

.pix-payment-contact-title {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  color: var(--k4n-primary-color);
}

.pix-payment-contact-input {
  box-sizing: border-box;
  width: 381px;
  height: 60px;
  border: 2px solid var(--k4n-secondary-color);
  border-radius: 28px;
  font-family: typography.$font-main;
  font-weight: 700;
  font-size: 20px;
  line-height: 120%;
  padding-left: 46px;
  background-image: url(../../../../../../assets/images/search_icon.svg);
  background-repeat: no-repeat;
  background-position: 90%;
}

.pix-payment-contact-input::placeholder {
  color: var(--k4n-secondary-color);
  outline: none;
  border: none;
}

textarea:focus,
.pix-payment-contact-input:focus {
  color: var(--k4n-secondary-color);
  border-radius: 28px;
  outline: none;
}

.pix-payment-contact-input:focus::placeholder {
  color: transparent;
}

.pix-payment-contact-table {
  margin-top: 30px;
}

.pix-payment-avatar {
  width: 48px;
  height: 48px;
  background-color: #d5f5fa;
  border-radius: 30px;
  margin-right: 16px;
}

.pix-payment-name-avatar {
  width: 100%;
  height: 100%;
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: var(--k4n-primary-color);
  display: flex;
  justify-content: center;
  align-items: center;
}

.pix-payment-table-txt {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #737373;
}

.pix-payment-nav {
  width: 100%;
  background: var(--k4n-secondary-light-color);
  border-radius: 16px;
  height: 77px;
  display: flex;
  align-items: center;
  padding: 0 32px;
  justify-content: space-between;
}

.pix-payment-nav-title {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 92%;
  color: var(--k4n-quaternary-color);
  margin: 0;
}

.pix-payment-nav-btns {
  display: inline-flex;
}

.pix-payment-nav-btn {
  width: 25px;
  height: 25px;
  background-color: transparent;
  border: 1px solid var(--k4n-secondary-color);
  border-radius: 100%;
  font-family: typography.$font-bold;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 16px;
  cursor: default !important;
}

.toggle-pix-payment-nav-btn {
  background: var(--k4n-tertiary-color);
  border: 1px solid #fff;
  color: #fff;
}

.pix-payment-align-btn {
  width: 100%;
  display: flex;
  justify-content: end;
  margin-top: 68px;
}

// PIX STEP 1

.pix-payment-step1-container {
  width: 100%;
  display: flex;
  height: auto;
  position: relative;
  top: 56px;
  bottom: 56px;
  flex-direction: column;
}

.pix-payment-step1-title {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 92%;
  color: var(--k4n-primary-color);
  margin: 0;
}

.pix-payment-step1-content {
  margin-top: 20px;
  width: 100%;
  height: auto;
  min-height: 378px;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  padding: 42px 68px;
}

.pix-payment-step1-btn {
  width: 100%;
  display: inline-flex;
  padding-top: 68px;
  justify-content: flex-end;
  border-top: 1px solid #dadada;
}

.pix-payment-step1-input {
  width: 384px;
  height: 55px;
  margin: 24px 0;
  border: 1px solid var(--k4n-secondary-color);
  border-radius: 16px;
  padding: 0 30px;
}

.wd-450 {
  width: 450px !important;
}

.pix-payment-step1-input::placeholder {
  color: #0a0203;
  border-radius: 16px;
  outline: none;
}

.pix-payment-step1-input::-webkit-input-placeholder {
  color: #0a0203;
  border-radius: 16px;
  outline: none;
}

.pix-payment-step1-input::-moz-placeholder {
  color: #0a0203;
  border-radius: 16px;
  outline: none;
}

.pix-payment-step1-input:-ms-input-placeholder {
  color: #0a0203;
  border-radius: 16px;
  outline: none;
}

.pix-payment-step1-input:-moz-placeholder {
  color: #0a0203;
  border-radius: 16px;
  outline: none;
}

textarea:focus,
.pix-payment-step1-input:focus {
  color: #0a0203;
  border-radius: 16px;
  outline: none;
}

.pix-payment-step1-input:focus::placeholder {
  color: transparent;
}

.margin-right-50 {
  margin-right: 50px;
}

.pix-payment-step1-select {
  width: 80px;
  height: 55px;
  border: 1px solid var(--k4n-secondary-color);
  border-radius: 16px;
  background: #ffffff;
  margin-right: 35px;
  padding-left: 10px;
  font-family: typography.$font-bold;
}

.pix-payment-step1-select:focus,
.pix-payment-step1-select:focus {
  background: #ffffff;
  border: 1px solid var(--k4n-secondary-color) !important;
  border-radius: 16px;
}

.pix-payment-step1-select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url(../../../../../../assets/images/arrow_yellow_down.svg);
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) center;
  background-size: 18px;
}

.spinner-pix {
  color: #ffffff;
}
