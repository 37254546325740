@use "../../../../../../assets/scss/colors";
@use "../../../../../../assets/scss/typography";

.panel-step-4-mobile-title {
  width: 100%;
  height: 50px;
  border-bottom: 1px #dadada solid;
  display: flex;
  align-items: center;
}

.title-mobile-blue {
  font-family: typography.$font-bold;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 22px;
  color: var(--k4n-primary-light-color);
}

.step-4-mobile-info-div {
  width: 100%;
  height: 180px;
  border-bottom: 1px #dadada solid;
  display: flex;
  padding: 20px 0px;
  flex-direction: column;
}

.step-4-mobile-info-div-flex {
  display: flex;
  gap: 5px;
  flex-direction: column;
}

.info-mobile-div {
  height: 55px;
  display: flex;
  flex-direction: column;
}

.info-span-bold-16 {
  font-family: typography.$font-main;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  color: #0a0203;
}

.info-span-grey-16 {
  font-family: typography.$font-main;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  color: #737373;
}

.step-4-steps-mobile-value-info {
  display: flex;
  gap: 10px;
  flex-direction: row;
}

.info-span-grey-24 {
  font-family: typography.$font-main;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  color: #737373;
}

.margin-top-20 {
  margin-top: 20px;
}

.transfer-mobile-contact-div {
  padding: 15px 0px;
  display: flex;
  gap: 5px;
  border-bottom: 1px #dadada solid;
  padding-right: 20px;
  flex-direction: column;
}

.padding-mobile-left-55 {
  padding-left: 55px;
}

// .form-step-3-div {
//   padding: 20px 0px;
//   display: flex;
//   flex-direction: column;
//   border-bottom: 1px #dadada solid;
// }

.send-message-content {
  width: 320px;
}

.send-message-box {
  width: 320px;
  height: 55px;
  border: 1px solid var(--k4n-secondary-color);
  border-radius: 16px;
  display: flex;
  align-items: center;
  padding: 0px 20px;
}

.send-message-input {
  color: #212529 !important;
  border: transparent;
}

.send-message-input::placeholder {
  font: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 120%;
  color: #0a0203;
}

.float-r {
  float: right;
}

.send-message-limit-char {
  font: typography.$font-main;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: var(--k4n-primary-light-color);
}

.info-span-grey-14 {
  font: typography.$font-main;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #737373;
}

.transfer-mobile-step3-date {
  display: flex;
  align-items: center;
}

.transfer-mobile-step3-date-input {
  width: 52px !important;
  height: 25px !important;
}

.transfer-mobile-step3-date-input:checked {
  background-color: var(--k4n-secondary-color);
  border-color: var(--k4n-secondary-color);
}

.transfer-mobile-step-3-btns-div {
  margin-top: 0;
  width: 50%;
  height: 100px;
  float: right;
  gap: 30px;
  display: flex;
  justify-content: flex-start;
  flex-direction: row-reverse;
  align-items: center;
}

.padding-top-30 {
  padding-top: 30px;
}

.cancel-btn {
  background: transparent;
  height: 60px;
  width: 170px;
  border: 2px solid var(--k4n-secondary-color);
  border-radius: 28px;
}

.cancel-span {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  color: var(--k4n-secondary-color);
}

.height-auto {
  height: auto;
}

.step-4-mobile-info-div {
  width: 100%;
  height: 180px;
  border-bottom: 1px #dadada solid;
  display: flex;
  padding: 20px 0px;
  gap: 5px;
  flex-direction: column;
  justify-content: flex-start;
}
