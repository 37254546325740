@use "../../../../assets/scss/colors";
@use "../../../../assets/scss/typography";

.Main-logged-area {
  background-color: #e5e5e5;
  width: 100%;
  margin: 0;
  height: auto;
  padding: 0 !important;
  color: #fff;
}

.Col-no-padding-logged {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.flex-1 {
  flex: 1;
}

.insurance-wrapper {
  background-color: transparent;
  width: 100%;
  height: 100%;
  margin: 0;
  display: inline-block;
  padding-left: 75px;
  padding-right: 75px;
}

.insurance-mobile-wrapper {
  height: 100%;
  background-color: var(--k4n-primary-color);
}

.insurance-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}

.insurance-content {
  margin-top: 20px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  padding: 32px 32px;
  margin-bottom: 20px;
}

.insurance-mobile-header {
  padding: 20px;
  background-color: var(--k4n-primary-color);
}

.insurance-mobile-content {
  border-radius: 16px 16px 0 0;
  background-color: #fff;
}
