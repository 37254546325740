@use "../../../assets/scss/colors";

.loading-overlay-bg {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: var(--k4n-primary-color);
}

.loading-overlay-bg.default {
  /* background-color: colors.$primary-green; */
  background-color: white;
}
