@use "../../../../../assets/scss/colors";
@use "../../../../../assets/scss/typography";

.insurance-product-status-div {
  width: 100%;
  min-width: 205px;
  background: var(--k4n-primary-color);
  border-radius: 28px;
  padding: 24px;
}

.insurance-renew-btn {
  width: 200px;
  height: 70px;
  background: var(--k4n-secondary-color);
  border-radius: 28px;
  border: none;
  margin: 15px;
  margin-left: 30px;
}

.insurance-renew-btn:hover {
  opacity: 0.9;
  color: var(--k4n-primary-color) !important;
}

.width-250 {
  width: 300px !important;
  height: 70px;
  // margin-top: 10px;
}

.insurance-product-status {
  padding: 1em 2em;
  display: flex;
  flex-wrap: wrap;
}

.insurance-status-title {
  font-family: typography.$font-bold;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 100%;
  color: white;
}

.insurance-status-value {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  color: var(--k4n-secondary-color);
  margin-top: 5px;
}

.insurance-renew-btn-txt {
  font-family: typography.$font-bold;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 120%;
  color: var(--k4n-primary-color);
}

.insurance-renew-arrow {
  width: 30px;
  height: 16px;
  margin-left: 24px;
  margin-bottom: 3px;
}

.insurance-terms-policy {
  padding-top: 48px;
  padding-bottom: 48px;
}

.insurance-terms-apolicy-txt {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 100%;
  color: #ffffff;
  margin-left: 5px;
}

.insurance-terms-apolicy-txt:hover {
  cursor: pointer;
  border-bottom: 1px solid var(--k4n-primary-color);
}

.insurance-terms-apolicy-icon {
  width: 25px;
  padding-bottom: 3px;
}

.insurance-terms-link {
  text-align: left;
}

.insurance-policy-link {
  text-align: left;
  padding-top: 32px;
}

.insurance-links-container {
  margin: 25px 0 15px 0;
}

.insurance-renew-col {
  padding-top: 70px;
  max-width: 465px !important;
}

.modal-pdf-text {
  font-family: typography.$font-bold;
  color: #ffffff;
  margin-top: 10px;
}

.pdf-modal-change-page-btn {
  width: 32px;
  height: 16px;
  border-radius: 28px;
  border: none;
  margin-right: 5px;
  background-color: var(--k4n-secondary-color);
  mask-size: contain;
  mask-repeat: no-repeat;
  -webkit-mask-image: url("../../../../../assets/images/arrow.svg");
  mask-image: url("../../../../../assets/images/arrow.svg");
}

.pdf-modal-previous-page-button {
  @extend .pdf-modal-change-page-btn;
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.pdf-modal-next-page-button {
  @extend .pdf-modal-change-page-btn;
}

.modal-pdf-href {
  font-family: typography.$font-bold;
  color: var(--k4n-secondary-color);
  font-size: 18px;
  line-height: 120%;
  margin: 10px;
  display: flex;
  flex-wrap: wrap;
}

.modal-pdf-href:hover {
  font-family: typography.$font-bold;
  opacity: 0.9;
  color: var(--k4n-secondary-color);
}

.btn-modal-pdf {
  width: 135px;
  height: 70px;
  background: var(--k4n-secondary-color);
  border-radius: 28px;
  border: none;
  float: right;
}

.btn-modal-pdf:hover,
.btn-modal-pdf:active,
.btn-modal-pdf:focus {
  opacity: 0.9;
  background: var(--k4n-secondary-color);
  border-radius: 28px;
  border: none;
  box-shadow: none;
}

.txt-modal-pdf-ok {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 120%;
  color: var(--k4n-primary-color);
  padding-right: 14px;
}

.border-radius-table {
  border-radius: 13px 0px 0px 13px !important;
  border-spacing: 0;
}

.border-radius-table2 {
  border-radius: 0px 13px 13px 0px !important;
  border-spacing: 0;
}

.installments-table {
  border-collapse: collapse;
  padding: 15px;
  width: 100%;
  color: black;
  text-align: center;
}

table.installments-table td,
table.installments-table th {
  padding: 10px;
}

.installments-th {
  background-color: var(--k4n-primary-color);
  color: #ffffff;
  font-weight: bold;
  font-size: 18px;
  height: 50px;
  border-radius: 20px;
}
.installments-th > th {
  transform: translateY(-20%);
}
.centerExclude {
  transform: translateY(0) !important;
}
.installments-td {
  padding: 15px;
}

.insurance-installments {
  width: 100%;
  border-radius: 20px;
  padding: 15px;
}

.spinner-renew-boleto {
  width: 22px;
  height: 22px;
  color: var(--k4n-secondary-color);
}

@media screen and (max-width: 768px) {
  .insurance-terms-policy {
    padding-left: 20px;
  }

  .insurance-product-status {
    transform: translateX(-3.5%);
  }
}
