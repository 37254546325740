@use "../../../../../../assets/scss/colors";
@use "../../../../../../assets/scss/typography";

.bc-step-2-header {
  width: 100%;
  margin-top: 89px;
  display: inline-flex;
  height: 54px;
  padding-bottom: 25px;
  border-bottom: 1px solid rgba(10, 2, 3, 0.1);
}

.bc-step-2-title {
  font-family: typography.$font-bold;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 22px;
  color: var(--k4n-primary-light-color);
  display: inline-block;
  white-space: nowrap;
}

.bc-step-2-input-row-1 {
  width: 100%;
  height: auto;
  position: relative;
  margin-top: 40px;
}

.pd-currency {
  padding-left: 49px;
  padding-top: 11px;
}

.currency-txt {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  padding-left: 27px;
  padding-top: 23px;
  color: colors.$complementary-black;
}

.mx-wd-339 {
  max-width: 339px;
}

.mx-wd-140 {
  max-width: 112px;
}

.mx-wd-204 {
  max-width: 204px;
}

.mx-wd-228 {
  max-width: 228px;
}

.mx-wd-364 {
  max-width: 364px;
}

.mn-hg-54 {
  min-height: 54px;
}

.pr-36 {
  padding-right: 36px;
}

.bc-step-2-header-2 {
  position: relative;
  margin-top: 48px;
  height: 54px;
  padding-bottom: 25px;
  border-bottom: 1px solid rgba(10, 2, 3, 0.1);
}

.bc-step-2-issue-date-txt {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 18px;
  color: #737373;
}

.bc-step-2-issue-date-col {
  display: grid;
  max-height: 36px;
  padding-top: 3px;
}

.bc-step-2-select {
  width: 221px;
  height: 55px;
  border: 2px solid colors.$primary-white-background;
  background: transparent;
  border-radius: 16px;
  color: colors.$complementary-black;
  padding-left: 23px;
  padding-top: 5px;
  background-image: url(../../../../../../assets/images/arrow_yellow_down.svg);
  background-repeat: no-repeat;
  background-position: calc(100% - 14px) center;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
  cursor: pointer;

  option {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.bc-step-2-select:focus-visible {
  outline: none;
}

.mx-wd-245 {
  max-width: 245px;
}

.bc-step-2-select-due-date {
  padding-top: 5px;
  width: 204px !important;
}

.bc-step-2-select-deadline {
  padding-top: 5px;
  width: 232px !important;
}

.bc-step-2-select-discount {
  width: 340px;
}

.bc-step-2-fee-date {
  padding-top: 5px;
  width: 221px !important;
}

.bc-step-2-fee-value {
  width: 221px !important;
}

.bc-step-2-discount-date {
  padding-top: 5px;
  width: 340px !important;
}

.bc-step-2-discount-value {
  width: 340px !important;
}

.mb-24 {
  margin-bottom: 24px;
}

.pl-24 {
  padding-left: 24px !important;
}

.bc-step-2-pct {
  left: 171px;
  padding-top: 20px !important;
  opacity: 0.5;
}

.bc-step-2-pct-discount {
  padding-left: 305px !important;
  padding-top: 20px !important;
  opacity: 0.5;
}

@media only screen and (max-width: 1236px) {
  .input-doc-mt-24 {
    margin-top: 24px;
  }
}
