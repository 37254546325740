@use "../../../../assets/scss/colors";
@use "../../../../assets/scss/typography";

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.address-input-label {
  color: var(--k4n-secondary-color);
  font-family: typography.$font-bold;
  font-size: 0.9rem;
}

.address-input {
  display: block;
  color: var(--k4n-primary-light-color);
  font-family: typography.$font-main;
  caret-color: var(--k4n-primary-light-color);
  border: none;
  border-radius: 28px;
  background-color: var(--k4n-secondary-color);
  font-size: 1rem;
  outline: none;
  padding: 10px 20px;
  width: 100%;
  margin: 8px 0 16px;
}

.address-input:disabled {
  background-color: var(#d4d4d438);
  /* padding: 10px 0; */
}

.address-input-disabled-bg {
  background-color: #d4d4d438;
}

@media screen and (max-width: 768px) {
  .address-input {
    background-color: var(--k4n-primary-light-color);
  }
  .address-input:disabled {
    background-color: var(#d4d4d438);
    /* padding: 10px 0; */
  }
  .address-input-disabled-bg {
    background-color: #d4d4d438;
  }
}
