@use "../../../../assets/scss/colors";
@use "../../../../assets/scss/typography";

body {
  color: var(--k4n-primary-light-color);
  font-family: typography.$font-main;
}

html {
  font-size: 20px;
}

.k4n-white-logo {
  margin-top: 47px;
}

.onboarding-mobile-content {
  background-color: var(--k4n-primary-color);
  min-height: 100vh;
  height: 100%;
  overflow-y: none;
}

.gray-bg {
  width: 100%;
  height: 100%;
  margin: 0;
  background-image: url("../../../../assets/images/gray-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.corner-image-bg {
  width: 100%;
  height: 100%;
  margin: 0;
  background-position: bottom right;
  background-image: url("../../../../assets/images/joyful-female.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.dark-overlay {
  width: 100%;
  height: 100%;
  margin: 0;
  background-color: rgba($color: #000000, $alpha: 0.2);
}

.start-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: var(--k4n-primary-light-color);
  font-family: typography.$font-main;
  max-width: 600px;
  flex: 1;
  margin-left: 60px;
}

.mt-60px {
  margin-top: 60px;
}

.ml-60px {
  margin-left: 60px;
}

.end-text-title {
  font-size: 3rem;
  line-height: 4rem;
  font-family: typography.$font-bold;
  color: var(--k4n-primary-light-color);
}

.start-text-title {
  font-size: 3rem;
  line-height: 4rem;
  font-family: typography.$font-bold;
}

.start-button {
  background-color: var(--k4n-secondary-color);
  font-family: typography.$font-bold;
  color: var(--k4n-quaternary-color);
  padding: 20px 60px;
  border-radius: 25px;
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  white-space: nowrap;
  width: fit-content;
}

.start-button::after {
  content: " ";
  background-image: url("../../../../assets/images/arrow.svg");
  background-size: 40px 20px;
  width: 40px;
  height: 20px;
  margin-left: 30px;
}

.loading.start-button::after {
  background-image: url("../../../../assets/images/spinner-white.svg");
  background-position: center;
  background-size: 40px 40px;
  width: 40px;
  height: 20px;
}

.loading {
  cursor: wait !important;
}

.contaniner-pj {
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  width: 100%;
}

.onboarding-pj-img {
  width: 100%;
  min-height: 100vh;
  height: 100%;
  margin: 0;
  background-position: center;
  background-image: url("../../../../assets/images/woman_with_tablet.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.onboarding-dark-overlay {
  width: 100%;
  height: 100%;
  margin: 0;
  background-color: rgba($color: #000000, $alpha: 0.8);
}

.title-color,
.title-color > p {
  color: #fbb040;
  // background-color: transparent;
}

.text-weight {
  font-family: typography.$font-bold;
  font-size: 24px;
}

.text-style {
  margin-block-start: 0;
  padding-inline-start: 20px;
}

.text-style > li {
  font-family: typography.$font-main;
  font-size: 20px;
}

.text-decoration-item-pj {
  text-decoration: underline;
  text-underline-offset: 4px;
}

.button-pj-container {
  position: relative;
  width: 100vw;
}

.button-pj-container > button {
  float: right;
  position: absolute;
  bottom: 4rem;
  right: 2rem;
  // bottom: 1rem;
  // right: 6rem;
}

.onboarding-pj-go-back {
  cursor: pointer;
  width: 45px;
  height: 25px;
}

.onboarding-go-back-white {
  outline: none;
  border: none;
  cursor: pointer;
  background-color: transparent;
  background-image: url("../../../../assets/images/arrow.svg");
  background-size: 32px 16px;
  width: 32px;
  height: 16px;
  transform: scaleX(-1);
}

.onboarding-pj-form-container {
  display: flex;
  flex-direction: column;
  padding-left: 98px;
  padding-top: 65px;
  padding-bottom: 10px;
  padding-right: 60px;
  color: var(--k4n-primary-light-color);
  font-family: typography.$font-main;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  max-width: 1200px;
}

// mobile break point
@media screen and (max-width: 768px) {
  .onboarding-pj-form-container {
    padding-left: 35px;
    padding-right: 35px;
  }
}

.onboarding-btn-next {
  background-color: var(--k4n-secondary-color);
  background-image: url("../../../../assets/images/arrow.svg");
  background-size: 36px 20px;
  background-repeat: no-repeat;
  background-position: center;
  width: 81px;
  height: 60px;
  outline: none;
  border: none;
  cursor: pointer;
  border-radius: 25px;
}

.onboarding-pj-btn-next-txt {
  color: var(--k4n-primary-color);
  font-family: typography.$font-bold;
  font-size: 1rem;
}

.onboarding-pj-btn-next-txt.loading {
  background-image: url("../../../../assets/images/spinner-white.svg");
  background-repeat: no-repeat;
  background-size: 50px 50px;
}

.onboarding-pj-btn-next-txt-div {
  width: 94.91px;
  display: flex;
  align-items: center;
  height: 58px;
  margin: 0 auto;
}

.ml-32 {
  margin-left: 32px;
}

/* Hide the actual upload file input */
.onboarding-pj-file-input-hidden {
  display: none;
}

.onboarding-arrow-btn-div {
  display: flex;
  align-items: center;
  width: 43px;
  height: 50px;
  float: right;
  margin-right: 33px;
}

.onboarding-pj-btn-next {
  background-color: var(--k4n-secondary-color);
  display: inline-flex;
  width: 300px;
  height: 60px;
  outline: none;
  border: none;
  cursor: pointer;
  border-radius: 25px;
}

.onboarding-pj-btn-add {
  background-color: transparent;
  width: 278px;
  height: 60px;
  outline: none;
  border: 1px solid var(--k4n-secondary-color);
  font-family: typography.$font-bold;
  font-size: 1rem;
  color: var(--k4n-secondary-color);
  cursor: pointer;
  float: right;
  border-radius: 25px;
}

.onboarding-pj-btn-add:hover {
  background-color: var(--k4n-secondary-color);
  border: none;
  color: var(--k4n-primary-light-color);
}

.onboarding-btn-next.loading {
  background-image: url("../../../../assets/images/spinner-white.svg");
  background-size: 50px 50px;
}

.onboarding-btn-primary {
  border: none;
  border-radius: 28px;
  outline: none;
  color: var(--k4n-secondary-color);
  font-family: typography.$font-bold;
  background-color: var(--k4n-primary-light-color);
  padding: 25px 55px;
}

.ml-3 {
  margin-left: 1rem;
}

.qr-code-container {
  max-width: 230px;
}

.onboarding-question-primary {
  font-size: 2rem;
  line-height: 2.2rem;
  color: var(--k4n-secondary-color);
  font-family: typography.$font-bold;
  margin: 20px 0;
}

.onboarding-reminder-primary {
  font-size: 2.5rem;
  line-height: 2.2rem;
  color: var(--k4n-secondary-color);
  font-family: typography.$font-bold;
  margin-top: 33px;
  margin-bottom: 17px;
}

.onboarding-expand-primary {
  font-size: 2.5rem;
  line-height: 2.2rem;
  color: var(--k4n-secondary-color);
  font-family: typography.$font-bold;
  margin-top: 15px;
  border-top: 1px solid #33708a;
  display: inline-flex;
}

.onboarding-expand-border-bottom {
  border-bottom: 1px solid #33708a;
  padding-bottom: 15px;
}

.onboarding-pj-form-row {
  margin-top: 35px;
}

.mb-16 {
  margin-bottom: 16px;
}

.divider-pj-form {
  margin-top: 16px;
  margin-bottom: 16px;
  border: 1px solid var(--k4n-primary-light-color);
  max-width: 692px;
  opacity: 0.2;
}

.onboarding-pj-input {
  color: var(--k4n-primary-color);
  font-family: typography.$font-main;
  background-color: var(--k4n-primary-light-color);
  padding: 24px 32px;
  border-radius: 30px;
  outline: none;
  border: none;
  caret-color: var(--k4n-secondary-color);
  height: 60px;
}

.onboarding-pj-input::placeholder {
  color: var(--k4n-primary-color);
  font-family: typography.$font-main;
  font-size: 0.9rem;
}

.onboarding-278-input {
  width: 278px;
}

.onboarding-180-input {
  width: 180px;
}

.onboarding-671-input {
  max-width: 671px;
  width: 100%;
}

.onboarding-689-input {
  max-width: 689px;
  width: 100%;
}

.col-mx-wd-203 {
  max-width: 203px;
}

.col-mx-wd-195 {
  max-width: 195px;
}

.input-mx-wd-100-pj {
  width: 100%;
}

.input-invalid {
  border: 1px solid #ff5050 !important;
  margin-bottom: 8px !important;
}

.file-name {
  color: var(--k4n-primary-light-color);
  font-family: typography.$font-main;
  font-size: 0.9rem;
}

.file-row {
  margin: 0.5rem 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.onboarding-pj-close-icon {
  cursor: pointer;
}

.input-error-pj {
  color: #ff5050;
  margin-left: 33px;
}

.input-error {
  color: #ff5050;
  margin-bottom: 20px;
}

.no-pd-right {
  padding-right: 0;
}

.onboarding-btn-link {
  color: var(--k4n-secondary-color);
  margin-right: 5px;
  cursor: pointer;
  text-decoration: underline;
  background-color: transparent;
  border: none;
  outline: none;
  white-space: nowrap;
}

.mx-wd-695 {
  max-width: 695px;
}

.mx-wd-722 {
  max-width: 722px;
}

.mx-wd-518 {
  max-width: 518px;
}

.mx-wd-618 {
  max-width: 618px;
}

.mx-wd-602 {
  max-width: 602px;
}

.pd-right-8-pj {
  padding-right: 8px;
}

.pd-left-8-pj {
  padding-left: 8px;
}

.mx-wd-100 {
  max-width: 100%;
}

.mx-wd-376 {
  max-width: 376px;
}

.mx-wd-320 {
  max-width: 320px;
}

.mt-35 {
  margin-top: 35px;
}

.mr-15 {
  margin-right: 15px;
}

.no-end-of-tem-checkbox {
  border: 1px solid var(--k4n-secondary-color);
  background-color: transparent;
  height: 24px;
  width: 24px;
  border-radius: 5px !important;
  margin-left: 30px;
}

.no-end-of-tem-checked {
  background-color: var(--k4n-secondary-color);
  background-image: url("../../../../assets/images/check.svg");
  background-size: 12px 12px;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 5px;
}

.flx-align-center {
  display: flex;
  align-items: center;
}

.onboarding-pj-edit-btns {
  float: right;
  display: flex;
  align-items: center;
}

.onboarding-close-btn {
  outline: none;
  border: none;
  cursor: pointer;
  background-color: transparent;
  background-image: url("../../../../assets/images/close.svg");
  background-size: 32px 16px;
  width: 32px;
  height: 16px;
}

#saffe-camera,
.bg-green {
  /* Overwite saffe components style */
  background: var(--k4n-primary-color) !important;
  background-color: var(--k4n-primary-color) !important;
}

.phone-verify-input-pj {
  border: none;
  outline: none;
  color: var(--k4n-primary-light-color);
  caret-color: var(--k4n-secondary-color);
  font-family: typography.$font-main;
  background-color: var(--k4n-primary-light-color);
  margin-right: 10px;
  padding: 15px;
  text-align: center;
  border-radius: 20px;
}

.onboarding-benefits-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.onboarding-pj-flex-end {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-end;
  height: 100%;
  padding-bottom: 65px;
  padding-top: 30px;
}

.onboarding-benefit {
  background-color: var(--k4n-primary-light-color);
  width: 280px;
  margin-bottom: 30px;
  padding: 20px 30px;
  border-radius: 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.onboarding-checkbox {
  border: 1px solid var(--k4n-secondary-color);
  background-color: transparent;
  height: 20px;
  width: 20px;
  border-radius: 5px;
}

.onboarding-checked {
  background-color: var(--k4n-secondary-color);
  background-image: url("../../../../assets/images/check.svg");
  background-size: 12px 12px;
  background-position: center;
  background-repeat: no-repeat;
}

.qr-code-image {
  width: 100%;
  margin-top: 20px;
  background-image: url("../../../../assets/images/spinner-white.svg");
  background-position: center;
  background-size: 50px 50px;
  background-repeat: no-repeat;
  min-height: 50px;
}

.sms-text-finish {
  font-size: 1.75rem;
  font-weight: bold;
}

.text-finish {
  font-size: 1.25rem;
}

.onboarding-pj-medium-title {
  color: var(--k4n-primary-light-color);
  font-family: typography.$font-bold;
  font-size: 1.5rem;
}

.onboarding-pj-white-txt {
  color: var(--k4n-primary-light-color);
  font-family: typography.$font-main;
  font-size: 1.25rem;
  opacity: 0.9;
}

.mt-20 {
  margin-top: 20px;
}

.onboarding-pj-expand-arrow {
  width: 23px;
  height: 12px;
  cursor: pointer;
}

.onboarding-pj-expand-arrow-active {
  transform: rotate(180deg);
  width: 23px;
  height: 12px;
  cursor: pointer;
}

.onboarding-pj-expand-arrow-div {
  width: 22px;
  display: flex;
  align-items: center;
  height: 100%;
  float: right;
}

.onboarding-pj-expand-row {
  width: 100%;
  margin: 0;
  padding-top: 10px;
  cursor: pointer;
}

.no-padding-col {
  padding: 0;
}

.onboarding-pj-edit-remove-a {
  text-decoration: underline;
  text-underline-offset: 6px;
  color: var(--k4n-primary-light-color);
  font-size: 0.8rem;
  font-family: typography.$font-main;
  cursor: pointer;
}

.onboarding-pj-edit-remove-a:hover {
  color: var(--k4n-secondary-color);
}

.ml-15 {
  margin-left: 15px;
}

.onboarding-pj-expand-item-arrow {
  cursor: pointer;
}

.onboarding-pj-item-arrow {
  float: right;
}

.onboarding-pj-input-disabled {
  background-color: #6291a4 !important;
}

.onboarding-pj-input-label-bottom {
  font-size: 0.7rem;
  font-family: typography.$font-main;
  color: var(--k4n-primary-light-color);
  margin-left: 32px;
  position: relative;
  top: 6px;
}

.onboarding-pj-small-title {
  color: var(--k4n-primary-light-color);
  font-family: typography.$font-bold;
  font-size: 1rem;
}

.onboarding-pj-small-title-second-color {
  color: var(--k4n-secondary-color);
  font-family: typography.$font-bold;
  font-size: 1rem;
}

.onboarding-pj-small-txt-white {
  color: var(--k4n-primary-light-color);
  font-family: typography.$font-main;
  font-size: 1rem;
}

.onboarding-pj-small-super-small-txt-white {
  color: var(--k4n-primary-light-color);
  font-family: typography.$font-main;
  font-size: 0.7rem;
}

.onboarding-pj-list-row {
  margin-top: 15px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding: 15px 0px 15px 0px;
}

.onboarding-pj-list-collapsed-row {
  margin-top: 15px;
}

.mt-33 {
  margin-top: 33px;
}

.mt-15 {
  margin-top: 15px;
}

.col-onboarding-pj-txt-center {
  text-align: center;
}

.col-onboarding-pj-txt-left {
  text-align: left;
}

.onboarding-pj-select {
  height: 60px;
  color: var(--k4n-primary-light-color);
  font-family: typography.$font-main;
  font-size: 0.9rem;
  background-color: var(--k4n-primary-light-color);
  border-radius: 30px;
  outline: none;
  border: none;
  padding-left: 23px;
  background-image: url(../../../../assets/images/arrow_expand_yellow.svg);
  background-repeat: no-repeat;
  background-position: calc(100% - 14px) center;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .col-onboarding-pj-txt-center {
    text-align: unset;
    margin-top: 15px;
  }

  .pd-right-8-pj {
    padding-right: 16px;
  }

  .pd-left-8-pj {
    padding-left: 16px;
  }

  .mb-col-pj-270 {
    margin-top: 35px;
    max-width: 270px;
  }

  .mb-col-pj-mt-35 {
    margin-top: 35px;
  }

  .start-text-title {
    font-size: 2rem;
    line-height: 2.3rem;
  }
  .onboarding-pj-input {
    background-color: var(--k4n-primary-light-color);
  }
  .onboarding-form-container {
    padding: 60px 35px;
    height: 100%;
  }
  #saffe-camera,
  .bg-green {
    /* Overwite saffe components style */
    background: var(--k4n-primary-color) !important;
    background-color: var(--k4n-primary-color) !important;
  }
  .onboarding-btn-primary {
    background-color: var(--k4n-primary-light-color);
  }
  .phone-verify-input-pj {
    background-color: var(--k4n-primary-light-color);
  }
  .start-text-container {
    margin: 0 35px;
  }
  .start-button {
    color: var(--k4n-primary-color);
    padding: 20px 45px;
    white-space: nowrap;
  }
}
