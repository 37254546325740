@use "../../../../../assets/scss/colors";
@use "../../../../../assets/scss/typography";

.boleto-creation-container {
  width: 100%;
  display: flex;
  height: auto;
  position: relative;
  top: 56px;
}

.boleto-creation {
  width: 100%;
  height: 77px;
  background: var(--k4n-secondary-light-color);
  padding-left: 32px;
  padding-right: 32px;
  border-radius: 16px;
}

.boleto-creation-title {
  font-family: typography.$font-bold;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 22px;
  color: var(--k4n-primary-light-color);
  display: inline-block;
}

.boleto-creation-input {
  width: 315px;
  height: 55px;
  border: 2px solid colors.$primary-white-background;
  background: transparent;
  border-radius: 16px;
  color: colors.$complementary-black;
  padding-left: 23px;
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
}

.boleto-creation-input:focus {
  color: colors.$complementary-black;
  border-radius: 16px;

  label {
    display: block !important;
  }
}

.boleto-creation-input:focus + label {
  label {
    display: block !important;
  }
}

.boleto-creation-input::-webkit-input-placeholder {
  /* Edge */
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #737373;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.boleto-creation-input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #737373;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.boleto-creation-input::placeholder {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #737373;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.boleto-creation-input-label {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 18px;
  color: #737373;
  position: absolute;
  padding-left: 26px;
  padding-top: 3px;
  display: none;
}

.bc-error-msg {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: #ed6465;
  text-align: left;
  padding-top: 5px;
  padding-left: 30px;
}

.mx-wd-row {
  max-width: 1500px;
}

.bc-input-value-txt {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: colors.$complementary-black;
}

.bc-btn-yellow {
  font-family: typography.$font-bold;
  background: var(--k4n-secondary-color);
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 120%;
  color: var(--k4n-quaternary-color);
  width: 264px;
  height: 60px;
  border-radius: 28px;
  border: none;
}

.bc-btn-yellow:hover {
  opacity: 0.9;
}

.bc-btn-transparent {
  width: 170px;
  height: 60px;
  font-family: typography.$font-bold;
  background: transparent;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 120%;
  color: var(--k4n-secondary-color);
  border: 2px solid var(--k4n-secondary-color);
  border-radius: 28px;
  margin-right: 40px;
}

.bc-btn-transparent:hover {
  background: var(--k4n-secondary-color);
  color: var(--k4n-quaternary-color);
}

.bc-row-btns {
  width: 100%;
  height: auto;
  position: relative;
  margin-top: 81px;
  text-align: right;
}

.bc-step-header {
  width: 100%;
  margin-top: 89px;
  display: inline-flex;
  height: 54px;
  padding-bottom: 25px;
  border-bottom: 1px solid rgba(10, 2, 3, 0.1);
}

.bc-step-title {
  font-family: typography.$font-bold;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 22px;
  color: var(--k4n-secondary-color);
  display: inline-block;
  white-space: nowrap;
}

.bc-step {
  width: 100%;
  height: auto;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  min-height: 500px;
  margin-top: 80px;
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 25px;
}
