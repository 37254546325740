@use "../../../../assets/scss/colors";
@use "../../../../assets/scss/typography";

.payment-wrapper {
  background-color: transparent;
  width: 100%;
  height: 100%;
  margin: 0;
  display: inline-block;
  padding-left: 75px;
  padding-right: 75px;
}

.payment-wrapper-mobile {
  background-color: transparent;
  width: 100%;
  height: 100%;
  margin: 0;
}

.payment-mobile-header {
  width: 100%;
  height: 100%;
  padding-top: 5%;
  background-color: var(--k4n-primary-color);
}

.payment-nav {
  width: 100%;
  background: var(--k4n-secondary-light-color);
  border-radius: 16px;
  height: 77px;
  display: flex;
  align-items: center;
  padding: 0 32px;
  justify-content: space-between;
}

.payment-nav-title {
  font-family: typography.$font-bold;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: var(--k4n-quaternary-color);
  height: 22px;
}

.payment-nav-buttons {
  display: inline-flex;
}
.steps-btn {
  width: 25px;
  height: 25px;
  background-color: transparent;
  border: 1px solid var(--k4n-secondary-color);
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 16px;
  font-family: typography.$font-bold;
  color: var(--k4n-primary-color);
  font-size: 16px;
  cursor: default !important;
}

.steps-btn-active {
  width: 25px;
  height: 25px;
  background-color: var(--k4n-tertiary-color);
  border: 1px solid #fff;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 16px;
  font-family: typography.$font-bold;
  color: #fff;
  font-size: 16px;
  cursor: default !important;
}
