@use "../../../../../assets/scss/colors";
@use "../../../../../assets/scss/typography";

.payment-mobile-panel {
  width: 100%;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  height: 100%;
  left: 0px;
  top: 24px;
  background: var(--k4n-primary-color);
}

.payment-mobile-form {
  background: #fff;
  border-radius: 28px;
  padding: 10%;
  height: 100vh;
  overflow-y: auto;
}

.payment-mobile-close-btn {
  width: 18px;
  height: 18px;
  outline: none;
  border: none;
  color: var(--k4n-secondary-color);
  background-color: var(--k4n-secondary-color);
  mask-size: cover;
  -webkit-mask-image: url("../../../../../assets/images/close.svg");
  mask-image: url("../../../../../assets/images/close.svg");
}

.payment-mobile-btn {
  background: transparent;
  border: transparent;
}

.payment-mobile-title {
  margin-top: 20px;
  display: grid;
}

.payment-mobile-title-h1 {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  color: var(--k4n-secondary-color);
  padding-top: 5%;
  padding-bottom: 5px;
}

.payment-mobile-title-h2 {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
}

.payment-mobile-bar-code-div {
  display: flex;
  align-content: center;
  padding-top: 5%;
}

.payment-mobile-bar-code-box {
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  margin: 20px;
  background: var(--k4n-secondary-light-color);
  border-radius: 28px;
  padding: 0% 5%;
}

.payment-mobile-bar-code-input {
  background-color: transparent;
  border: transparent;
  color: #737373;
  width: 100%;
}

.payment-mobile-btn-div {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: 20px;
  height: 30%;
  padding-right: 20px;
}

.payment-mobile-info {
  margin-top: 5%;
}

.payment-mobile-row {
  margin: 10px 0px;
  border-bottom: 1px solid rgba(10, 2, 3, 0.1);
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.payment-mobile-span-title-black {
  font-family: typography.$font-main;
  font-style: normal;
  color: #0a0203;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
}

.payment-mobile-span-content-grey {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: #737373;
  padding-left: 8px;
}

.payment-mobile-margin-20 {
  margin-top: 20px;
}

.step3-section-mobile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  border-bottom: 1px solid rgba(10, 2, 3, 0.1);
}

.payment-receipt-mobile-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  border-bottom: 1px solid rgba(10, 2, 3, 0.1);
}

.receipt-capture-mobile {
  padding: 5% 5%;
}
.payment-header-mobile {
  background-color: var(--k4n-primary-color);
  display: flex;
  flex-direction: column;
  padding: 5%;
}

.payment-mobile-bar-code-container {
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  border-radius: 28px 28px 0 0;
}
