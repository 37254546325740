:root {
  --k4n-primary-color: #005476; /* backgrounds and some texts */
  --k4n-secondary-color: #fbb040; /* backgrounds and some texts */
  --k4n-primary-light-color: #095f82; /* inputs and buttons texts */
  --k4n-secondary-light-color: #fff2de; /* some backgrounds */

  /* insurance */
  --k4n-tertiary-color: #818585; /* some tabs in insurance */

  --k4n-quaternary-color: #000; /* labels and feature titles */
  --k4n-senary-color: #fff; /* Navbar Button Background */
  --k4n-quinary-color: #000; /* Navbar button Icons and navbar button text*/
}

#root {
  height: 100%;
}

.primary-btn {
  font-family: "K4n-Bold", sans-serif !important;
  color: var(--k4n-quaternary-color) !important;
}

.secondary-btn {
  font-family: "K4n-Bold", sans-serif !important;
  color: var(--k4n-quinary-color) !important;
}
