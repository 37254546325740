@use "../../../../assets/scss/colors";
@use "../../../../assets/scss/typography";

.signature-transaction-container {
  background-color: var(--k4n-primary-color);
  width: 100%;
  height: auto;
  margin: 0;
  display: inline-block;
  min-height: 100%;
}

.signature-center-div {
  margin: 0 auto;
  width: 100%;
  max-width: 700px;
  height: auto;
}

.signature-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 25px;
  margin-top: 15px;
}

.signature-transaction-input {
  width: 312px;
  height: 70px;
  background: var(--k4n-primary-light-color);
  border-radius: 28px;
  border: none;
  padding-left: 28px;
  padding-right: 28px;
  margin-bottom: 18px;
  color: #ffffff;
}

.signature-transaction-input::-webkit-input-placeholder {
  /* Edge */
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  color: #ffffff;
  opacity: 0.65;
}

.signature-transaction-input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  color: #ffffff;
  opacity: 0.65;
}

.signature-transaction-input::placeholder {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  color: #ffffff;
  opacity: 0.65;
}

.signature-transaction-input:focus,
.signature-transaction-input:focus {
  border-radius: 28px;
  outline: none;
  border: 1px solid #ffffff;
  background-color: var(--k4n-primary-color);
}

.signature-transaction-input:focus::placeholder {
  color: transparent;
}

.signature-transaction-btn-next {
  width: 312px;
  height: 70px;
  background: var(--k4n-secondary-color);
  border-radius: 28px;
  border: none;
  text-align: left;
  font-family: typography.$font-bold;
  line-height: 120%;
  font-size: 20px;
  color: var(--k4n-primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
}

.signature-transaction-btn-next:hover {
  opacity: 0.9;
}

.signature-transaction-back {
  width: 241px;
  height: 70px;
  background: var(--k4n-secondary-color);
  border-radius: 28px;
  border: none;
  text-align: left;
  font-family: typography.$font-bold;
  line-height: 120%;
  font-size: 20px;
  color: var(--k4n-primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
}

.signature-transaction-back:hover {
  opacity: 0.9;
}

.signature-transaction-btn-arrow {
  width: 42.22px;
  height: 23.07px;
  float: right;
}

.signature-transaction-form-error {
  width: 355px;
  height: 204px;
  margin: 0 auto;
  background: var(--k4n-primary-light-color);
  border-radius: 29px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 30px;
  padding-right: 30px;
  margin-bottom: 61px;
}

.signature-transaction-form-error-txt {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: -0.01em;
  color: #d89b9b;
  padding-bottom: 8px;
}

.signature-transaction-form-check-txt {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: -0.01em;
  color: #65bf73;
  padding-bottom: 8px;
}

.signature-transaction-error-check {
  width: 12px;
  height: 12px;
  margin-right: 8px;
}

.signature-transaction-confirmation-error {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: -0.01em;
  color: #d89b9b;
  padding-bottom: 8px;
}

.signature-transaction-btn-spinner {
  width: 50px;
  float: right;
}

.signature-transaction-span-error {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 120%;
  color: #d89b9b;
  margin-bottom: 18px;
}

.signature-spinner {
  margin: 0 auto;
  display: block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
  color: #fff;
}

.signature-title {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 120%;
  text-align: center;
  color: var(--k4n-secondary-color);
}
