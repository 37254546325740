@use "../../../../assets/scss/colors";
@use "../../../../assets/scss/typography";

.switcher-wrapper {
  max-width: 753px;
  margin-top: 31px;
}

.switcher-col {
  min-width: 102px;
}

.switcher-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  width: 92px;
  height: 96px;
  background: var(--k4n-senary-color);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  cursor: pointer;
  z-index: 10;
  position: relative;
}

.switcher-item:hover {
  background: var(--k4n-secondary-color);

  svg {
    filter: brightness(0) invert(1);
  }

  span {
    color: #fff;
  }
}

.m-auto {
  padding-top: 31px;
  margin: 0 auto !important;
}

.switcher-item-active {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  width: 92px;
  height: 96px;
  background: var(--k4n-tertiary-color) !important;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  cursor: pointer;
}

.img-filter {
  svg {
    filter: brightness(0) invert(1);
  }
  span {
    color: #fff;
  }
}

.switcher-row {
  margin: 0;
  width: 100%;
  flex-wrap: nowrap;
  padding: 0;
  --bs-gutter-x: 0;
  --bs-gutter-y: 0;
}

.switcher-item-span {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  color: var(--k4n-quinary-color);
  padding-top: 11px;
}

.switcher-item-img {
  width: fit-content;
  height: 25px;
  min-height: 25px;
  max-height: 25px;
}

.hidden-row {
  padding: 0 !important;
}

@media screen and (max-width: 992px) {
  .switcher-wrapper {
    margin: 0;
    margin-top: -65px;
    max-width: 100%;
    overflow-x: scroll;
    padding: 10px 10%;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
  }
  .switcher-wrapper::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
}
