@use "../../../../../../assets/scss/colors";
@use "../../../../../../assets/scss/typography";

.panel-mobile-title {
  width: 100%;
  height: 80px;
  // border-bottom: 1px #dadada solid;
  display: flex;
  align-items: center;
}

.title-mobile-blue {
  font-family: typography.$font-bold;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 22px;
  color: #000;
}

.panel-mobile-title-step-2 {
  width: 100%;
  height: 80px;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
}

.steps-2-mobile-value-info {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.info-span-grey-24 {
  font-family: typography.$font-main;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  color: #737373;
}

.new-contact-btn-div-2 {
  display: flex;
  height: 70px;
  align-items: middle;
  padding-bottom: 30px;
  width: 100%;
  justify-content: middle;
}

.p-16 {
  font-size: 0.9em;
}

.flex-space-evenly {
  display: flex;
  justify-content: space-evenly;
  padding-left: 10px;
}

.contact-mobile-nav {
  height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
}

.contact-mobile-options-div {
  display: flex;
  height: 70px;
  width: 100%;
  align-items: center;
}

.all-mobile-contacts-btn {
  height: 50px;
  background-color: transparent;
  border-color: transparent;
  font: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 120%;
  text-align: center;
  color: var(--k4n-primary-color);
  // border-bottom: 2px solid var(--k4n-secondary-color);
}

.search-mobile-contact-div {
  display: flex;
  width: 480px;
  height: 60px;
  border: 2px solid var(--k4n-secondary-color);
  border-radius: 28px;
  align-items: center;
  gap: 5px;
}

.search-mobile-contact-input {
  border: transparent;
  background: transparent;
  color: black !important;
  padding-left: 20px;
  width: 85%;
  font-size: 20px;
}

.search-mobile-contact-input::placeholder {
  color: var(--k4n-secondary-color);
  font: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 120%;
}

.transfer-mobile-step-2-btns-div {
  padding-top: 10px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.transfer-mobile-value-div-2 {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  gap: 20px;
  padding-bottom: 20px;
}
