@use "../../../../assets/scss/colors";
@use "../../../../assets/scss/typography";

.extrato-header {
  width: 100%;
  background: var(--k4n-secondary-light-color);
  border-radius: 16px;
  height: 77px;
  display: flex;
  align-items: center;
  padding: 0 32px;
  justify-content: space-between;
  margin: 56px 0 20px 0;
}

.extrato-title {
  font-family: typography.$font-bold;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: var(--k4n-quaternary-color);
  display: inline-block;
}

.extrato-wrapper {
  background-color: transparent;
  width: 100%;
  height: 100%;
  margin: 0;
  display: inline-block;
  padding: 0 75px;
}

.extrato-wrapper-mobile {
  background-color: transparent;
  width: 100%;
  height: 100%;
  margin: 0;
  display: inline-block;
}
