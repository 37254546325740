@use "../../../../assets/scss/colors";
@use "../../../../assets/scss/typography";

.pix-wrapper {
  background-color: transparent;
  width: 100%;
  height: 100%;
  margin: 0;
  display: inline-block;
  padding-left: 75px;
  padding-right: 75px;
}

.content-table {
  width: 100%;
  height: 67px;
  display: flex;
  border-bottom: 1px solid #dadada;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
}

.align-center {
  display: flex;
  align-items: center;
}

.btn-align-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pix-content-img {
  width: 22.74px;
  height: 24.81px;
  margin-right: 10px;
}

.pix-content-txt {
  width: 190px;
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  color: #0a0203;
}

.pix-modal-sucess-h1 {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  color: var(--k4n-secondary-color);
  margin: 0;
  margin-top: 38px;
}

.pix-modal-sucess-3p {
  font-size: 32px;
  text-align: center;
  color: #ffffff;
  margin-top: 16px;
  margin-bottom: 16px;
}

.pix-modal-sucess-txt {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  margin: 0;
  margin-bottom: 10px;
}

.pix-toggle-active {
  border: 1px solid var(--k4n-secondary-color) !important;
}

.pointer {
  cursor: pointer;
}

.pix-align-btn {
  width: 100%;
  display: inline-flex;
  justify-content: end;
  padding-top: 4%;
  padding-bottom: 4%;
}

.pix-error-msg {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: #e34848;
  margin-left: 10px;
}

.pix-align-center {
  display: flex;
  margin-top: 40px;
  justify-content: center;
}

.emptySpace-pix {
  width: 100%;
  height: 21px;
  margin-bottom: 24px;
}

.gradient {
  animation-duration: 2.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeded 8%, #f7f6f6 38%, #eeeded 54%);
  background-size: 1000px 640px;
  position: relative;
  border-radius: 20px;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

// MOBILE

.pix-wrapper-mobile {
  background-color: transparent;
  width: 100%;
  height: 100%;
  margin: 0;
}

.wd-100 {
  width: 100% !important;
}
