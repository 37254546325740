@use "../../../assets/scss/colors";
@use "../../../assets/scss/typography";

.onboarding-wrapper {
  margin: 0;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  background-color: var(--k4n-primary-color);
  display: flex;
  flex-direction: row;
}

.onboarding-menu {
  height: 100%;
  overflow-y: scroll;
  padding: 20px 40px;
  background-color: var(--k4n-secondary-color);
  color: var(--k4n-primary-light-color);
  font-family: typography.$font-main;
  &::-webkit-scrollbar {
    background-color: var(--k4n-secondary-color);
    width: 0;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.4);
    border-radius: 10px;
    border: 5px solid var(--k4n-secondary-color);
  }
}

.onboarding-pj-menu {
  height: 100%;
  width: 26.09%;
  overflow-y: scroll;
  padding: 20px 40px;
  background-color: var(--k4n-secondary-color);
  color: var(--k4n-primary-color);
  font-family: typography.$font-main;
  &::-webkit-scrollbar {
    background-color: var(--k4n-secondary-color);
    width: 0;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.4);
    border-radius: 10px;
    border: 5px solid var(--k4n-secondary-color);
  }
}

.onboarding-content {
  flex: 1;
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
  &::-webkit-scrollbar {
    background: transparent; /* Chrome/Safari/Webkit */
    width: 0px;
  }
}

.progress-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.progress-bar-wrapper {
  flex: 1;
  height: 4px;
  background-color: rgba(255, 255, 255, 0.3);
  margin-right: 5px;
}

.progress-bar-filled {
  background-color: var(--k4n-primary-color);
  height: 100%;
  transition: width 0.5s linear;
}

.step-label {
  padding: 16px 22px;
  border-radius: 30px;
  margin: 15px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.step-label-active {
  font-family: typography.$font-bold;
  position: relative;
  overflow: hidden;
  display: block;
}

.step-label-validated::before,
.step-label-active::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: var(--k4n-primary-color);
  opacity: 0.2;
}

.step-label-validated {
  position: relative;
  overflow: hidden;
}

.step-label::after {
  content: " ";
  background-color: var(--k4n-primary-color);
  mask-size: cover;
  -webkit-mask-image: url("../../../assets/images/check-circle.svg");
  mask-image: url("../../../assets/images/check-circle.svg");
  min-width: 24px;
  width: 24px;
  height: 24px;
  margin-left: 10px;
  visibility: hidden;
}

.step-label-validated::after {
  visibility: inherit;
}
