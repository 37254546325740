@use "../../../../../../../assets/scss/colors";
@use "../../../../../../../assets/scss/typography";

.insurance-step3-card {
  width: 100%;
  padding: 24px;
  border-radius: 28px;
  background-color: #fbfbfb;
  float: left;
  text-align: left;
}

.insurance-step3-card-h1 {
  font-family: typography.$font-bold;
  color: var(--k4n-primary-color);
  margin: 0;
  font-size: 20px;
  line-height: 28px;
}

.card-input {
  width: 148px !important;
  margin-bottom: 10px;
}

/* Form - Cartão de credito */

.Form-step3 {
  width: 100%;
  min-width: 100%;
  height: auto;
  padding-top: 24px;
  margin: 0 auto;
}

.Mg-top-32 {
  margin-top: 32px;
}

.Mg-right-16 {
  margin-right: 16px;
}

.Mg-left-12 {
  margin-left: 12px;
}

.Mg-top-24 {
  margin-top: 24px;
}

.Mg-top-40 {
  margin-top: 40px !important;
}

.Mg-right-7 {
  margin-right: 7px;
}

.No-padding {
  padding: 0;
}

.No-padding-less-left {
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 0;
}

.Padding-bottom-10 {
  padding-bottom: 10px;
}

.Dp-contents {
  display: contents;
}

.Blck-mobile {
  display: block !important;
}

.Mg-negative {
  margin-bottom: -10px;
}
