@use "../../../assets/scss/colors";
@use "../../../assets/scss/typography";

.ob-camera-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1000px;
  margin: 0 auto;
}

.ob-camera-frame {
  --b: 3px; /* thickness of the border */
  --c: var(--k4n-secondary-color); /* color of the border */
  --w: 10px; /* width of border */
  --r: 10px; /* radius */

  padding: var(--b); /* space for the border */

  position: relative;

  /* width: 550px;
  min-height: 309px; */
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  background-color: #fff;
  border-radius: var(--r);
}

.ob-camera-frame::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--c, red);
  padding: var(--b);
  border-radius: var(--r);
  -webkit-mask: linear-gradient(#fff 0 0) top / calc(100% - 2 * var(--w))
      var(--b),
    linear-gradient(#fff 0 0) bottom/calc(100% - 2 * var(--w)) var(--b),
    linear-gradient(#fff 0 0) left / var(--b) calc(100% - 2 * var(--w)),
    linear-gradient(#fff 0 0) right / var(--b) calc(100% - 2 * var(--w)),
    linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  -webkit-mask-repeat: no-repeat;
  mask: linear-gradient(#fff 0 0) top / calc(100% - 2 * var(--w)) var(--b),
    linear-gradient(#fff 0 0) bottom/calc(100% - 2 * var(--w)) var(--b),
    linear-gradient(#fff 0 0) left / var(--b) calc(100% - 2 * var(--w)),
    linear-gradient(#fff 0 0) right / var(--b) calc(100% - 2 * var(--w)),
    linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask-composite: exclude;
  mask-repeat: no-repeat;
}

.ob-camera-buttons-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 20px;
}

.ob-camera-shot-button {
  cursor: pointer;
  outline: none;
  border-width: 3px;
  border-radius: 50%;
  border-style: solid;
  border-color: var(--k4n-secondary-color);
  background-color: rgba(var(--k4n-secondary-color), 0.2);
  height: 60px;
  width: 60px;
  transition: background-color ease-in 0.1s;
}

.ob-camera-shot-button:active {
  background-color: var(--k4n-secondary-color);
}

.btn-icon {
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
  height: 30px;
  width: 50px;
}

.ob-camera-text {
  background-color: rgba(255, 255, 255, 0.55);
  color: var(--k4n-primary-color);
  font-family: typography.$font-bold;
  padding: 16px 42px;
  position: absolute;
  border-radius: 28px;
}

.w-400px {
  width: 400px;
}

@media screen and (max-width: 768px) {
  /* .ob-camera-frame {
    width: unset;
    min-height: unset;
  } */
  .w-400px {
    max-width: 300px;
    width: 100%;
  }
}
