@use "../../../../assets/scss/colors";
@use "../../../../assets/scss/typography";

.withdraw-wrapper {
  background-color: transparent;
  width: 100%;
  height: 100%;
  margin-top: 40px;
  display: inline-block;
  padding-left: 75px;
  padding-right: 75px;
}
.withdraw-panel-container {
  width: 100%;
  display: flex;
  height: auto;
  flex-direction: column;
}

.withdraw-panel-title {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  color: #737373;
  padding: 50px;
}

.withdraw-panel-content {
  position: relative;
  margin-top: 27px;
  width: 100%;
  height: 687px;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  padding: 0 68px;
}

.withdraw-button {
  padding: 4vh;
  width: 100%;
  display: flex;
  justify-content: center;
}
.withdraw-modal {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.withdraw-modal-content {
  width: 100%;
  background-color: var(--k4n-primary-color);
  border-radius: 28px 28px 0 0;
  display: grid;
}

.withdraw-cedule {
  width: 200px;
  height: 120px;
  padding: 20px;
}

.withdraw-cedules-text {
  display: flex;
  justify-content: center;
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  color: var(--k4n-secondary-color);
  padding: 0 0 30px;
}

.withdraw-input {
  width: 100px;
  height: 50px;
  display: flex;
  align-items: center;
  background: var(--k4n-secondary-light-color);
  border-radius: 28px;
  padding: 0% 5%;
}

.withdraw-total {
  color: #000;
  padding: 30px;
}

.withdraw-modal-close {
  width: 22.54px;
  height: 22.54px;
  cursor: pointer;
}

.withdraw-close {
  width: 22.54px;
  height: 22.54px;
  cursor: pointer;
}

.input-withdraw-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
}

.withdraw-btn-container {
  padding: 70px;
}

.withdraw-available-bills {
  width: 100%;
  display: grid;
}

.withdraw-available-bills-text-div {
  width: 100%;
  margin-top: 20px;
}

.withdraw-available-bills-text {
  font-family: typography.$font-main;
  font-weight: 400;
  font-size: 17px;
  opacity: 0.8;
  float: left;
}

.withdraw-money-bill {
  width: 130px;
  height: auto;
  padding: 20px;
  margin: 0;
}

.withdraw-money-bill-div {
  display: grid;
  width: 100%;
  padding: 0px 48px 0px 48px;
}

@media only screen and (max-height: 670px) {
  .withdraw-money-bill-div {
    padding: 0px 30px 0px 30px !important;
  }
}

.mr-10 {
  margin-right: 10;
}
.ml-10 {
  margin-left: 10;
}

.withdraw-money-bill-items {
  display: inline-flex;
  width: 100%;
  margin: 0;
}

.withdraw-money-bill-items-div {
  width: 100%;
  display: grid;
  margin-top: 30px;
  padding: 0 25px 0 25px;
}
.withdraw-tablet-padding {
  padding: 0 20% 0 20%;
}

.withdraw-money-bill-item {
  padding: 0;
  height: 42px;
  background: #095f82;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.withdraw-money-bill-value {
  font-family: typography.$font-bold;
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.2);
}

.money-bill-item-active {
  background: var(--k4n-secondary-color) !important;
  .withdraw-money-bill-value {
    color: #ffffff !important;
  }
}

.mt-20 {
  margin-top: 20px;
}

.col-pd-right {
  padding: 0 10px 0px 0px;
}

.col-pd-left {
  padding: 0 0px 0px 10px;
}

.withdraw-info-msg {
  font-family: typography.$font-main;
  color: #ffffff;
  font-weight: 400;
  font-size: 18px;
  line-height: 120%;
  text-align: left;
  margin-top: 32px;
}

.withdraw-money-bill-img {
  margin: 0 auto;
}

.withdraw-title-div {
  width: 100%;
}

.withdraw-title {
  font-family: typography.$font-main;
  font-weight: 700;
  font-size: 32px;
  float: left;
  color: var(--k4n-secondary-color);
}

.withdraw-submit-btn {
  width: 100%;
  margin-top: 49px;
}

.withdraw-total-input-container {
  width: 100%;
  max-width: 312px;
  height: 67px;
  background: #fff2de;
  border: none;
  border-radius: 28px;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  align-items: center;
}

.withdraw-total-label {
  color: var(--k4n-primary-color) !important;
  border: none;
  font-family: typography.$font-main;
  font-weight: 700;
  font-size: 18px;
  line-height: 120%;
}

.withdraw-mt-24 {
  margin-top: 24.5px;
}

.withdraw-total-input {
  color: var(--k4n-primary-color) !important;
  border: none;
  width: 100%;
  font-family: typography.$font-main;
  font-weight: 700;
  font-size: 18px;
  line-height: 120%;
  background-color: transparent;
}

.withdraw-input-error {
  font-size: 16px;
  color: #ff5050;
  margin-bottom: 20px;
  text-align: left;
}

.withdraw-balance-div {
  width: 100%;
  display: grid;
}

.withdraw-error {
  text-align: left;
  margin-top: 10px;
}

.withdraw-balance-title {
  font-family: typography.$font-main;
  font-size: 18px;
  color: #ffffff;
  width: max-content;
  float: left;
}

.withdraw-balance-value {
  font-family: typography.$font-bold;
  font-size: 22px;
  color: #ffffff;
  width: max-content;
  float: left;
}

.withdraw-map {
  width: 100vw;
  height: 100vh;
}

.withdraw-map-button {
  padding: 4vh;
  display: flex;
  justify-content: center;
}

#map {
  width: 100%;
  height: 80vh;
  padding: 20px;
}

.map-info {
  color: #000;
}

.withdraw-panel {
  background-color: var(--k4n-primary-color);
  height: 100%;
}

.withdraw-header {
  padding: 10% 10%;
}
.withdraw-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  border-radius: 28px 28px 0 0;
  width: 100;
}

.withdraw-header-h1 {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: var(--k4n-secondary-color);
  margin-top: 40px;
}

.withdraw-header-txt {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
  margin-top: 40px;
}

.withdraw-maps {
  background-color: #ffffff;
}

.withdraw-panel .modal-container-mobile {
  border-radius: 28px 28px 0 0;
}

.withdraw-fixed-bottom {
  justify-content: center;
  display: flex;
}
