@use "../../../../../../assets/scss/colors";
@use "../../../../../../assets/scss/typography";

/* Informações de pagamento e progresso */

.insurance-step-3 {
  background: transparent;
  width: 100%;
}

.insurance-content-step-3 {
  width: 100%;
  margin: 0;
  max-width: 1250px;
}

.insurance-payment {
  width: 100%;
  min-width: 100%;
  height: auto;
  margin: 0 auto;
  padding-top: 11px;
}

.insurance-payment-day {
  max-width: 570px;
  width: 100%;
  min-height: 271px;
  padding: 24px;
  border-radius: 28px;
  background: #fbfbfb;
  text-align: left;
  float: right;
}

.insurance-payment-day-h1 {
  font-family: typography.$font-bold;
  color: var(--k4n-primary-color);
  width: 100%;
  margin: 0;
  font-size: 20px;
  line-height: 28px;
}

.insurance-payment-day-btn {
  position: relative;
  width: 82px;
  height: 32px;
  border: 1px solid var(--k4n-secondary-color);
  background: transparent;
  box-sizing: border-box;
  border-radius: 12px;
  margin-top: 24px;
  margin-right: 24px;
  font-family: typography.$font-main;
  font-size: 16px;
  line-height: 22px;
  color: var(--k4n-primary-color);
}

.insurance-payment-day-btn:hover,
.insurance-payment-day-btn:focus {
  background-color: var(--k4n-secondary-color);
  color: var(--k4n-tertiary-color);
  font-family: typography.$font-bold;
  box-shadow: none;
  border: none;
}

/* Forma de pagamento */

.insurance-form-of-payment {
  max-width: 570px;
  background: #fbfbfb;
  width: 100%;
  min-height: 271px;
  border-radius: 28px;
  padding: 24px;
}

.insurance-form-of-payment-h1 {
  font-family: typography.$font-bold;
  color: var(--k4n-primary-color);
  margin: 0;
  font-size: 20px;
  width: 60%;
  line-height: 28px;
}

.Forma-Pagamento-Btn {
  position: static;
  width: 177px;
  height: 45px;
  border: 1px solid var(--k4n-secondary-color);
  background: transparent;
  box-sizing: border-box;
  border-radius: 16px;
  margin-right: 24px;

  /* Text */

  font-family: typography.$font-main;
  font-size: 16px;
  line-height: 140%;
  color: var(--k4n-primary-color);
}

.Forma-Pagamento-Btn:hover,
.Forma-Pagamento-Btn:focus {
  background-color: var(--k4n-secondary-color);
  color: var(--k4n-tertiary-color);
  border: none;
  font-family: typography.$font-bold;
  box-shadow: none;
}

/* Row - Pagamento */

.insurance-payment-row {
  width: 100%;
  min-width: 100%;
  height: auto;
  padding-top: 42px;
  margin: 0 auto;
  padding-bottom: 80px;
}

/*  */

.insurance-step-3-tgl-active {
  border: 1px solid var(--k4n-secondary-color);
  background-color: var(--k4n-secondary-color);
  color: var(--k4n-tertiary-color);
  font-family: typography.$font-bold;
}

.Mx-wd-mg {
  max-width: 824px;
  margin: 0 auto;
}

.Mg-top-16 {
  margin-top: 16px;
}

.Mg-top-24 {
  margin-top: 24px;
}

@media screen and (max-width: 768px) {
  .insurance-form-of-payment-h1 {
    width: 100%;
  }

  .center-div {
    display: flex;
    justify-content: center;
  }

  .insurance-form-of-payment {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
