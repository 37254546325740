@use "../../../../../../assets/scss/colors";
@use "../../../../../../assets/scss/typography";

.boleto-panel-mobile {
  height: 100%;
  background-color: var(--k4n-primary-color);
}

.boleto-panel-header-mobile {
  padding: 10% 10%;
}

.boleto-panel-h1-mobile {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: var(--k4n-secondary-color);
  margin-top: 40px;
}

.boleto-panel-txt-mobile {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
  margin-top: 40px;
}

.boleto-panel-content-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  background: #ffffff;
  border-radius: 28px 28px 0 0;
  padding: 10% 10%;
}

.boleto-panel-cards-mobile {
  width: 100%;
  display: inline-flex;
  gap: 16px;
}

.boleto-panel-card-mobile {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 120px;
  padding: 16px;
  background: #fff2de;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
}

.boleto-panel-card-mobile-disabled {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 120px;
  padding: 16px;
}

.boleto-panel-card-h1-mobile {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: var(--k4n-primary-color);
}

.boleto-panel-card-img-mobile {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.mg-top-16 {
  margin-top: 16px;
}

.boleto-panel-config-mobile {
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  margin: 10% 0;
}

.boleto-panel-config-h1-mobile {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: var(--k4n-primary-color);
}

.boleto-panel-config-table-mobile {
  display: inline-flex;
  align-items: center;
  padding: 24px 0;
  border-bottom: 1px solid rgba(10, 2, 3, 0.1);
}

.boleto-panel-config-table-align-mobile {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.mb-modal-ok-btn-mobile {
  width: 100%;
  height: 56px;
  font-family: typography.$font-bold;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 120%;
  color: var(--k4n-primary-color);
  border: none;
  background: var(--k4n-secondary-color);
  border-radius: 24px;
}

.mb-modal-ok-div-mobile {
  width: 100%;
  height: 56px;
  margin: 0 auto;
  margin-top: 30px;
}

.bm-time-info-mobile {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  margin: 0 auto;
}

.bm-time-info-div-mobile {
  width: 100%;
  text-align: center;
  margin-top: 10px;
}
