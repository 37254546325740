@use "../../../../../assets/scss/colors";
@use "../../../../../assets/scss/typography";

.pix-keys-container {
  width: 100%;
  display: flex;
  height: auto;
  position: relative;
  top: 56px;
  bottom: 56px;
  flex-direction: column;
}

.pix-keys {
  width: 100%;
  background: var(--k4n-secondary-light-color);
  border-radius: 16px;
  height: 77px;
  display: flex;
  align-items: center;
  padding: 0 48px;
}

.pix-keys-title {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  color: #737373;
  margin: 0;
}

.pix-keys-content {
  position: relative;
  margin-top: 15px;
  width: 100%;
  height: 687px;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  padding: 0 68px;
}

.pix-keys-content-title {
  // width: 163px;
  // height: 29px;
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  color: var(--k4n-primary-color);
  margin-top: 48px;
}

.pix-keys-href {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  text-decoration-line: underline;
  color: var(--k4n-secondary-color);
  cursor: pointer;
}

.spinner-pix-keys {
  color: var(--k4n-primary-color);
  margin-right: 10px;
  width: 22px;
  height: 22px;
  margin-left: 35px;
}

.spinner-pix-keys-delete {
  color: var(--k4n-primary-color);
  width: 25px;
  height: 25px;
}

.line-top {
  border-top: 1px solid #dadada;
  margin-top: 11px;
}

.mg-bottom-11 {
  margin-bottom: 11px;
}

.mg-left-35 {
  margin-left: 35px;
}

.pix-keys-space-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.loading-pix-keys {
  width: 100%;
}

.pix-keys-delete-modal {
  width: 600px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 5%;
}

.add-key-container {
  font-size: 30px;
  color: var(--k4n-secondary-color);
  cursor: pointer;
}
