@use "../../../assets/scss/colors";
@use "../../../assets/scss/typography";

body {
  color: #ffffff;
  font-family: typography.$font-main;
}

html {
  font-size: 20px;
}

.onboarding-mobile-content {
  background-color: var(--k4n-primary-color);
  min-height: 100vh;
  height: 100%;
  overflow-y: none;
}

.gray-bg {
  width: 100%;
  height: 100%;
  margin: 0;
  // background-image: url("../../../assets/images/gray-bg.png");
  background-color: var(--k4n-primary-light-color);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.corner-image-bg {
  width: 100%;
  height: 100%;
  margin: 0;
  background-position: bottom right;
  background-repeat: no-repeat;
  object-fit: cover;
}

.dark-overlay {
  width: 100%;
  height: 100%;
  margin: 0;
  // background-color: rgba($color: #000000, $alpha: 0.2);
}

.start-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: var(--k4n-secondary-color);
  font-family: typography.$font-main;
  max-width: 600px;
  flex: 1;
  margin-left: 60px;
}

.start-text-message {
  color: var(--k4n-primary-color);
}

.mt-60px {
  margin-top: 60px;
}

.ml-60px {
  margin-left: 60px;
}

.start-text-title {
  font-size: 3rem;
  line-height: 4rem;
  font-family: typography.$font-bold;
}

.start-button {
  background-color: var(--k4n-secondary-color);
  font-family: typography.$font-bold;
  color: var(--k4n-quaternary-color);
  padding: 20px 60px;
  border-radius: 25px;
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  white-space: nowrap;
  width: fit-content;
}

.start-button::after {
  content: " ";
  background-image: url("../../../assets/images/arrow.svg");
  background-size: 40px 20px;
  width: 40px;
  height: 20px;
  margin-left: 30px;
}

.loading.start-button::after {
  background-image: url("../../../assets/images/spinner-white.svg");
  background-position: center;
  background-size: 40px 40px;
  width: 40px;
  height: 20px;
}

.loading {
  cursor: wait !important;
}

.onboarding-go-back {
  outline: none;
  border: none;
  cursor: pointer;
  background-color: var(--k4n-secondary-color);
  mask-size: contain;
  mask-repeat: no-repeat;
  -webkit-mask-image: url("../../../assets/images/arrow.svg");
  mask-image: url("../../../assets/images/arrow.svg");
  transform: scaleX(-1);
  width: 32px;
  height: 16px;
}

.onboarding-go-back-white {
  outline: none;
  border: none;
  cursor: pointer;
  background-color: transparent;
  background-image: url("../../../assets/images/arrow.svg");
  background-size: 32px 16px;
  width: 32px;
  height: 16px;
  transform: scaleX(-1);
}

.onboarding-form-container {
  display: flex;
  flex-direction: column;
  padding-left: 60px;
  padding-top: 60px;
  padding-bottom: 10px;
  padding-right: 10px;
  color: #ffffff;
  font-family: typography.$font-main;
  max-width: 600px;
  overflow-x: hidden;
}

.onboarding-btn-next {
  background-color: var(--k4n-secondary-color);
  background-image: url("../../../assets/images/arrow.svg");
  background-size: 36px 20px;
  background-repeat: no-repeat;
  background-position: center;
  width: 81px;
  height: 60px;
  outline: none;
  border: none;
  cursor: pointer;
  border-radius: 25px;
}

.onboarding-btn-next.loading {
  background-image: url("../../../assets/images/spinner-white.svg");
  background-size: 50px 50px;
}

.onboarding-btn-primary {
  border: none;
  border-radius: 28px;
  outline: none;
  color: var(--k4n-secondary-color);
  font-family: typography.$font-bold;
  background-color: var(--k4n-primary-light-color);
  padding: 25px 55px;
}

.ml-3 {
  margin-left: 1rem;
}

.qr-code-container {
  max-width: 230px;
}

.onboarding-question-primary {
  font-size: 2rem;
  line-height: 2.2rem;
  color: var(--k4n-secondary-color);
  font-family: typography.$font-bold;
  margin: 20px 0;
}

.onboarding-input {
  color: #000000;
  font-family: typography.$font-main;
  background-color: var(--k4n-primary-light-color);
  margin-bottom: 28px;
  padding: 24px 32px;
  border-radius: 30px;
  outline: none;
  border: none;
  caret-color: var(--k4n-secondary-color);
}

.onboarding-input::placeholder {
  color: #9d9b9b;
  font-family: typography.$font-main;
}

.input-invalid {
  border: 1px solid #ff5050 !important;
  margin-bottom: 8px !important;
}

.input-error {
  color: #ff5050;
  margin-bottom: 20px;
}

.onboarding-btn-link {
  color: var(--k4n-secondary-color);
  margin-right: 5px;
  cursor: pointer;
  text-decoration: underline;
  background-color: transparent;
  border: none;
  outline: none;
  white-space: nowrap;
}

.onboarding-close-btn {
  outline: none;
  border: none;
  cursor: pointer;
  background-color: transparent;
  background-image: url("../../../assets/images/close.svg");
  background-size: 32px 16px;
  width: 32px;
  height: 16px;
}

#saffe-camera,
.bg-green {
  /* Overwite saffe components style */
  background: var(--k4n-primary-color) !important;
  background-color: var(--k4n-primary-color) !important;
}

.phone-verify-input {
  border: none;
  outline: none;
  color: var(--k4n-primary-color);
  caret-color: var(--k4n-primary-color);
  font-family: typography.$font-main;
  background-color: var(--k4n-primary-light-color);
  margin-right: 10px;
  padding: 15px;
  text-align: center;
  border-radius: 20px;
}

.onboarding-benefits-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.onboarding-benefit {
  background-color: var(--k4n-primary-light-color);
  width: 280px;
  margin-bottom: 30px;
  padding: 20px 30px;
  border-radius: 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.onboarding-checkbox {
  border: 1px solid var(--k4n-secondary-color);
  background-color: transparent;
  height: 20px;
  width: 20px;
  border-radius: 5px;
}

.onboarding-checked {
  background-color: var(--k4n-secondary-color);
  background-image: url("../../../assets/images/check.svg");
  background-size: 12px 12px;
  background-position: center;
  background-repeat: no-repeat;
}

.qr-code-image {
  width: 100%;
  margin-top: 20px;
  background-image: url("../../../assets/images/spinner-white.svg");
  background-position: center;
  background-size: 50px 50px;
  background-repeat: no-repeat;
  min-height: 50px;
}

.sms-text-finish {
  font-size: 1.75rem;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .start-text-title {
    font-size: 2rem;
    line-height: 2.3rem;
  }
  .onboarding-input {
    background-color: var(--k4n-primary-light-color);
  }
  .onboarding-form-container {
    padding: 60px 35px;
    height: 100%;
  }
  #saffe-camera,
  .bg-green {
    /* Overwite saffe components style */
    background: var(--k4n-primary-color) !important;
    background-color: var(--k4n-primary-color) !important;
  }
  .onboarding-btn-primary {
    background-color: var(--k4n-primary-light-color);
  }
  .phone-verify-input {
    background-color: var(--k4n-primary-light-color);
  }
  .start-text-container {
    margin: 0 35px;
  }
  .start-button {
    color: var(--k4n-primary-color);
    padding: 20px 45px;
    white-space: nowrap;
  }
}
