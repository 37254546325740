@use "../../../../../../assets/scss/typography";
@use "../../../../../../assets/scss/colors";

.modal-content-edit-approval {
  gap: 30px;
  display: flex;
  align-items: center;
  flex-direction: column;

  .modal-description-edit-approval {
    font-size: 14px;
    color: #616060;
  }

  .input-group-edit-approval {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;

    .edit-approval-min-value-box {
      width: 100%;
      height: 55px;
      border: 2px solid colors.$primary-white-background;
      background: transparent;
      border-radius: 16px;
      color: colors.$complementary-black;
      font-family: typography.$font-main;
      padding-left: 15px;
      padding-right: 15px;
      display: flex;
      align-items: center;
    }

    .edit-approval-min-value-box:focus-within {
      border-color: var(--k4n-secondary-color);
    }

    .edit-approval-min-value-label {
      color: black !important;
      border: none;
      font-family: typography.$font-main;
      font-weight: 700;
      font-size: 14px;
      line-height: 120%;
    }

    .edit-approval-min-value-input {
      color: black !important;
      border: none;
      width: 100%;
      font-family: typography.$font-main;
      font-weight: 700;
      font-size: 14px;
      line-height: 120%;
      background-color: transparent;
    }

    .edit-approval-min-value-input::placeholder {
      font-family: typography.$font-main;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 120%;
      color: #0a0203;
    }

    .approval-quantity-container {
      @media screen and (max-width: 768px) {
        margin-left: 0;
        margin-top: 20px;
      }

      .approval-quantity-select {
        width: 100%;
        height: 55px;
        border: 2px solid colors.$primary-white-background;
        background: transparent;
        border-radius: 16px;
        color: colors.$complementary-black;
        padding-left: 23px;
        padding-top: 5px;
        background-image: url(../../../../../../assets/images/arrow_yellow_down.svg);
        background-repeat: no-repeat;
        background-position: calc(100% - 14px) center;
        appearance: none;
        text-indent: 1px;
        cursor: pointer;

        option {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }

      .approval-quantity-select:focus-visible {
        outline: none;
      }

      .approval-quantity-select-value-txt {
        font-family: typography.$font-main;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        color: colors.$complementary-black;
      }
    }
  }

  .modal-buttons-edit-approval {
    display: flex;
    gap: 20px;
  }
}
