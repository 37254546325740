.pix-keys-img-mobile {
  max-width: 48px;
  width: 100%;
  height: 48px;
  background: #fff2de;
  border-radius: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
}

.pix-keys-modal-mobile {
  width: 100%;
  height: 360px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.width-height-64 {
  width: 100% !important;
  max-width: 64px !important;
  height: 64px !important;
  margin: 0 !important;
  margin-bottom: 24px !important;
}

.pix-modal-bin-copy-mobile {
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
  gap: 24px;
}

.font-18 {
  font-size: 18px !important;
}

.spinner-pix-keys-mobile {
  color: var(--k4n-primary-color);
  width: 30px;
  height: 30px;
}

.empty-pix-keys-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.mg-top-48 {
  margin-top: 48px;
}

.empty-pix-keys-card-mobile {
  margin-top: 90px;
  margin-left: -32px;
  margin-bottom: -25px;
}

.pix-keys-delete-mobile {
  width: 100%;
  margin-top: 32px;
}

.pix-keys-delete-modal-mobile {
  width: 100%;
  height: 260px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 22px 0;
}
