@use "../../../../../../assets/scss/colors";
@use "../../../../../../assets/scss/typography";

.pix-payment-step2-container {
  width: 100%;
  display: flex;
  height: auto;
  position: relative;
  top: 56px;
  bottom: 56px;
  flex-direction: column;
}

.pix-payment-step2-title {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 92%;
  color: var(--k4n-primary-color);
  margin: 0;
}

.pix-payment-step2-content {
  margin-top: 20px;
  width: 100%;
  height: auto;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  padding: 42px 68px;
}

.pix-payment-step2-content-title {
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #dadada;
  padding-bottom: 24px;
}

.pix-payment-step2-btn {
  width: 100%;
  display: inline-flex;
  padding: 68px 0;
  justify-content: flex-end;
  // border-top: 1px solid #DADADA;
}

.pix-payment-step2-input {
  width: 312px;
  height: 54px;
  border-radius: 30px;
  border: none;
  font-family: typography.$font-main;
  font-weight: 700;
  font-size: 20px;
  color: var(--k4n-primary-color);
  line-height: 120%;
  padding: 16px 40px 16px 24px;
  background: var(--k4n-secondary-light-color);
  background-image: url(../../../../../../assets/images/search_icon.svg);
  background-repeat: no-repeat;
  background-position: 95%;
}

.pix-payment-step2-input::placeholder {
  color: var(--k4n-primary-color);
  outline: none;
  border: none;
  border-radius: 30px;
}

textarea:focus,
.pix-payment-step2-input:focus {
  color: var(--k4n-primary-color);
  outline: none;
}

.pix-payment-step2-input:focus::placeholder {
  color: transparent;
}

.content-table-step2 {
  width: 100%;
  display: flex;
  border-bottom: 1px solid #dadada;
  flex-direction: column;
}

.content-table-step2-title {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  color: #0a0203;
  margin-bottom: 11px;
  margin-top: 24px;
}

.content-table-step2-name-txt {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  color: #737373;
  margin-bottom: 11px;
}

.content-table-step2-pix-txt {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  color: #737373;
  margin-bottom: 24px;
}

.form-check-step2 {
  margin-top: 24px;
  margin-bottom: 48px;
  display: inline-flex;
  align-items: center;
}

.pix-payment-step2-checkbox {
  width: 24px;
  height: 24px;
  border-color: var(--k4n-secondary-color);
  border-radius: 5px;
}

.pix-payment-step2-checkbox:checked {
  background-color: var(--k4n-secondary-color);
  border-color: var(--k4n-secondary-color);
}

.pix-payment-step2-checkbox:focus {
  border-color: var(--k4n-secondary-color);
  box-shadow: none;
}

.pix-payment-step2-checkbox-txt {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  color: #0a0203;
  margin-left: 8px;
}

.pix-payment-step2-value-input {
  width: 204px;
  height: 55px;
  border: 1px solid var(--k4n-secondary-color);
  border-radius: 16px;
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  color: #0a0203;
  padding: 0 13px;
}

.pix-payment-step2-value-input::placeholder {
  color: #0a0203;
  outline: none;
  border: none;
}

textarea:focus,
.pix-payment-step2-value-input:focus {
  color: #0a0203;
  outline: none;
  border-radius: 16px;
}

.pix-payment-step2-value {
  padding-top: 24px;
  display: inline-flex;
  align-items: center;
}

.pix-payment-step2-balance {
  display: flex;
  flex-direction: column;
  margin-left: 50px;
}

.content-table-step2-txt-red {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  text-decoration-line: underline;
  color: #cc0000;
  margin-bottom: 48px;
}

.pix-payment-step2-message-input {
  width: 320px;
  height: 55px;
  border: 1px solid var(--k4n-secondary-color);
  border-radius: 16px;
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 120%;
  color: #0a0203;
  padding: 0 13px;
  margin-bottom: 24px;
}

.pix-payment-step2-message-input::placeholder {
  color: #0a0203;
  outline: none;
  border-radius: 16px;
}

.pix-payment-step2-message-input::-webkit-input-placeholder {
  color: #0a0203;
  outline: none;
  border-radius: 16px;
}

.pix-payment-step2-message-input::-moz-placeholder {
  color: #0a0203;
  outline: none;
  border-radius: 16px;
}

.pix-payment-step2-message-input:-ms-input-placeholder {
  color: #0a0203;
  outline: none;
  border-radius: 16px;
}

.pix-payment-step2-message-input:-moz-placeholder {
  color: #0a0203;
  outline: none;
  border-radius: 16px;
}

textarea:focus,
.pix-payment-step2-message-input:focus {
  color: #0a0203;
  outline: none;
  border-radius: 16px;
}

.content-table-step2-title-18 {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 120%;
  color: #0a0203;
  margin-right: 67px;
}

.pix-payment-step2-date {
  display: flex;
  align-items: center;
  margin-top: 34px;
  margin-bottom: 9px;
}

.pix-payment-step2-date-input {
  width: 52px !important;
  height: 25px !important;
}

.pix-payment-step2-date-input:checked {
  background-color: var(--k4n-secondary-color);
  border-color: var(--k4n-secondary-color);
}

.mg-bottom-24 {
  margin-bottom: 24px;
}

.padding-bottom-15 {
  padding-bottom: 15px;
}

.margin-0 {
  margin: 0 !important;
}

.pix-payment-step2-input-calender {
  background-image: url(../../../../../../assets/images/calendar.svg);
  background-repeat: no-repeat;
  background-position: 95%;
}

.pix-payment-step2-input-calender::placeholder {
  color: #0a0203;
  outline: none;
  border-radius: 16px;
}

.pix-payment-step2-input-calender::-webkit-input-placeholder {
  color: #0a0203;
  outline: none;
  border-radius: 16px;
}

.pix-payment-step2-input-calender::-moz-placeholder {
  color: #0a0203;
  outline: none;
  border-radius: 16px;
}

.pix-payment-step2-input-calender:-ms-input-placeholder {
  color: #0a0203;
  outline: none;
  border-radius: 16px;
}

.pix-payment-step2-input-calender:-moz-placeholder {
  color: #0a0203;
  outline: none;
  border-radius: 16px;
}

textarea:focus,
.pix-payment-step2-input-calender:focus {
  color: #0a0203;
  outline: none;
  border-radius: 16px;
}

.content-table-step2-copy-paste {
  padding-right: 15%;
  border-bottom: 1px solid #dadada;
  margin-bottom: 24px;
}

.table-step2-copy-paste {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #dadada;
}

.flex-direction-column {
  display: flex;
  flex-direction: column;
  padding-right: 141px;
}

.inline-flex {
  display: inline-flex;
}

.mg-top-24 {
  margin-top: 24px !important;
}

.mg-bottom-11 {
  margin-top: 11px;
}
