@use "../../../../../assets/scss/colors";
@use "../../../../../assets/scss/typography";

.pix-receive-container {
  width: 100%;
  display: flex;
  height: auto;
  position: relative;
  top: 56px;
  bottom: 56px;
  flex-direction: column;
}

.pix-receive-title {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 92%;
  color: var(--k4n-primary-color);
  margin: 0;
  margin-bottom: 24px;
}

.pix-receive-content {
  margin-top: 20px;
  width: 100%;
  height: auto;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  padding: 42px 68px;
}

.pix-receive-content-table {
  width: 100%;
  display: flex;
  border-bottom: 1px solid #dadada;
  flex-direction: column;
  padding: 24px 0;
}

.pix-receive-txt {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  color: #0a0203;
  margin: 0;
}

.pix-receive-keys {
  width: auto;
  min-width: 206px;
  height: 55px;
  border: 1px solid var(--k4n-secondary-color);
  border-radius: 16px;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  margin-right: 16px;
  padding-left: 16px;
  cursor: pointer;
}

.pix-receive-key-btn {
  min-width: 20px;
  height: 20px;
  border-radius: 100%;
  margin-right: 16px;
  border: 1px solid var(--k4n-secondary-color);
}

.pix-receive-key-btn-selected {
  background: var(--k4n-secondary-color);
}

.pix-receive-keys-h1 {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  color: #0a0203;
  margin: 0;
}

.pix-receive-keys-txt {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 120%;
  color: #0a0203;
  margin: 0;
}

.margin-0 {
  margin: 0;
}

.pix-receive-content-keys {
  width: 100%;
  display: inline-flex;
  border-bottom: 1px solid #dadada;
  padding: 24px 0;
}

.pix-receive-modal-sucess-h1 {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 125%;
  color: var(--k4n-secondary-color);
  margin-top: 30px;
  text-align: center;
}

.pix-receive-modal-sucess-txt {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 133%;
  color: #ffffff;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 24px;
}

.pix-receive-txts {
  width: 119px;
  white-space: nowrap;
  overflow: hidden;
}

.pix-receive-modal-img {
  width: 180px;
  height: 180px;
}

.pix-receive-align-center {
  display: flex;
  justify-content: center;
}

.pix-receive-modal-btn-img {
  width: 30px;
  height: 30px;
  margin-right: 39px;
}
