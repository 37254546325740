@use "../../../../../assets/scss/colors";
@use "../../../../../assets/scss/typography";

.insurance-container {
  width: 100%;
  display: flex;
  height: auto;
}

.insurance-header {
  width: 100%;
  height: 77px;
  background: var(--k4n-secondary-light-color);
  padding-left: 32px;
  padding-right: 32px;
  border-radius: 16px;
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.insurance-content-mobile {
  width: 100%;
  padding: 32px;
  border-radius: 16px;
}

.insurance-title {
  font-family: typography.$font-bold;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: var(--k4n-primary-light-color);
  display: inline-block;
}

.insurance-title-mobile {
  font-family: typography.$font-bold;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  color: var(--k4n-secondary-color);
  display: inline-block;
}

.insurance-subtitle-mobile {
  font-size: 20px;
  font-family: typography.$font-main;
}

.insurance-wrapper {
  width: 100%;
  margin: 0;
  display: inline-block;
  padding-left: 75px;
  padding-right: 75px;
  margin-bottom: 25px;
}

.insurance-input {
  max-width: 570px;
  width: 92%;
  height: 69px;
  border: 2px solid var(--k4n-secondary-color);
  background: transparent;
  border-radius: 16px;
  color: colors.$complementary-black;
  padding-left: 23px;
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
}

.insurance-input:focus {
  color: colors.$complementary-black;
  border-radius: 16px;

  label {
    display: block !important;
  }
}

.insurance-input:focus + label {
  label {
    display: block !important;
  }
}

.insurance-input::-webkit-input-placeholder {
  /* Edge */
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #737373;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.insurance-input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #737373;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.insurance-input::placeholder {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #737373;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.insurance-input-label {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 18px;
  color: #737373;
  position: absolute;
  padding-left: 26px;
  padding-top: 3px;
  display: none;
}

.show-input {
  display: block !important;
}

.insurance-next {
  padding: 0;
  width: 100%;
}

.insurance-back {
  padding: 0;
  width: 100%;
  padding-bottom: 10px;
}

.insurance-next-btn {
  width: 95px;
  height: 70px;
  background: var(--k4n-secondary-color);
  border-radius: 28px;
  border: none;
  float: right;
}

.insurance-next-btn:hover {
  opacity: 0.9;
  border: none;
  /* Green */
}

.insurance-next-arrow {
  width: 42, 22px;
  height: 23, 07px;
}

.insurance-checkbox {
  border: 1px solid var(--k4n-secondary-color);
  background-color: #ffffff;
  height: 24px;
  width: 24px;
  border-radius: 5px !important;
  vertical-align: middle;
}

.insurance-checked {
  background-color: var(--k4n-secondary-color);
  background-image: url("../../../../../assets/images/check.svg");
  background-size: 12px 12px;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 5px;
}

.insurance-error-msg {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: #ed6465;
  text-align: left;
  padding-top: 5px;
  padding-left: 30px;
}

.insurance-disabled-bg {
  background-color: #d4d4d438;
}

.spinner-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.spinner-container-mobile {
  width: 100%;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner-proposal {
  width: 50px;
  height: 50px;
  color: var(--k4n-secondary-color);
}

@media screen and (max-width: 768px) {
  .insurance-back {
    margin-top: 10px;
    max-width: 100px;
  }
  .insurance-next-btn {
    height: 60px;
  }

  .insurance-next {
    display: flex;
    box-sizing: content-box;
    justify-content: space-around;
  }
  .insurance-next-step {
    display: flex;
    box-sizing: content-box;
    justify-content: space-around;
  }
  .insurance-completed {
    display: flex;
    box-sizing: content-box;
    justify-content: right;
    margin: 20px;
  }

  .insurance-input {
    font-size: 12px;
    padding-left: 10px;
  }
}
