@use "../../../assets/scss/colors";
@use "../../../assets/scss/typography";

@font-face {
  font-family: "Gruppo";
  src: url("../../../assets/fonts/gruppo/Gruppo-Regular.ttf");
}

body {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
}

body::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.home {
  width: 100%;
  min-height: 100vh;
  height: 100%;
}

.bg-image {
  height: 100%;
  background-size: contain;
  background-clip: border-box;
  background-repeat: no-repeat;
  background-position: top center;
  background-color: #fff;
}

.home-content {
  width: 100%;
  height: auto;
  position: relative;
  padding-top: 7%;
  padding-left: 10%;
}

.home-h1 {
  font-family: Gruppo;
  font-size: 42px;
  color: var(--k4n-primary-color);
  width: auto;
  height: auto;
  line-height: 120%;
}

.home-h1 strong {
  color: var(--k4n-secondary-color);
}

.home-form {
  width: 100%;
  display: inline-flex;
  margin-top: 71px;
  margin-bottom: 60px;
}

.home-input {
  width: 312px;
  height: 70px;
  border-radius: 28px;
  // border: solid var(--k4n-primary-color);
  border: none;
  background-color: var(--k4n-secondary-color);
  color: var(--k4n-primary-light-color);
  padding-left: 28px;
  font-family: typography.$font-bold;
  line-height: 120%;
  font-size: 20px;
  margin-right: 16px;
}

.home-input::placeholder {
  color: var(--k4n-primary-light-color);
}

textarea:focus,
input:focus {
  outline: none;
}

.home-btn-next {
  width: 312px;
  height: 70px;
  background: var(--k4n-secondary-color);
  border-radius: 28px;
  border: none;
  text-align: left;
  font-family: typography.$font-bold;
  line-height: 120%;
  font-size: 20px;
  color: var(--k4n-primary-light-color);
  padding-left: 21px;
  padding-right: 61px;
}

.home-btn-next:hover {
  opacity: 0.9;
}

.home-btn-arrow {
  width: 42.22px;
  height: 23.07px;
  float: right;
}

.home-href {
  margin-top: 71px;
}

.home-password-txt {
  font-family: typography.$font-bold;
  line-height: 120%;
  font-size: 16px;
  color: #ffffff;
  margin-right: 18px;
  margin-left: 10px;
}

.home-password-href {
  font-family: typography.$font-bold;
  line-height: 120%;
  font-size: 16px;
  color: var(--k4n-primary-color);
}

.home-password-href:hover {
  color: var(--k4n-secondary-color);
}

.home-error-msg {
  padding-top: 5px;
  padding-left: 28px;
  margin-bottom: -24px;
  font-size: 16px;
  line-height: 120%;
  color: #e34848;
}

// MOBILE

.home-mobile {
  width: 100%;
  height: 100%;
  margin: 0;
  background-color: var(--k4n-primary-color);
  padding: 15% 10%;
}

.home-h1-mobile {
  font-family: typography.$font-bold;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 120%;
  color: var(--k4n-secondary-color);
  margin-top: 50px;
}

.home-form-mobile {
  text-align: center;
}

.home-input-mobile {
  width: 100%;
  max-width: 312px;
  height: 70px;
  background: var(--k4n-primary-light-color);
  border-radius: 28px;
  border: none;
  padding-left: 28px;
  margin-top: 48px;
  margin-bottom: 55%;
}

.home-input-mobile {
  color: var(--k4n-secondary-light-color);
}

.home-input-mobile::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: var(--k4n-secondary-light-color);
}

.home-input-mobile::-moz-placeholder {
  /* Firefox 19+ */
  color: var(--k4n-secondary-light-color);
}

.home-input-mobile:-ms-input-placeholder {
  /* IE 10+ */
  color: var(--k4n-secondary-light-color);
}

.home-input-mobile:-moz-placeholder {
  /* Firefox 18- */
  color: var(--k4n-secondary-light-color);
}

.home-btn-txt-mobile {
  display: flex;
  padding: 0 10%;
  justify-content: space-between;
}

.home-error-msg-mobile {
  text-align: initial;
  padding-top: 10px;
  padding-left: 28px;
  margin-top: -55%;
  margin-bottom: 55%;
  font-size: 16px;
  line-height: 120%;
  color: #e34848;
}
