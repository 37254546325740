@use "../../../../../assets/scss/colors";
@use "../../../../../assets/scss/typography";

.pix-panel-container {
  width: 100%;
  display: flex;
  height: auto;
  position: relative;
  top: 56px;
  bottom: 56px;
  flex-direction: column;
}

.pix-panel {
  width: 100%;
  background: var(--k4n-secondary-light-color);
  border-radius: 16px;
  height: 77px;
  display: flex;
  align-items: center;
  padding: 0 48px;
}

.pix-panel-title {
  font-family: typography.$font-bold;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  color: var(--k4n-quaternary-color);
  margin: 0;
  padding-left: 15px;
}

.pix-panel-content {
  position: relative;
  margin-top: 27px;
  width: 100%;
  height: 687px;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  padding: 0 68px;
}

.pix-panel-content-title {
  width: 153px;
  height: 29px;
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  color: var(--k4n-primary-color);
  margin-top: 48px;
  white-space: nowrap;
}

.pix-panel-content-table {
  width: 100%;
  height: 67px;
  display: flex;
  border-bottom: 1px solid #dadada;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.pix-panel-content-txt {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  color: #0a0203;
}

.pix-content-img {
  width: 22.74px;
  height: 24.81px;
  margin-right: 10px;
}

.pix-tabel-txt {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 129%;
  color: #737373;
}
