@use "../../../../../assets/scss/colors";
@use "../../../../../assets/scss/typography";

.extrato-container-mobile {
  background-color: var(--k4n-primary-color);
  width: 100%;
  height: 100%;
}

.extrato-header-mobile {
  padding: 10% 5% 3%;
}

.extrato-header-mobile-btn {
  background-color: transparent;
  border: transparent;
  float: right;
  height: 15.02px;
}

.f-l {
  float: left;
}

.extrato-panel-img-mobile {
  width: 27.48px;
  height: 15.02px;
  outline: none;
  border: none;
  cursor: pointer;
  background-color: var(--k4n-secondary-color);
  mask-size: contain;
  mask-repeat: no-repeat;
  -webkit-mask-image: url("../../../../../assets/images/arrow_yellow.svg");
  mask-image: url("../../../../../assets/images/arrow_yellow.svg");
}

.extrato-header-h1-mobile {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: var(--k4n-secondary-color);
  margin-top: 80px;
}

.extrato-table-content-mobile {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  border-top-left-radius: 26px;
  border-top-right-radius: 26px;
  padding: 8% 5% 0% 5%;
}

.extrato-options-btns-mobile-div {
  width: 100%;
  display: inline-flex;
  flex-direction: row;
  gap: 10px;
  overflow-y: hidden;
  margin: 10px 0;
  height: 60px;
  background: white;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
  align-items: center;
  padding: 0 10px;
}

.extrato-option-btn-mobile {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  color: var(--k4n-quaternary-color);
  background-color: var(--k4n-secondary-color);
  box-shadow: 0px 4px 12px rgb(0 0 0 / 8%);
  border-radius: 12px;
  border-width: 0;
  padding: 11.5px 16px;
}

.extrato-option-btn-mobile-disable {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  color: #737373;
  background-color: #ffffff;
  box-shadow: 0px 4px 12px rgb(0 0 0 / 8%);
  border-radius: 12px;
  border-width: 0;
  padding: 11.5px 16px;
}

.extrato-options-btns-mobile-div::-webkit-scrollbar {
  display: none;
}

.extrato-download-mobile-div {
  float: right;
  width: 100%;
}

.extrato-info-mobile-div {
  height: 100%;
  margin-top: 20px;
}

.extrato-info-mobile-content {
  width: 100%;
  display: flex;
  border-bottom: 1px solid #dadada;
  justify-content: space-between;
  gap: 16px;
  padding: 10px 0px;
}

.display-inline-grid {
  display: inline-grid;
}

.extrato-info-mobile-row-content {
  display: flex;
  flex-direction: column;
  // gap: 4px;
  // min-width: 50%;
}

.extrato-info-mobile-row-span-16 {
  font-family: typography.$font-bold;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  color: #0a0203;
}

.extrato-info-mobile-row-span-14 {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: #737373;
}

.extrato-info-mobile-row-span-14-red {
  font-family: typography.$font-bold;
  font-weight: 700;
  font-size: 14px;
  line-height: 120%;
  color: #ff0000;
}

.dropdown-statements-menu-mobile {
  top: 30px;
  width: 100%;
  height: auto;
  position: absolute;
  background-color: #ffffff;
  border-radius: 26px;
  padding: 5% 5%;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.statement-mobile-title-h1 {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: var(--k4n-primary-light-color);
}

.dropdown-statements-menu-mobile-close {
  width: 34px;
  display: flex;
  height: 34px;
  align-items: center;
  justify-content: flex-start;
  background: transparent;
  border: transparent;
}

.statement-mobile-text-22 {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 120%;
  color: #0a0203;
}

.dropdown-statements-menu-btns-div {
  display: flex;
  gap: 16px 8px;
  flex-wrap: wrap;
  width: 70%;
}

.dropdown-calendar-mobile-div {
  width: 90%;
  height: 60px;
  border-radius: 12px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  padding-left: 20px;
  box-shadow: 0px 4px 12px rgb(0 0 0 / 8%);
}

.dropdown-apply-btn-mobile-div {
  display: flex;
  justify-content: center;
}

.w-80 {
  width: 80%;
}

.grey-box-mobile {
  width: 100%;
  height: 20px;
}
