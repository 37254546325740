.form-header {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .title {
    font-size: 1.25rem;
    color: #000;
    margin: 0;
    border: none;
    text-decoration: none;

    @media (min-width: 768px) {
      font-size: 1.5rem;
    }
  }
}

.header-divider {
  width: 100%;
  height: 1px;
  background-color: #ddd;
  margin-bottom: 1.5rem;
}

.profile-selection {
  .radio-group {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 1.5rem;

    @media (min-width: 768px) {
      flex-direction: row;
      gap: 2rem;
    }

    .radio-label {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      color: #000;
      cursor: pointer;
      font-size: 0.875rem;

      input[type="radio"] {
        width: 16px;
        height: 16px;
        cursor: pointer;
      }

      span {
        color: #000;
      }
    }
  }

  .profile-dropdown {
    margin-bottom: 2rem;

    select {
      width: 100%;
      padding: 0.75rem;
      border: 1px solid #ddd;
      border-radius: 4px;
      color: #000;
      background-color: white;
      cursor: pointer;
      font-size: 0.875rem;

      &:focus {
        outline: none;
        border-color: #666;
      }
    }
  }

  .permissions-section {
    .subtitle {
      color: #000;
      font-size: 1rem;
      margin-bottom: 1rem;
    }

    .permission-item {
      border-bottom: 1px solid #ddd;

      .permission-toggle {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        padding: 1rem 0;
        color: #000;
        background: none;
        border: none;
        cursor: pointer;
        text-align: left;
        font-size: 0.875rem;

        .icon {
          width: 20px;
          height: 20px;
          transition: transform 0.3s ease;
          color: #000;

          &.open {
            transform: rotate(180deg);
          }
        }

        span {
          color: #000;
        }
      }

      .permission-content {
        padding: 0.5rem 0 1rem 1.5rem;

        .permission-grid {
          display: grid;
          grid-template-columns: 1fr;
          gap: 1rem;

          @media (min-width: 768px) {
            grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
            gap: 2rem;
          }

          .permission-category {
            .category-name {
              font-weight: 600;
              color: #000;
              margin-bottom: 0.5rem;
            }

            .permission-row {
              padding: 0.5rem 0;
              color: #666;
              font-size: 0.875rem;
            }
          }
        }
      }
    }
  }
}

.title {
  font-size: 1.25rem;
  margin-bottom: 1.5rem;
  color: #000;

  @media (min-width: 768px) {
    font-size: 1.5rem;
  }
}

.description {
  color: #000;
  font-size: 0.875rem;
  margin-bottom: 1.5rem;
  line-height: 1.5;
}
