@use "../../../../../../../assets/scss/colors";
@use "../../../../../../../assets/scss/typography";

.transfer-mobile-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: var(--k4n-primary-color);
}

.transfer-mobile-new-contact-panel {
  margin-top: 20px;
  width: 100%;
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
  border-radius: 28px 28px 0 0;
  padding: 10% 10%;
}

.transfer-mobile-new-contact-title {
  width: 100%;
  height: 50px;
  border-bottom: 1px #dadada solid;
  display: flex;
  align-items: center;
}

.title-mobile-blue {
  font-family: typography.$font-bold;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 22px;
  color: var(--k4n-primary-light-color);
}

.transfer-mobile-new-contact-form-div {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
}

.checkbox-mobile-transfer-new-contact-div {
  display: flex;
  gap: 10px;
  width: 100%;
  height: 20px;
  align-items: center;
  margin: 10px 0px;
  background: transparent;
}

.checkbox-mobile-transfer-new-contact {
  width: 24px !important;
  height: 24px !important;
  border-color: var(--k4n-secondary-color) !important;
  border-radius: 5px !important;
}

.checkbox-mobile-transfer-new-contact:checked {
  background-color: var(--k4n-secondary-color);
  border-color: var(--k4n-secondary-color);
}

.checkbox-mobile-transfer-new-contact:focus {
  border-color: var(--k4n-secondary-color);
  box-shadow: none;
}

.checkbox-mobile-transfer-new-contact-span {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #0a0203;
}

.transfer-mobile-new-contact-box-div {
  display: flex;
  max-width: 280px;
  width: 100%;
  align-items: center;
  gap: 10px;
  margin: 5px 0px;
  flex-direction: column;
}

.transfer-mobile-new-contact-form-field {
  max-width: 280px;
  width: 100%;
  border: 1px solid transparent;
  background: var(--k4n-secondary-light-color);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  padding: 11px 20px;
}

.transfer-mobile-conta-digito-wrapper {
  width: 100%;
  display: flex;
  gap: 5px;
}

.transfer-mobile-new-contact-form-field-conta {
  max-width: 280px;
  width: 70%;
  border: 1px solid transparent;
  background: var(--k4n-secondary-light-color);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  padding: 11px 20px;
}

.transfer-mobile-new-contact-form-field-digit {
  max-width: 280px;
  width: 30%;
  border: 1px solid transparent;
  background: var(--k4n-secondary-light-color);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  padding: 11px 20px;
}

.bank-name {
  width: 100%;
}

.account-type {
  min-width: 220px;
}

.agency {
  min-width: 95px;
}

.account {
  min-width: 150px;
}

.account-digit {
  width: 157px !important;
  min-width: 95px;
}

.transfer-mobile-new-contact-form-span {
  font-family: typography.$font-bold;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 120%;
  color: #737373;
}

.transfer-mobile-new-contact-form-input {
  height: 20px;
  background: transparent;
  border: transparent;
  color: #0a0203 !important;
  font-size: 16px;
}

.transfer-mobile-new-contact-form-input::placeholder {
  color: #c4c4c4;
  font-size: 16px;
}

.select-mobile-transfer-new-contact {
  font-family: typography.$font-bold;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  color: #0a0203 !important;
  width: 100%;
  border-color: transparent;
  background-color: transparent;
  background-image: url(../../../../../../../assets/images/arrow_yellow_down.svg);
  background-repeat: no-repeat;
  background-position: 100%;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.transfer-mobile-new-contact-name-box {
  border: 1px solid var(--k4n-secondary-color);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  padding: 11px 20px;
}

.transfer-mobile-new-contact-btns-div {
  height: 100px;
  margin: 10px 0px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 20px;
}

input::-webkit-calendar-picker-indicator,
input::-webkit-calendar-picker-indicator:hover,
input::-webkit-calendar-picker-indicator:focus {
  display: none !important;
}

.transfer-mobile-new-contact-holder-checkbox {
  width: 24px;
  height: 24px;
}

.transfer-mobile-new-contact-holder-checkbox:checked {
  background-color: var(--k4n-secondary-color);
  border-color: var(--k4n-secondary-color);
}

.transfer-mobile-new-contact-holder-checkbox:focus {
  border-color: var(--k4n-secondary-color);
  box-shadow: none;
}

.transfer-mobile-new-contact-info-name {
  font-family: typography.$font-main;
  color: colors.$complementary-black;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
}

.transfer-mobile-new-contact-info-cpf {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  color: #737373;
}

.transfer-mobile-new-contact-holder-info {
  font-family: "Satoshi";
}

.new-contact-mobile-transfer-error-msg {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: #ed6465;
  text-align: left;
}
