@use "../../../../../assets/scss/colors";
@use "../../../../../assets/scss/typography";

.transfer-container {
  margin-top: 56px;
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.panel-content {
  margin-top: 20px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  padding: 16px 24px;
}
.panel-title {
  width: 100%;
  height: 80px;
  border-bottom: 1px #dadada solid;
  display: flex;
  align-items: center;
}
.title-blue {
  font-family: typography.$font-bold;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 22px;
  color: var(--k4n-secondary-color);
}
.panel-title-step-2 {
  width: 100%;
  height: 80px;
  border-bottom: 1px #dadada solid;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
}
.steps-value-info {
  width: 30%;
  display: flex;
  flex-direction: column;
}

.info-span-grey-24 {
  font-family: typography.$font-main;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  color: #737373;
}
.new-contact-btn-div {
  display: flex;
  height: 150px;
  align-items: flex-end;
  padding-bottom: 30px;
  width: 100%;
  justify-content: flex-end;
}
.p-16 {
  font-size: 16px;
}

.transfer-arrow-margin {
  margin: 5px 5px 0 10px;
}

.flex-space-evenly {
  display: flex;
  justify-content: space-evenly;
  padding-left: 10px;
}
.contact-nav {
  height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
}
.contact-options-div {
  display: flex;
  height: 70px;
  width: 100%;
  align-items: center;
}
.all-contacts-btn {
  height: 50px;
  background-color: transparent;
  border-color: transparent;
  font: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 120%;
  text-align: center;
  color: var(--k4n-primary-color);
  border-bottom: 2px solid var(--k4n-secondary-color);
}
.search-contact-div {
  display: flex;
  width: 480px;
  height: 60px;
  border: 2px solid var(--k4n-secondary-color);
  border-radius: 28px;
  align-items: center;
  gap: 5px;
}
.search-contact-input {
  border: transparent;
  background: transparent;
  color: black !important;
  padding-left: 20px;
  width: 85%;
  font-size: 20px;
}
.search-contact-input::placeholder {
  color: var(--k4n-secondary-color);
  font: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 120%;
}
.transfer-step-2-btns-div {
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
