@use "../../../assets/scss/colors";
@use "../../../assets/scss/typography";

.navbar-home-kikkin {
  width: 100%;
  height: 68px;
  padding-left: 75px;
  padding-right: 75px;
  background-color: var(--k4n-secondary-color);
}

.navbar-banking-kikkin {
  width: 100%;
  height: 167px;
  padding-left: 75px;
  padding-right: 75px;
  background-color: var(--k4n-primary-color);
  display: grid;
}

.navbar-login-kikkin {
  width: 100%;
  height: 66px;
  padding-left: 75px;
  padding-right: 75px;
  background-color: var(--k4n-secondary-color);
}

.vertical-center {
  margin: 0;
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.dp-flex {
  display: flex;
}

.fl-right {
  float: right;
  margin: 15px;
}

.fl-left {
  float: left;
}

.mr-46 {
  margin-right: 46px;
}

.border-left-yellow {
  border-left: 1px solid var(--k4n-secondary-color);
  padding-left: 50px;
}

.navbar-login-txt {
  font-family: typography.$font-bold;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
}

.btn-open-account {
  background: var(--k4n-secondary-color);
  border: 1px solid var(--k4n-secondary-color);
  border-radius: 16px;
  width: 177px;
  height: 44px;
  box-sizing: border-box;
  margin-right: 50px;
}

.btn-open-account-login {
  border: 1px solid #ffffff !important;
  margin-right: 0px;
  border-radius: 16px;
  width: 177px;
  height: 44px;
  box-sizing: border-box;
  background: var(--k4n-secondary-color);
}

.btn-open-account:hover {
  opacity: 0.9;
  cursor: pointer;
}

.txt-open-account {
  font-family: typography.$font-bold;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
  align-items: center;
  color: var(--k4n-primary-color);
}

.txt-login-cpf {
  font-family: typography.$font-bold;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  text-align: right;
  color: var(--k4n-primary-color);
}

.txt-login-date {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  text-align: right;
  color: var(--k4n-primary-color);
}

.login-data {
  display: grid;
}

.navbar-first-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
}

.span-navbar-user {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  color: #ffffff;
  margin-right: 10px;
  vertical-align: middle;
}

.span-navbar-leave {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  color: #ffffff;
  margin-left: 30px;
  vertical-align: middle;
  cursor: pointer;
}

.mr-32 {
  margin-right: 32px;
}

.navbar-division {
  width: 1px;
  border-right: 1px solid var(--k4n-secondary-color);
  align-self: stretch;
}

.switcher-wrapper-mobile {
  width: 100%;
  height: 96px;
  display: inline-flex;
  margin-top: 30.86px;
  white-space: nowrap;
  overflow: auto;
}

.navbar-info-span {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #ffffff;
}

.navbar-value-span {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  color: #ffffff;
}

.navbar-balance {
  display: grid;
}

.spinner-header {
  width: 22px;
  height: 22px;
}

.navbar-second-section {
  height: 96px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.navbar-eye {
  cursor: pointer;
  position: relative;
  top: 7px;
}

.navbar-spinner {
  margin: 0 auto;
  display: block;
  width: 1rem;
  height: 1rem;
  border: 0.15em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
  color: #fff;
}

.timer-container {
  border: 1px;
  border-style: solid;
  border-color: #fff;
  display: block;
  text-align: center;
  border-radius: 10px;
  line-height: 35px;
  width: 90px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.ml-auto {
  margin-left: auto;
}

@media screen and (max-width: 768px) {
  .navbar-login-kikkin {
    padding-left: 20px;
    padding-right: 20px;
  }
}
