@use "../../../../assets/scss/colors";
@use "../../../../assets/scss/typography";

.transfer-wrapper {
  background-color: transparent;
  width: 100%;
  margin: 0;
  display: inline-block;
  padding-left: 75px;
  padding-right: 75px;
  margin-bottom: 25px;
}
