@use "../../../../../assets/scss/colors";
@use "../../../../../assets/scss/typography";

.pix-qr-code-modal {
  width: 100%;
  min-width: 657px;
  height: 100%;
  min-height: 576px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pix-qr-code-h1 {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 125%;
  color: var(--k4n-secondary-color);
  margin-bottom: 40px;
  text-align: center;
}

.pix-qr-code-error-message {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: #e34848;
  text-align: center;
  margin-top: -30px;
}

.pix-qr-code-spinner {
  color: var(--k4n-secondary-color);
  width: 70px;
  height: 70px;
}

.pix-qr-code-align-center {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pix-qr-code-mobile {
  width: 100%;
  height: 100vh;
  background-color: var(--k4n-primary-color);
  padding-top: 10%;
}

.pix-qr-code-h1-mobile {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 125%;
  color: var(--k4n-secondary-color);
  padding-right: 20px;
  padding-left: 20px;
  margin-top: 40px;
  text-align: center;
}

.pix-qr-code-img-close {
  padding-left: 10%;
}

.pix-qr-code-reader-mobile {
  height: 85%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
