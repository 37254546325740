@use "../../../assets/scss/typography";

.primary-header-logo {
  max-width: 140px;
  height: 50px;
}

.primary-header {
  background-color: var(--k4n-primary-color);
  padding: 30px 75px;
}

.flex-space-between {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.primary-navbar-division-green {
  width: 1px;
  border-right: 1px solid var(--k4n-primary-color);
  height: 2rem;
  margin: 0 30px;
}

.secondary-navbar-division-green {
  width: 1px;
  border-right: 1px solid var(--k4n-secondary-color);
  height: 2rem;
  margin: 0 30px;
}

.primary-header-link {
  font-family: typography.$font-main;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}

.secondary-header-link {
  font-family: typography.$font-main;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}

.primary-header-link:hover {
  color: var(--k4n-primary-color);
  text-decoration: underline;
}

.secondary-header-link:hover {
  color: var(--k4n-secondary-color);
  text-decoration: underline;
}

@media screen and (max-width: 768px) {
  .primary-header-logo {
    max-width: 90px;
  }
  .primary-header {
    padding: 25px 10%;
  }
}
