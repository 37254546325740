@use "../../../../assets/scss/typography";

.navbar-banking-mobile {
  padding: 25px 10% 80px;
  background-color: var(--k4n-primary-color);
}

.mobile-menu-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba($color: #000000, $alpha: 0.2);
  z-index: 15;
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

.mobile-menu-close-area {
  flex: 1;
}

.mobile-menu-container {
  background-color: var(--k4n-primary-color);
  padding: 10px 20px;
  transition: transform 0.2s ease;
  transform: translateX(0);
}

.closed {
  transform: translateX(100%);
}

.mobile-menu-item {
  display: block;
  text-decoration: none;
  color: white;
  border-bottom: 1px solid #e1e1e1;
  font-size: 1.1rem;
  padding: 5px 50px 5px 10px;
  margin-bottom: 10px;
}

.navbar-banking-avatar {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.font-bold {
  font-family: typography.$font-bold;
}

.navbar-banking-mobile-icon-button {
  margin-left: 15px;
}

.navbar-mobile-value-span {
  color: var(--k4n-secondary-color);
  font-size: 2rem;
  font-family: typography.$font-bold;
}

.yellow-arrow-button-right {
  width: 27px;
  height: 27px;
  cursor: pointer;
  color: var(--k4n-secondary-color);
  background-color: var(--k4n-primary-color);
  border: none;
  outline: none;
}

.flip-icon {
  transform: scaleX(-1);
}
