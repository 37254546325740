@use "../../../../../assets/scss/colors";
@use "../../../../../assets/scss/typography";

.cancel-insurance-container {
  display: block;
  height: auto;
  background-color: transparent;
  justify-content: center;
}

.cancel-insurance-header {
  width: 100%;
  height: auto;
  background: var(--k4n-secondary-light-color);
  padding-left: 32px;
  padding-top: 25px;
  padding-bottom: 15px;
  border-radius: 16px;
  margin-bottom: 12px;
}

.cancel-insurance-content {
  display: block;
  height: fit-content;
  margin: 50px;
  background: #ffffff;
  border-radius: 16px;
}

.insurance-title {
  font-family: typography.$font-bold;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 22px;
  color: var(--k4n-primary-light-color);
  display: inline-block;
}
.input-container {
  padding: 0.5em;
}

.input-info-container {
  display: flex;
}

.input {
  width: auto;
  height: 69px;
  border: 2px solid colors.$primary-white-background;
  background: transparent;
  border-radius: 16px;
  color: var(--k4n-secondary-light-color);
  padding-left: 23px;
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
}

.CustomModal {
  background-color: var(--k4n-primary-color);
}

.body-info {
  font-family: typography.$font-main;
  font-style: normal;
  color: var(--k4n-secondary-light-color);
}
.insurance-cancel-button-div {
  padding-top: 15px;
}

.ticket-info {
  padding-left: 20px;
  color: colors.$complementary-black;
}

.insurance-cancel-button {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 120%;
  color: var(--k4n-primary-color);
  background-color: var(--k4n-secondary-color);
  border: none;
  border-radius: 28px;
  height: 56px;
  width: 173px;
}

.insurance-cancel-button:hover {
  opacity: 0.9;
}

.custom-modal-content {
  display: flex;
  justify-content: space-evenly;
  margin-top: 20px;
}

.insurance-cancellation-in-progress {
  text-align: center;
  margin: 32px;
  color: colors.$complementary-black;
}

.cancel-form-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.info-insurance {
  color: colors.$complementary-black;
  padding: 0 36px;
  margin-bottom: 10px;
}

.info-insurance-bold {
  font-family: typography.$font-bold;
}

.cancel-button {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 120%;
  color: var(--k4n-primary-color);
  background-color: var(--k4n-secondary-color);
  border: none;
  border-radius: 28px;
  height: 56px;
  width: 173px;
}

.insurance-cancel-description {
  color: colors.$complementary-black;
}

.input-error-insurance {
  color: #ff5050;
  display: flex;
  margin-bottom: 20px;
  justify-content: center;
}

.input-text {
  margin-bottom: 30px;
}

@media screen and (max-width: 768px) {
  .cancel-insurance-content {
    margin: 10px;
  }

  .info-insurance {
    display: block;
    padding: 0;
  }
  .input {
    max-width: 180px;
  }
}
