@use "../../../assets/scss/typography";

.wait-account-container {
  background-color: var(--k4n-primary-color);
  width: 100%;
  min-height: 100vh;
  margin: 0;
  padding-top: 100px;
}

.wait-account-h1 {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 120%;
  text-align: center;
  color: var(--k4n-secondary-color);
}

.wait-account-title {
  margin: 0 auto;
  width: 100%;
  text-align: center;
  max-width: 742px;
  margin-bottom: 60px;
}
