@use "../../../../../../assets/scss/colors";
@use "../../../../../../assets/scss/typography";

.panel-mobile-title {
  width: 100%;
  height: 80px;
  border-bottom: 1px #dadada solid;
  display: flex;
  align-items: center;
}

.title-mobile-blue {
  font-family: typography.$font-bold;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 22px;
  color: var(--k4n-primary-light-color);
}

.step-3-mobile-info-div {
  width: 100%;
  display: flex;
  padding: 20px 0px;
  flex-direction: column;
}

.step-3-mobile-info-div-flex {
  display: flex;
  gap: 30px;
  color: #737373;
  flex-direction: column;
}

.step-3-info-mobile-span-bold {
  color: #737373;
  font-size: 14px;
  font-weight: 700;
}

.step-3-info-mobile-span {
  color: #737373;
  font-size: 14px;
}

.step-3-mobile-value-info {
  flex-direction: row;
  width: 100%;
  display: flex;
}

.step-3-mobile-flex-gap-10 {
  gap: 10px;
  display: flex;
}

.step3-info-mobile-div {
  // height: 55px;
  display: flex;
  flex-direction: row;
}

.info-span-bold-16 {
  font-family: typography.$font-main;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  color: #0a0203;
}

.info-span-grey-16 {
  font-family: typography.$font-main;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  color: #737373;
}

.step3-steps-mobile-value-info {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.info-span-grey-24 {
  font-family: typography.$font-main;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  color: #737373;
}

.info-step-3-mobile-span-blue-24 {
  font-family: typography.$font-bold;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 22px;
  color: var(--k4n-primary-light-color);
}

.margin-top-20 {
  margin-top: 20px;
}

.step3-transfer-mobile-contact-div {
  padding: 15px 0px;
  display: flex;
  gap: 2px;
  border-bottom: 1px #dadada solid;
  padding-right: 20px;
  flex-direction: column;
}

.padding-left-55 {
  padding-left: 55px;
}

.form-step-3-div {
  padding: 20px 0px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px #dadada solid;
}

.send-message-content {
  width: 320px;
}

.send-message-box {
  width: 320px;
  height: 55px;
  border: 1px solid var(--k4n-secondary-color);
  border-radius: 16px;
  display: flex;
  align-items: center;
  padding: 0px 20px;
}

.send-message-input {
  color: #212529 !important;
  border: transparent;
  width: 100%;
}

.send-message-input::placeholder {
  font: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 120%;
  color: #0a0203;
}

.float-r {
  float: right;
}

.send-message-limit-char {
  font: typography.$font-main;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: var(--k4n-primary-light-color);
}

.info-span-grey-14 {
  font: typography.$font-main;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #737373;
}

.transfer-step3-date {
  display: flex;
  align-items: center;
}

.transfer-mobile-step-3-checkbox-div {
  display: flex;
  gap: 10px;
  width: 100%;
  height: 20px;
  align-items: center;
  margin: 10px 0px;
  background: transparent;
}

.checkbox-mobile-transfer-step-3 {
  width: 24px;
  height: 24px;
}

.checkbox-mobile-transfer-step-3:checked {
  background-color: var(--k4n-secondary-color);
  border-color: var(--k4n-secondary-color);
}

.checkbox-mobile-transfer-step-3:focus {
  border-color: var(--k4n-secondary-color);
  box-shadow: none;
}

.transfer-mobile-step-3-checkbox-span {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  color: #0a0203;
}

.transfer-mobile-step3-date-input {
  width: 52px !important;
  height: 25px !important;
}

.transfer-mobile-step3-date-input:checked {
  background-color: var(--k4n-secondary-color);
  border-color: var(--k4n-secondary-color);
}

.transfer-mobile-step-3-btns-div {
  margin-top: 60px;
  width: 50%;
  height: 100px;
  float: right;
  gap: 30px;
  display: flex;
  justify-content: flex-start;
  flex-direction: row-reverse;
  align-items: center;
}

.padding-top-30 {
  padding-top: 30px;
}

.cancel-btn {
  background: transparent;
  height: 60px;
  width: 170px;
  border: 2px solid var(--k4n-secondary-color);
  border-radius: 28px;
}

.cancel-span {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  color: var(--k4n-secondary-color);
}

.height-auto {
  height: auto;
}

.transfer-mobile-schedule-alert {
  display: flex;
  justify-content: space-around;
  margin-top: 16px;
}

.transfer-mobile-custom-modal {
  text-align: center;
  margin-top: 16px;
}

.transfer-step3-payment-table-mobile {
  border-bottom: 1px solid #dadada;
  padding: 16px 0;
}

.transfer-step3-payment-step3-h1-mobile {
  font-family: typography.$font-bold;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 120%;
  color: #0a0203;
}

.transfer-step3-payment-step3-txt-mobile {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 129%;
  color: #737373;
}

.transfer-payment-date-mobile {
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  border-bottom: 1px solid #dadada;
  padding: 16px 0;
  align-items: center;
}

.transfer-payment-step3-h1-mobile {
  font-family: typography.$font-bold;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 120%;
  color: #0a0203;
}

.transfer-payment-step3-txt-mobile {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 129%;
  color: #737373;
}

.transfer-payment-img {
  width: 22px !important;
  height: 22px !important;
}

.transfer-payment-step3-message-mobile {
  width: 90%;
}

.transfer-step3-destino {
  font-family: typography.$font-bold;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 150%;
  color: #0a0203;
}

.align-right {
  width: 100%;
  text-align: right;
}
