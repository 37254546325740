@use "../../../../../../assets/scss/colors";
@use "../../../../../../assets/scss/typography";

.vertical-center {
  margin: 0;
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.bc-step-1-header {
  width: 100%;
  margin-top: 89px;
  display: inline-flex;
  height: 54px;
  padding-bottom: 25px;
  border-bottom: 1px solid rgba(10, 2, 3, 0.1);
  cursor: auto;
}

.bc-input-disabled-bg {
  background-color: #d4d4d438;
}

.bc-select-favorite-div {
  float: right;
  width: inherit;
}

.bc-step-1-title {
  font-family: typography.$font-bold;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 22px;
  color: var(--k4n-primary-light-color);
  display: inline-block;
  white-space: nowrap;
}

.bc-select-favorite {
  float: right;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px 66px 16px 24px;
  width: 312px;
  height: 54px;
  background: colors.$primary-white-background;
  color: black !important;
  border-radius: 30px;
  border: none;
  background-image: url(../../../../../../assets/images/search_icon.svg);
  background-repeat: no-repeat;
  background-position: calc(100% - 34px) center;
}

.bc-favorite-right {
  float: right;
  outline: none;
  border-radius: 16px;
  position: relative;
  bottom: 9px;
}

.bc-favorite-size {
  width: 312px;
}

.bc-select-favorite::-webkit-input-placeholder {
  /* Edge */
  font-family: typography.$font-bold;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  color: var(--k4n-primary-color);
}

.bc-select-favorite:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-family: typography.$font-bold;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  color: var(--k4n-primary-color);
}

.bc-select-favorite::placeholder {
  font-family: typography.$font-bold;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  color: var(--k4n-primary-color);
}

.show-input {
  display: block !important;
}

.bc-step-1-input-row-1 {
  width: 100%;
  height: auto;
  margin-top: 40px;
  position: relative;
}

.bc-step-1-input-row-2 {
  width: 100%;
  height: auto;
  position: relative;
  margin-top: 24px;
}

.bc-step-1-header-2 {
  position: relative;
  margin-top: 48px;
  height: 54px;
  padding-bottom: 25px;
  border-bottom: 1px solid rgba(10, 2, 3, 0.1);
}

.bc-step-1-input-row-3 {
  width: 100%;
  height: auto;
  position: relative;
  margin-top: 40px;
}

.bc-step-1-input-row-4 {
  width: 100%;
  height: auto;
  position: relative;
  margin-top: 24px;
}

.mi-wd-625 {
  min-width: 625px;
}

.mi-wd-463 {
  min-width: 463px;
}

.mx-wd-149 {
  max-width: 149px;
}

.mx-wd-mx-content {
  max-width: max-content;
}

.mx-wd-row {
  max-width: 1500px;
}

.bc-step-1-select {
  width: 315px;
  height: 55px;
  border: 2px solid colors.$primary-white-background;
  background: transparent;
  border-radius: 16px;
  color: colors.$complementary-black;
  padding-left: 23px;
  background-image: url(../../../../../../assets/images/arrow_yellow_down.svg);
  background-repeat: no-repeat;
  background-position: calc(100% - 14px) center;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
  cursor: pointer;
}

.bc-step-1-select:focus-visible {
  outline: none;
}

.bc-step1-checkbox {
  border: 1px solid var(--k4n-secondary-color);
  background-color: transparent;
  height: 24px;
  width: 24px;
  border-radius: 5px !important;
  margin-left: 30px;
  padding: 0;
}

.bc-step1-checked {
  background-color: var(--k4n-secondary-color);
  background-image: url("../../../../../../assets/images/check.svg");
  background-size: 12px 12px;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 5px;
}

.bc-step-1-input-row-check {
  width: 100%;
  height: auto;
  position: relative;
  margin-top: 40px;
}

.save-as-favorite-txt {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  color: colors.$complementary-black;
  width: 200px;
  white-space: nowrap;
  align-self: center;
}

.mx-wd-339 {
  max-width: 339px;
}

.mx-wd-173 {
  max-width: 173px;
}

.mx-wd-649 {
  max-width: 649px;
}

.mx-wd-487 {
  max-width: 487px;
}

@media only screen and (max-width: 1230px) {
  .input-phone-mt-24 {
    margin-top: 24px;
  }
}

@media only screen and (max-width: 1380px) {
  .input-complement-mt-24 {
    margin-top: 24px;
  }
}

@media only screen and (max-width: 1384px) {
  .input-state-mt-24 {
    margin-top: 24px;
  }
}
