@use "../../../../../../assets/scss/colors";
@use "../../../../../../assets/scss/typography";

.pix-receipt-content {
  margin-top: 20px;
  width: 100%;
  height: auto;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
}

.pix-receipt-img-title {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  margin-top: 48px;
  border-bottom: 1px solid #dadada;
}

.pix-receipt-message {
  display: flex;
  flex-direction: column;
  margin-top: 48px;
}

.pix-receipt-nav-img {
  display: flex;
  justify-content: space-between;
  margin-bottom: 44px;
}

.pix-receipt-img-close {
  width: 22.54px;
  height: 22.54px;
  cursor: pointer;
}

.pix-receipt-img-share {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.pix-receipt-capture {
  padding: 42px 68px;
}

.pix-receipt-content-title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.mg-bottom-17 {
  margin-bottom: 17px !important;
}
