@use "../../../assets/scss/typography";

.warning-account-container {
  background-color: var(--k4n-primary-color);
  width: 100%;
  min-height: 100vh;
  margin: 0;
  padding-top: 100px;
}

.warning-account-h1 {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 120%;
  text-align: center;
  color: var(--k4n-secondary-color);
}

.warning-account-title {
  margin: 0 auto;
  width: 100%;
  text-align: center;
  max-width: 742px;
  margin-bottom: 60px;
}

.warning-account-flex-center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 25px;
}

.warning-error-icon-space {
  width: 88px;
  height: 88px;
  border-radius: 50%;
  background: linear-gradient(
    226.39deg,
    rgba(255, 242, 222, 0.7) 15.37%,
    rgba(255, 242, 222, 0.19) 85.86%
  );
}

.warning-error-exclamation-div {
  width: 50px;
  height: 50px;
  margin: 0 auto;
  position: relative;
  top: 19px;
}
