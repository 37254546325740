@use "../../../../../../assets/scss/colors";
@use "../../../../../../assets/scss/typography";

.bc-step-3-title {
  font-family: typography.$font-bold;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 120%;
  color: colors.$complementary-black;
  white-space: nowrap;
  margin-bottom: 11px;
}

.bc-step-3-row {
  width: 100%;
  height: auto;
  position: relative;
  margin-top: 32px;
  padding-bottom: 24.5px;
}

.bc-step-3-label {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 18px;
  color: #737373;
}

.bc-step-3-value {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: colors.$complementary-black;
}

.bc-step-3-grid-col {
  display: grid;
}

.row-mx-wd-step-3 {
  max-width: 1035px;
}

.bc-step-3-border-bottom {
  border-bottom: 1px solid #dadada;
}

.bc-step-3 {
  width: 100%;
  height: auto;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  min-height: 500px;
  margin-top: 80px;
  padding-left: 29px;
  padding-top: 27px;
  padding-bottom: 25px;
  padding-right: 32px;
}

.bc-step-3-info {
  height: 664px;
  max-width: 1118px;
  max-height: 664px;
  padding-left: 33px;
  padding-top: 32px;
  background: #fcfcfc;
  padding-bottom: 101px;
  border-radius: 16px;
}

.bc-step-3-item-div {
  width: 100%;
  display: grid;
}

.mt-8 {
  margin-top: 8px;
}

.mt-81 {
  margin-top: 81px;
}

.mt-11 {
  margin-top: 11px;
}

.mx-wd-202 {
  max-width: 202px;
}

.mx-wd-289 {
  max-width: 289px;
}

.mx-wd-517 {
  max-width: 517.5px;
}

.mx-wd-171 {
  max-width: 171px;
}

.mn-wd-360 {
  min-width: 360px;
}

.mn-wd-275 {
  min-width: 275px;
}

.mn-hg-76 {
  min-height: 76px;
}

.mx-wd-275 {
  max-width: 171px;
}

.pb-0 {
  padding-bottom: 0px !important;
}

.bc-step-3-item-row {
  display: flex;
}

.bc-step-3-issue-btn {
  text-align: left;
  padding-left: 34px;
}

.bc-step-3-issue-btn-arrow {
  margin-left: 42px;
}

.pr-10 {
  padding-right: 10px;
}

.bc-step-3-value-break {
  overflow-wrap: anywhere;
}

.bc-step-3-value-overflow {
  word-wrap: break-word;
  padding-right: 10px;
  max-width: 115px;
}

.bc-spinner-btn {
  margin: 0 auto;
  display: block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
  color: #fff;
}

.no-pl {
  padding-left: 0 !important;
}

.v-middle {
  vertical-align: middle;
}
