@use "../../../../assets/scss/colors";
@use "../../../../assets/scss/typography";

.home-banking-wrapper {
  background-color: transparent;
  width: 100%;
  margin: 0;
  display: inline-block;
  margin-top: 56px;
  padding-left: 75px;
  padding-right: 75px;
}

.home-mobile-wrapper {
  background-color: transparent;
  width: 100%;
  margin: 0;
  display: inline-block;
  margin-top: 30px;
  padding-left: 5%;
  padding-right: 5%;
}

.home-banking-card {
  width: 100%;
  height: 77px;
  background: var(--k4n-secondary-light-color);
  border-radius: 16px;
  padding: 0 54px;
  display: inline-flex;
  align-items: center;
}

.home-banking-card-img {
  width: 24px;
  height: 24px;
}

.home-banking-card-h1 {
  font-family: typography.$font-bold;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  display: flex;
  align-items: center;
  color: var(--k4n-quaternary-color);
  margin: 0;
  padding-left: 18px;
}

.home-banking-content {
  width: 100%;
  height: 576px;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  padding: 59px 68px;
  margin-top: 27px;
}

.home-banking-h1 {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;
  margin-top: 25px;
  cursor: pointer;
}

.home-banking-content-h1 {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 120%;
  color: #0a0203;
  margin: 0;
}

.home-banking-content-extrato {
  height: 60%;
  margin-top: 15px;
  padding-right: 15px;
  overflow-y: auto;
}

.home-align-btn {
  padding-top: 52px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

// Service channel

.home-banking-service-channel-h1 {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 22px;
  color: var(--k4n-primary-color);
  margin: 0;
}

.home-banking-img-close {
  width: 22.5px;
  height: 22.5px;
  justify-content: space-between;
  cursor: pointer;
}

.space-between {
  justify-content: space-between;
}

.service-channel-content-h1 {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 120%;
  color: var(--k4n-primary-color);
  margin: 0;
}

.service-channel-card {
  width: 300px;
  height: 369px;
  border-radius: 28px;
  background-color: var(--k4n-primary-color);
  margin-top: 40px;
  margin-right: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 24px;
}

.service-channel-card-h1 {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 120%;
  color: #ffffff;
  margin: 0;
  padding-top: 24px;
}

.service-channel-card-txt {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: var(--k4n-secondary-color);
  margin: 0;
  padding-top: 16px;
}

.service-channel-card-img-txt {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 80px;
  padding-bottom: 65px;
}

.service-channel-card-img {
  width: 56px;
  height: 56px;
}

.service-channel-card-border {
  border-top: 1px solid var(--k4n-secondary-color);
}

.key-vector {
  filter: invert(86%) sepia(37%) saturate(3134%) hue-rotate(328deg)
    brightness(104%) contrast(97%);
}

.digital-signature-h1 {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  color: var(--k4n-secondary-color);
  margin: 0;
}

.home-bold-btn {
  width: 145px;
  height: 60px;
  margin-top: 10px;
  background: var(--k4n-secondary-color);
  border-radius: 28px;
  border: none;
  text-align: left;
  font-family: typography.$font-bold;
  line-height: 120%;
  font-size: 20px;
  color: var(--k4n-primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-bold-btn:hover {
  opacity: 0.9;
}
