@use "../../../../../../../assets/scss/colors";
@use "../../../../../../../assets/scss/typography";

.bc-step-mobile-content {
  width: 100%;
  height: 100%;
  padding-top: 10px;
  background-color: var(--k4n-primary-color);
}

.bc-header-h1-mobile {
  font-family: typography.$font-bold;
  font-size: 24px;
  line-height: 40px;
  color: var(--k4n-primary-color);
  margin-top: 20px;
}

.bc-title-div {
  display: flex;
  border-bottom: 1px solid #dadada;
}

.bc-step-mobile {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  border-top-left-radius: 26px;
  border-top-right-radius: 26px;
  padding: 8% 5% 5% 5%;
}

.boleto-box-mobile {
  width: 100%;
  min-height: 67px;
  background: #fff2de;
  border-radius: 28px;
  border: none;
  padding: 10px 20px;
  display: flex;
  margin-top: 24px;
  flex-direction: column;
  justify-content: center;
}

.boleto-btn-div {
  margin-top: 30px;
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}

.boleto-creation-input-mobile {
  border: transparent;
  background: transparent;
  color: #0a0203;
  font-family: typography.$font-main;
  font-size: 16px;
  line-height: 18px;
}

.bc-error-msg-mobile {
  font-family: typography.$font-main;
  font-size: 16px;
  line-height: 120%;
  color: #ed6465;
  text-align: left;
  margin-top: 5px;
}

.boleto-creation-txt-mobile {
  font-family: typography.$font-main;
  font-size: 10px;
  line-height: 18px;
  color: #737373;
  display: none;
  padding-left: 2px;
}

.bc-step-1-select-mobile {
  border: 2px solid colors.$primary-white-background;
  background: transparent;
  border-radius: 16px;
  color: colors.$complementary-black;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
  cursor: pointer;
}

.bc-step-1-select-mobile:focus-visible {
  outline: none;
}

.mt-20 {
  margin-top: 20px;
}

.bc-checkbox-mobile {
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 15px;
  padding-left: 10px;
}

.ml-0 {
  margin-left: 0;
}

.f-12-grey {
  font-family: typography.$font-main;
  font-size: 16px;
}
