@use "../../../../../../assets/scss/colors";
@use "../../../../../../assets/scss/typography";

.transfer-receipt-mobile-container {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #fff;
}

.pd-2 {
  padding: 2%;
}

.panel-content {
  margin-top: 20px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  padding: 16px 24px;
}

.transfer-receipt-mobile-receipt-btns {
  height: 50px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 15px;
}

.close-btn {
  float: left;
  border: transparent;
  background: transparent;
}

.share-btn {
  float: right;
  border: transparent;
  background: transparent;
}

.transfer-receipt-mobile-receipt-title {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: var(--k4n-primary-light-color);
}

.transfer-receipt-mobile-span-title-18 {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  line-height: 18px;
  color: #737373;
  margin-right: 4px;
}

.transfer-receipt-mobile-receipt-message {
  display: flex;
}

.transfer-mobile-receipt-info-div {
  height: 55px;
  display: flex;
  flex-direction: row;
}

.info-span-bold-16 {
  font-family: typography.$font-main;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  color: #0a0203;
}

.info-span-grey-16 {
  font-family: typography.$font-main;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  color: #737373;
}

.transfer-receipt-img-div {
  height: 50px;
  border-bottom: 1px solid #dadada;
  display: flex;
  align-items: center;
}

.transfer-receipt-mobile-info-div-wrapper {
  display: flex;
  flex-direction: column;
  gap: 15px;
  border-bottom: 1px solid #dadada;
}

.transfer-receipt-mobile-info-div {
  display: flex;
  flex-direction: row;
  gap: 10px;
  border-bottom: 1px solid #dadada;
}

.margin-top-30 {
  margin-top: 30px;
}

.receipt-transaction-id-div {
  display: flex;
  align-items: center;
  height: 120px;
  border-bottom: 1px solid #dadada;
}

.transfer-receipt-mobile-receipt-capture {
  padding: 32px 5px;
}

.transfer-receipt-row-span-title-18 {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  line-height: 18px;
  color: #737373;
}

.transfer-receipt-mobile-d-flex-w-50 {
  display: flex;
  width: 50%;
}

.transfer-receipt-mobile-d-flex-w-50-right {
  display: flex;
  width: 50%;
  text-align: right;
}

.transfer-receipt-mobile-align-right {
  text-align: right;
}
