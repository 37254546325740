@use "../../../assets/scss/colors";
@use "../../../assets/scss/typography";

html,
body {
  color: #ffffff;
  font-family: typography.$font-main;
  height: 100%;
}

.banking-wrapper {
  margin: 0;
  height: 100vh;
  width: 100vw;
  background-color: #e5e5e5;
  flex-direction: row;
  overflow-y: scroll;
}

.loading-gradient {
  animation-duration: 2.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeded 8%, #f7f6f6 38%, #eeeded 54%);
  background-size: 1000px 640px;
  position: relative;
  border-radius: 20px;
}
