@use "../../../../../../assets/scss/colors";
@use "../../../../../../assets/scss/typography";

.pix-payment-step3-container {
  width: 100%;
  display: flex;
  height: auto;
  position: relative;
  top: 56px;
  bottom: 56px;
  flex-direction: column;
}

.pix-payment-step3-title {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 92%;
  color: var(--k4n-primary-color);
  margin: 0;
}

.pix-payment-step3-content {
  margin-top: 20px;
  width: 100%;
  height: auto;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  padding: 42px 68px;
}

.pix-payment-step3-content-title {
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #dadada;
  padding-bottom: 24px;
}

.pix-payment-step3-content-table {
  width: 100%;
  height: auto;
  display: flex;
  border-bottom: 1px solid #dadada;
  flex-direction: column;
  margin-top: 24px;
}

.pix-payment-step3-txt-bold {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  color: #0a0203;
  margin-bottom: 11px;
}

.pix-payment-step3-txt {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  color: #737373;
  margin-bottom: 24px;
}

.pix-payment-step3-txt-value {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  color: #737373;
  margin-bottom: 24px;
}

.direction-column {
  display: flex;
  flex-direction: column;
}
