@use "../../../assets/scss/colors";
@use "../../../assets/scss/typography";

.step-navigation {
  float: right;
  width: 104px;
  height: 24px;
  display: inline-flex;
}

.navigation-item-active {
  width: 24px;
  height: 24px;
  background: var(--k4n-tertiary-color);
  border: 1px solid #fff;
  border-radius: 50%;
  margin: 0 auto;
  cursor: pointer;
}

.navigation-item {
  width: 24px;
  height: 24px;
  background: transparent;
  border: 1px solid var(--k4n-secondary-color);
  border-radius: 50%;
  margin: 0 auto;
  cursor: pointer;
}

.navigation-item-txt-active {
  font-family: typography.$font-bold;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  color: #fff;
}

.navigation-item-txt {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  color: var(--k4n-primary-light-color);
}

.wd-138 {
  width: 138px !important;
}
