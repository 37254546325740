@use "../../../../../assets/scss/colors";
@use "../../../../../assets/scss/typography";

.Pv-1 {
  height: 135px;
  background: var(--k4n-primary-color);
  border-radius: 28px 28px 0px 0px;
  text-align: left;
}

.Pv-2 {
  height: auto;
  min-height: 159px;
  background: var(--k4n-secondary-light-color);
  border-radius: 20px 20px 28px 28px;
  text-align: left;
  position: relative;
  top: -40px;
  padding-bottom: 79px;
}

.Pv-3 {
  height: 129px;
  background: var(--k4n-primary-color);
  border-radius: 20px 20px 28px 28px;
  position: relative;
  top: -80px;
}

.select-plan-step1 {
  height: 24px;
  border: none;
  font-family: typography.$font-bold;
  font-size: 20px;
  line-height: 120%;
  color: #ffffff;
  background: var(--k4n-primary-color);
  margin-top: 24px;
  margin-left: 24px;
}

/* Arrow drop select */

.arrow-drop {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url(../../../../../assets//images/white-down-arrow.png);
  background-repeat: no-repeat;
  background-position: right center;
  padding-right: 30px;
  background-size: contain;
}

.select-plan-step1:focus {
  color: #ffffff;
  background: var(--k4n-primary-color);
  border-radius: 28px;
  outline: none;
  border: none;
  background-image: url(../../../../../assets//images/white-down-arrow.png);
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) center;
  background-size: 20px;
}

.Pv-1-title {
  width: 163px;
  height: 24px;
  font-family: typography.$font-bold;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 120%;
  /* identical to box height, or 24px */
  color: #ffffff;
  position: relative;
  top: 24px;
  left: 24px;
}

.Pv-2-title {
  width: 112px;
  height: 24px;
  font-family: typography.$font-bold;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 120%;
  color: var(--k4n-primary-color);
  position: relative;
  left: 24px;
  padding: 0;
}

.Pv-3-symbol {
  width: 30px;
  height: 24px;
  font-style: normal;
  font-weight: bold;
  font-size: 20px !important;
  line-height: 120%;
  color: var(--k4n-secondary-color);
  padding: 0;
  margin-right: 5px;
  white-space: nowrap;
}

.Pv-3-symbol-small {
  padding-right: 6px !important;
  position: relative;
  bottom: 2px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px !important;
  line-height: 120%;
  color: var(--k4n-secondary-color);
  padding: 0;
  white-space: nowrap;
}

.Pv-3-value {
  width: 64px !important;
  height: 24px;
  font-style: normal;
  font-weight: bold;
  font-size: 20px !important;
  line-height: 120%;
  color: #ffffff;
  padding: 0;
  white-space: nowrap;
  font-family: typography.$font-main;
}

.Pv-3-value-small {
  font-style: normal;
  font-weight: bold;
  font-size: 16x !important;
  line-height: 120%;
  color: #ffffff;
  float: right;
  padding: 0;
  white-space: nowrap;
}

.Pv-1-subtitle {
  width: 144px;
  height: 22px;
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 120%;
  /* identical to box height, or 22px */
  color: #ffffff;
}

.Pv-1-subtitle-div {
  position: relative;
  top: 5px;
  left: 24px;
}

.Item-div {
  text-align: left;
  padding-left: 24px !important;
  padding-right: 0px !important;
}

.Valor-div {
  text-align: right;
  padding-right: 22px !important;
}

.Valor-mensalidade {
  padding-right: 19px !important;
  text-align: right;
}

.Display-blck {
  display: block !important;
}

.Container-title {
  width: 100%;
  top: 16px !important;
  position: relative;
  margin: 0;
}

.Div-item-2 {
  height: 48px;
}

.Center-top {
  position: relative;
  top: 30%;
}

.Center-top-33 {
  position: relative;
  top: 33%;
}

.Item {
  width: 60px;
  height: 19px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: var(--k4n-primary-color);
}

.Valor-item {
  width: 65px;
  height: 17px;
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 120%;
  color: var(--k4n-primary-color);
  font-family: typography.$font-bold;
}

.Container-item {
  width: 100% !important;
  margin: 32px 0 0 0 !important;
}

.Container-3 {
  width: 100%;
  margin-left: 0 !important;
}

.Container-valor-total {
  margin-top: 14px !important;
  width: 100%;
  margin-left: 0 !important;
}

.Col-mensalidade {
  padding: 0;
  text-align: left;
  padding-left: 24px !important;
  padding-right: 0px !important;
}

.Container-title {
  width: 100%;
  top: 16px !important;
  position: relative;
  margin: 0;
}

.Container-top {
  margin-top: 32px !important;
}

.Txt-mensalidade {
  font-family: "typography.$font-bold;";
  width: 118px;
  height: 24px;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 120%;
  color: #ffffff;
}

.Center-vertically {
  display: flex;
  align-items: center;
  justify-content: right;
}

.inline-flx {
  display: inline-flex;
}

.Pv-kikkin {
  max-width: 350px;
}

.Pv-1-mobile {
  width: 312px;
  height: 145px;
  background: var(--k4n-primary-color);
  border-radius: 28px 28px 0px 0px;
  text-align: left;
}

.Pv-2-mobile {
  width: 312px;
  height: auto;
  min-height: 275px;
  background: var(--k4n-secondary-light-color);
  border-radius: 20px 20px 28px 28px;
  text-align: left !important;
  position: relative;
  top: -40px;
  padding-bottom: 79px;
}

.Pv-kikkin-mobile {
  width: 312px;
  height: auto;
  min-height: 631px;
  margin: 0 auto;
}

.Pv-3-mobile {
  width: 312px;
  height: 220px;
  background: var(--k4n-primary-color);
  border-radius: 20px 20px 28px 28px;
  position: relative;
  top: -80px;
}

.Pv-1-title-mobile {
  width: 163px;
  height: 24px;
  font-family: typography.$font-bold;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 120%;
  /* identical to box height, or 24px */
  color: #ffffff;
  position: relative;
  top: 24px;
  left: 24px;
}

.Pv-2-title-mobile {
  width: 112px;
  height: 24px;
  font-family: typography.$font-bold;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 120%;
  color: var(--k4n-primary-color);
  position: relative;
  left: 24px;
  padding: 0;
}

.Pv-3-symbol-mobile {
  width: 30px;
  height: 24px;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 120%;
  color: var(--k4n-secondary-color);
  margin-right: 5px;
  padding: 0;
  white-space: nowrap;
}

.ft-sz-16 {
  font-size: 16px !important;
}

.Pv-3-col-vlr-total {
  text-align: right;
}

.Pv-3-value-mobile {
  width: 64px;
  height: 24px;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 120%;
  color: #ffffff;
  padding: 0;
  white-space: nowrap;
}

.Pv-1-subtitle-mobile {
  width: 144px;
  height: 22px;
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 120%;
  /* identical to box height, or 22px */
  color: #ffffff;
}

.Pv-1-subtitle-div-mobile {
  margin-left: 24px;
  margin-top: 5px;
}

.Item-div-mobile {
  text-align: left;
  padding-left: 24px !important;
  padding-right: 0px !important;
}

.Valor-div-mobile {
  text-align: right;
  padding-right: 22px !important;
}

.Div-item-2-mobile {
  height: 48px;
}

.Center-vertically-mobile {
  display: flex;
  align-items: center;
  justify-content: right;
}

.Item-mobile {
  width: 60px;
  height: 19px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: var(--k4n-primary-color);
}

.Valor-item-mobile {
  width: 65px;
  height: 17px;
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 120%;
  white-space: nowrap;
  color: var(--k4n-primary-color);
  font-family: typography.$font-bold;
}

.Container-item-mobile {
  width: 100% !important;
  margin: 32px 0 0 0 !important;
}

.Container-3-mobile {
  margin-top: 41px !important;
  width: 100%;
  margin-left: 0 !important;
}

.Container-3-mg-14 {
  width: 100%;
  margin-top: 14px !important;
  margin-left: 0 !important;
}

.Col-mensalidade-mobile {
  padding: 0;
  text-align: left;
  padding-left: 24px !important;
  padding-right: 0px !important;
}

.Container-title-mobile {
  width: 100%;
  top: 16px !important;
  position: relative;
  margin: 0;
}

.Container-top-mobile {
  margin-top: 32px !important;
}

.Txt-mensalidade-mobile {
  width: 118px;
  height: 24px;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 120%;
  color: #ffffff;
  font-family: "typography.$font-bold;";
}

.Valor-mensalidade-mobile {
  padding-right: 15px !important;
  text-align: right;
}

.Pd-right-19 {
  padding-right: 19px !important;
}

.Txt-align-right {
  text-align: right;
}

@media screen and (max-width: 768px) {
  .Pv-kikkin {
    margin: auto;
    width: 100%;
  }
}
