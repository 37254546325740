@use "../../../../../../assets/scss/colors";
@use "../../../../../../assets/scss/typography";

.insurance-step-2 {
  background: transparent;
  width: 100%;
}

.insurance-content-step-2 {
  width: 100%;
  margin: 0 auto;
}

.insurance-step-2-bg {
  max-width: 824px;
  width: 100%;
  height: auto;
  // height: 379px;
  padding: 24px;
  border-radius: 28px;
  background-color: #fbfbfb;
  text-align: left;
  margin: 0 auto;
  padding: 24px;
  margin-top: 24px;
}

.insurance-step-2-h1 {
  font-family: typography.$font-bold;
  color: var(--k4n-primary-color);
  width: 100%;
  margin: 0;
  font-size: 20px;
  line-height: 28px;
}

.no-pd {
  padding: 0 !important;
}

.insurance-step-2-name {
  width: 624px;
  height: 69px;
  padding-left: 36px;
  padding-right: 36px;
  max-width: 100%;
}

.insurance-step-2-input-pct {
  width: 150px;
  height: 69px;
  padding-left: 36px;
  padding-right: 36px;
}

.insurance-step-2-span-pct {
  position: relative;
  right: 5%;
  color: var(--k4n-primary-color);
  font-size: 18px;
  top: 1px;
}

.is-step-2-input textarea:focus,
.is-step-2-inputinput:focus {
  color: #ffffff;
  background: var(--k4n-primary-light-color);
  border-radius: 28px;
  outline: none;
  border: none;
}

.insurance-step-2-error-msg {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: #ed6465;
  text-align: left;
  padding-top: 5px;
  padding-left: 30px;
  margin: 0;
  margin-top: 10px;
  margin-bottom: 10px;
}

.insurance-step-2-add-span {
  color: white;
  font-size: 27px;
  position: relative;
  bottom: 2px;
}

.insurance-step-2-mobile {
  width: 100%;
  min-width: 100%;
  height: auto;
  padding: 0 !important;
  margin-top: 20.04px;
  padding-left: 24px !important;
  padding-right: 24px !important;
}

.insurance-step-2-span-pct-mobile {
  position: relative;
  right: 11%;
  color: white;
  font-size: 18px;
  top: 1px;
}

.insurance-step-2-next-btn-mobile {
  width: 95px;
  height: 70px;
  background: var(--k4n-secondary-color);
  border-radius: 28px;
  border: 1px solid var(--k4n-secondary-color);
  position: relative;
  left: 35%;
}

.insurance-step-2-trash {
  width: 20px;
  height: 20px;
  float: right;
  cursor: pointer;
  background-color: var(--k4n-secondary-color);
  mask-size: contain;
  -webkit-mask-size: contain;
  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-image: url("../../../../../../assets/images/trash.svg");
  mask-image: url("../../../../../../assets/images/trash.svg");
  outline: none;
  border: none;
}

.insurance-step-2-header {
  display: inline-flex;
  width: 100%;
  height: fit-content;
}

.insurance-step-2-add {
  width: 58px;
  height: 45px;
  margin: 0 auto;
  position: relative;
  top: -22px;
}

.insurance-step-2-add-btn {
  width: 58px;
  height: 45px;
  background: var(--k4n-secondary-color);
  border-radius: 28px;
  border: 1px solid var(--k4n-secondary-color);
}

.mt-32 {
  margin-top: 22px;
}

.no-spin::-webkit-inner-spin-button,
.no-spin::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

.no-spin {
  -moz-appearance: textfield !important;
}

.insurance-step-2-label {
  margin-top: 24px;
  margin: 10px 0 5px;
  color: var(--k4n-secondary-color);
  font-size: 15px;
}

.margin-0 {
  margin: 0;
}

.insurance-step-2-separator {
  margin: 15px 0;
}
