.password-input-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 312px;
  height: 70px;
  background: var(--k4n-primary-light-color);
  border-radius: 28px;
  border: none;
  padding-left: 28px;
  padding-right: 28px;
  margin-bottom: 18px;
  color: var(--k4n-primary-color);
  cursor: text;
  input {
    border: none;
    padding: 0;
    margin: 0;
    flex: 1;
    background-color: transparent;
    color: var(--k4n-primary-color);
  }
}

.password-input-container input::placeholder {
  color: #9d9b9b;
  opacity: 0.65;
}

.password-input-button {
  cursor: pointer;
}
