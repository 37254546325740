@use "../../../../../../../assets/scss/colors";
@use "../../../../../../../assets/scss/typography";

.pix-receipt-content-mobile {
  width: 100%;
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
}

.pix-receipt-capture-mobile {
  padding: 10%;
}

.pix-receipt-table-mobile {
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #dadada;
  padding-top: 16px;
  padding-bottom: 16px;
}

.pix-receipt-mobile {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
}

.pix-receipt-h1-mobile {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 120%;
  color: #737373;
}

.border-bottom-none {
  border-bottom: none !important;
}

.pix-receipt-title-mobile {
  font-family: typography.$font-bold;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 125%;
  color: var(--k4n-primary-color);
}

.mg-top-8 {
  margin-top: 8px !important;
}

.mg-bottom-50 {
  margin-bottom: 50px;
}

.text-align-right {
  text-align: right !important;
}
