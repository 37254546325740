@use "../../../../../assets/scss/colors";
@use "../../../../../assets/scss/typography";

.home-mobile-txt {
  font-family: typography.$font-bold;
  font-weight: 700;
  font-size: 18px;
  line-height: 120%;
  color: #0a0203;
  margin-bottom: 20px;
}

.home-mobile-content-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  padding: 0 0 10%;
}

.home-mobile-content {
  max-height: 200px !important;
  overflow-y: auto;
  width: 100%;
}
