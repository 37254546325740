.list-controls {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.showing-dropdown {
  width: 100%;

  @media (min-width: 768px) {
    width: auto;
    margin-right: 1rem;
  }
}

.showing-select {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  color: #000;
  background-color: white;
  cursor: pointer;
  font-size: 0.875rem;

  @media (min-width: 768px) {
    width: auto;
  }
}

.showing-select:focus {
  outline: none;
  border-color: #666;
}

.pagination {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;

  @media (min-width: 768px) {
    width: auto;
    justify-content: flex-start;
  }
}

.pagination-arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: white;
  cursor: pointer;
  color: #000;
}

.pagination-arrow:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.pagination-arrow .icon {
  width: 16px;
  height: 16px;
}

.page-text {
  color: #000;
  font-size: 0.875rem;
}

.page-input {
  width: 3rem;
  padding: 0.25rem;
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 4px;
  color: #000;
}

.results-text {
  color: #000;
  font-size: 0.875rem;
  text-align: center;
  width: 100%;

  @media (min-width: 768px) {
    text-align: right;
    width: auto;
    margin-left: 1rem;
  }
}
