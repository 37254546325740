@use "../../../../../../assets/scss/colors";
@use "../../../../../../assets/scss/typography";

.pix-panel-mobile {
  height: 100%;
  background-color: var(--k4n-primary-color);
}

.pix-panel-header-mobile {
  padding: 10% 10%;
}

.pix-panel-img-mobile {
  width: 27.48px !important;
  height: 15.02px !important;
  outline: none !important;
  border: none !important;
  cursor: pointer !important;
  background-color: var(--k4n-secondary-color) !important;
  mask-size: contain !important;
  mask-repeat: no-repeat !important;
  -webkit-mask-image: url("../../../../../../assets/images/arrow_yellow.svg") !important;
  mask-image: url("../../../../../../assets/images/arrow_yellow.svg") !important;
}

.pix-panel-h1-mobile {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: var(--k4n-secondary-color);
  margin-top: 40px;
}

.pix-panel-txt-mobile {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
  margin-top: 40px;
}

.pix-panel-content-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  background: #ffffff;
  border-radius: 28px 28px 0 0;
  padding: 10% 10%;
}

.pix-panel-cards-mobile {
  width: 100%;
  display: inline-flex;
  gap: 16px;
}

.pix-panel-card-mobile {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 120px;
  padding: 16px;
  background: #fff2de;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
}

.pix-panel-card-mobile-disabled {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 120px;
  padding: 16px;
}

.pix-panel-card-h1-mobile {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: var(--k4n-primary-color);
}

.pix-panel-card-img-mobile {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.mg-top-16 {
  margin-top: 16px;
}

.pix-panel-config-mobile {
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  margin: 10% 0;
}

.pix-panel-config-h1-mobile {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: var(--k4n-primary-color);
}

.pix-panel-config-table-mobile {
  width: 100%;
  display: inline-flex;
  align-items: center;
  padding: 24px 0;
  border-bottom: 1px solid rgba(10, 2, 3, 0.1);
}

.pix-panel-config-table-align-mobile {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
