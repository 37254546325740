@use "../../../../../../assets/scss/colors";
@use "../../../../../../assets/scss/typography";

.insurance-step-1-mobile {
  background: transparent;
  align-items: center;
  margin-top: 30px;
}

.insurance-content-step1 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.insurance-step-1-details {
  width: 50%;
  margin-left: 20px;
  margin-right: 60px;
}

.insurance-step-1-input {
  width: 100%;
}

.insurance-plan-col {
  padding: 0;
  max-width: 312px !important;
}

.insurance-content-step-1 {
  display: flex;
  align-items: left;
  margin-top: 10px;
  width: 100%;
  padding: 15px;
}

.insurance-form-col {
  padding-left: 118px;
  padding-top: 40px;
  max-width: 465px !important;
}

.insurance-input-div {
  position: relative;
}

.mt-16 {
  margin-top: 16px;
}

.toggle-kikkin-btn {
  width: 125px;
  height: 32px;
  border: 1px solid var(--k4n-secondary-color) !important;
  box-sizing: border-box;
  border-radius: 12px !important;
  background: transparent !important;
  text-align: center !important;
  cursor: default !important;
}

.toggle-kikkin-col {
  padding: 0;
  text-align: left;
}

.toggle-active {
  background: var(--k4n-secondary-color) !important;
  cursor: default !important;

  span {
    font-family: typography.$font-bold;
    font-weight: bold !important;
  }
}

.toggle-kikkin-btn:focus,
.toggle-kikkin-btn:hover {
  background: var(--k4n-secondary-color) !important;
  box-shadow: none !important;

  span {
    font-family: typography.$font-bold;
    font-weight: bold !important;
  }
}

.toggle-kikkin-label {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 140%;
  align-items: center;
  color: var(--k4n-primary-color);
  vertical-align: middle;
}

.toggle-kikkin-span {
  bottom: 3px;
  font-style: normal;
  font-family: typography.$font-main;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  align-items: center;
  color: var(--k4n-primary-color);
}

.toggle-kikkin {
  display: inline-flex;
  margin-left: 2px;
}

.representative-label {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 120%;
  /* or 19px */
  color: var(--k4n-primary-color);
}

.representative-code {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 120%;
  /* identical to box height, or 17px */

  text-decoration-line: underline;

  color: var(--k4n-secondary-color);
  cursor: pointer;
}

.representative {
  text-align: left;
  display: grid;
  padding-top: 16px;
  width: 100%;
}

.representative-modal-span {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 120%;
  color: #ffffff;
}

.insurance-row-check {
  width: 100%;
  height: auto;
  margin-top: 16px;
  padding-right: 24px;
}

.insurance-placeholder-gradinet {
  width: calc(100% - 24px);
  height: 3rem;
  border-radius: 15px;
  animation: skeleton-loading 1.7s linear infinite alternate;
}
@keyframes skeleton-loading {
  0% {
    background-color: hsl(0, 0%, 90%);
  }
  100% {
    background-color: hsl(0, 0%, 80%);
  }
}

.insurance-recipient-txt {
  font-style: normal;
  font-family: typography.$font-main;
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;
  color: var(--k4n-primary-color);
  vertical-align: middle;
  padding-left: 9px;
}

.insurance-btn-col {
  display: block;
}

.insurance-step-1-btn {
  width: 211px;
  height: 70px;
  background-color: var(--k4n-secondary-color);
  border-radius: 28px;
  border: none;
  margin: 20px 0;
}
.insurance-step-1-btn:hover {
  opacity: 0.9;
}

.insurance-step-1-btn-txt {
  color: var(--k4n-primary-color);
  font-style: normal;
  font-family: typography.$font-bold;
  font-weight: 700;
  font-size: 20px;
  margin-left: 35px;
  vertical-align: middle;
  float: left;
}

.representative-modal-body {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.representative-modal-title {
  color: var(--k4n-primary-color);
  font-style: normal;
  font-family: typography.$font-bold;
  font-weight: 700;
  font-size: 20px;
}

.representative-modal-input {
  max-width: 315px;
  margin: 0 auto;
}

.representative-modal-btn {
  width: 137px;
  height: 70px;
  background: var(--k4n-secondary-color);
  border-radius: 28px;
  border: none;
  float: right;
  margin-top: 24px;
}

.representative-modal-btn:active,
.representative-modal-btn:focus {
  width: 137px;
  height: 70px;
  background: var(--k4n-secondary-color);
  border-radius: 28px;
  border: none;
  box-shadow: none;
}

.representative-modal-btn:hover {
  opacity: 0.9;
}

.representative-modal-btn-txt {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 120%;
  color: var(--k4n-primary-color);
  padding-right: 14px;
  font-family: typography.$font-bold;
}

.location-error {
  width: 600px;
  height: 80px;
  margin: 0;
  margin-top: 32px;
}

.location-error-x {
  margin: 0 auto;
  width: 10px;
}

.dp-table-cell {
  display: table-cell;
  vertical-align: middle;
}

.dp-table {
  display: table;
}

.pl-15 {
  padding-left: 15px;
}

.location-error-msg-div {
  padding-right: 10px;
  text-align: left;
}

.location-error-red-bar {
  width: 5.01px;
  height: 80px;
  float: left;
  background-color: #e34848;
}

.location-error-red-circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin: 0 auto;
  border: 3px solid;
  border-color: #e34848;
}

.location-error-x-img {
  vertical-align: baseline;
}

.no-padding-flx {
  display: inline-flex;
  padding: 0;
}

.location-error-txt {
  color: var(--k4n-primary-color);
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  font-family: typography.$font-main;
  position: relative;
  top: 29px;
}

.location-modal-btn {
  width: 137px;
  height: 70px;
  background: var(--k4n-secondary-color);
  border-radius: 28px;
  border: none;
  float: right;
  margin-top: 60px;
}

.location-modal-content {
  margin: 0 auto;
}

.location-modal-txt {
  color: var(--k4n-primary-color);
  font-style: normal;
  font-family: typography.$font-bold;
  font-weight: 700;
  font-size: 20px;
}

.form-select-marital {
  max-width: 570px;
  width: 92%;
  height: 69px;
  background: transparent;
  border-radius: 16px;
  border: 2px solid var(--k4n-secondary-color);
  color: colors.$complementary-black;
  padding-left: 36px;
  font-family: typography.$font-main;
  font-size: 16px;
}

.form-select-marital:focus,
.form-select-marital:focus {
  color: colors.$complementary-black;
  background: transparent;
  border-radius: 16px;
  outline: none;
}

/* Arrow drop select */

.form-select-marital {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url(../../../../../../assets/images/arrow_yellow_down.svg);
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) center;
  background-size: 20px;
}

.insurance-step1-gender {
  display: inline-flex;
  width: 100%;
  gap: 10px;
  padding: 0;
}

.nowrap {
  flex-flow: nowrap;
}

@media screen and (max-width: 768px) {
  .insurance-content-width {
    width: 100%;
    display: flex;
  }

  .insurance-content-step-1 {
    flex-direction: column;
    align-items: center;
  }
  .insurance-step-1-details {
    width: 100%;
    margin: 0;
  }
}
