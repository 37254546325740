@use "../../../../../../assets/scss/colors";
@use "../../../../../../assets/scss/typography";

.pix-receive-title-mobile {
  font-family: typography.$font-bold;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 125%;
  color: var(--k4n-primary-color);
  margin-top: 28px;
}

.pix-receive-input-mobile {
  width: 100% !important;
  max-width: 312px !important;
  height: 67px !important;
  background: #fff2de !important;
  border: none !important;
  border-radius: 28px !important;
  margin-top: 40px;
}

.pix-receive-date-mobile {
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  padding: 16px 0;
  align-items: center;
}

.pix-receive-content-keys-mobile {
  width: 100%;
  display: inline-flex;
  padding: 14px 0;
}

.justify-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
