@use "../../../../../../../assets/scss/colors";
@use "../../../../../../../assets/scss/typography";

.pix-payment-step1-mobile {
  width: 100%;
  height: 100vh;
  background-color: #ffffff;
  border-radius: 28px 28px 0 0;
  padding: 10% 10%;
}

.pix-payment-step1-h1-mobile {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #737373;
  margin-top: 8%;
}

.pix-payment-step1-keys-mobile {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  gap: 8px;
  padding: 4% 0;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
}

.pix-payment-step1-keys-mobile::-webkit-scrollbar {
  width: 0 !important;
}

.pix-payment-step1-key-mobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  width: auto;
  min-width: 85px;
  height: 115px;
  background: #fff2de;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  gap: 14px;
  border: none;

  // TXT

  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: var(--k4n-primary-color);
}

.pix-key-active-mobile {
  background: var(--k4n-primary-color);
  color: var(--k4n-secondary-light-color);
}

.pix-payment-step1-key-img-mobile {
  width: 24px;
  height: 24px;
  margin: 0;
}

.pix-payment-step1-txt-active-mobile {
  color: var(--k4n-secondary-light-color);
}

.pix-payment-step1-input-mobile {
  width: 100%;
  max-width: 312px;
  height: 67px;
  background: #fff2de;
  border-radius: 28px;
  border: none;
  padding: 24px;
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  color: #0a0203;
  margin-top: 24px;
}

.width-109-mobile {
  max-width: 109px !important;
  margin-right: 16px;
}

.width-187-mobile {
  max-width: 187px !important;
}

.pix-payment-step1-btn-mobile {
  margin-top: 21px;
}

.pix-payment-step1-form-mobile {
  text-align: center;
}

.pix-error-msg-mobile {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: #e34848;
  margin-top: 10px;
}

.margin-0 {
  margin: 0;
}

.pix-payment-input-phone-mobile {
  width: 100%;
  display: inline-flex;
  justify-content: center;
}
