@use "../../../assets/scss/colors";
@use "../../../assets/scss/typography";

.onboarding-modal-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 100;
  max-width: 90%;
  margin: 0 auto;
}

.onboarding-modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba($color: #000000, $alpha: 0.4);
}

.onboarding-modal-container {
  background-color: var(--k4n-primary-color);
  padding: 32px;
  border-radius: 28px;
  z-index: 105;
  margin: 20px 0;
  max-width: 100%;
}

.modal-message {
  color: #ffffff;
  font-family: typography.$font-main;
  max-width: 700px;
}

.modal-title {
  color: var(--k4n-secondary-color);
  font-family: typography.$font-bold;
}

.flex-end {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
}

.onboarding-modal-close-button {
  width: 22px;
  height: 22px;
  cursor: pointer;
  color: var(--k4n-secondary-color);
  background-color: var(--k4n-secondary-color);
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-image: url("../../../assets/images/close.svg");
  mask-image: url("../../../assets/images/close.svg");
}

@media screen and (max-width: 768px) {
  .onboarding-modal-container {
    padding: 30px 20px;
    background-color: var(--k4n-primary-color);
    text-align: center;
  }
}
