@use "../../../assets/scss/colors";
@use "../../../assets/scss/typography";

.bg-gray {
  background-color: #e5e5e5;
  min-height: 100vh;
  max-width: 100vw;
}

.manage-container {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  padding: 30px 50px;
  margin: 20px 75px;
}

.k4n-input-label {
  margin: 10px 0 5px;
  color: var(--k4n-secondary-color);
}

.k4n-input {
  border-radius: 18px;
  border: var(--k4n-secondary-color) 1px solid;
  padding: 10px 20px;
  color: #333;
  flex: 1;
}

.k4n-input:disabled {
  opacity: 0.7;
  background-color: #ddd;
}

.manage-title {
  font-size: 1.8rem;
  font-family: typography.$font-bold;
  margin: 20px 0 10px;
  color: var(--k4n-primary-color);
}

.manage-title-signature {
  font-size: 1.2rem;
  font-family: typography.$font-bold;
  margin: 20px 0 10px;
  color: var(--k4n-primary-color);
}

.yellow-hr {
  height: 1px;
  background-color: var(--k4n-secondary-color);
  width: 100%;
  margin: 20px 0;
}

.yellow-link {
  color: var(--k4n-secondary-color);
  text-decoration: none;
  cursor: pointer;
  margin-left: auto;
  margin-right: 10px;
}

.yellow-link:hover {
  color: var(--k4n-secondary-color);
  text-decoration: underline;
}

.mg-left-none {
  margin-left: 0px !important;
}

.button-primary {
  padding: 10px 25px;
  background: var(--k4n-secondary-color);
  border-radius: 18px;
  border: none;
  text-align: right;
  color: var(--k4n-quaternary-color);
  font-family: typography.$font-bold;
  width: fit-content;
}

.button-primary:hover {
  opacity: 0.8;
}

.button-primary:disabled {
  opacity: 0.8;
  cursor: not-allowed;
  width: fit-content;
}

.button-primary-outlined {
  padding: 10px 25px;
  background: #ffffff00;
  border-radius: 18px;
  border: 1px solid var(--k4n-secondary-color);
  text-align: right;
  color: var(--k4n-secondary-color);
  font-family: 12px;
  width: fit-content;
}

.button-primary-outlined:hover {
  opacity: 0.8;
}

.button-primary-outlined:disabled {
  opacity: 0.8;
  cursor: not-allowed;
  width: fit-content;
}

.save-spinner-container {
  width: 2.85rem;
}
.save-spinner {
  margin: 0 auto;
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
  color: #fff;
}

@media screen and (max-width: 768px) {
  .manage-container {
    margin: 20px auto;
  }
}
