@use "../../../assets/scss/colors";
@use "../../../assets/scss/typography";

.op-page-container {
  background-color: #e5e5e5;
  width: 100vw;
  height: 100vh;
}

.op-management-container {
  width: 100%;
  display: flex;
  height: auto;
  padding: 50px;
  overflow: hidden;
  @media screen and (max-width: 768px) {
    padding: 20px;
  }

  .op-management-content {
    width: 100%;
    height: auto;
    background: #ffffff;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
    border-radius: 16px;
    padding: 50px;

    @media screen and (max-width: 768px) {
      padding: 20px;
    }

    .op-management-date-div {
      height: 50px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .op-date-text {
      font-family: typography.$font-bold;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      color: #0a0203;
    }
    .options {
      border-bottom: 1px solid #dadada;
      width: 100%;
      height: 53px;
      display: flex;

      @media screen and (max-width: 768px) {
        flex-direction: column;
        height: auto;
        border-bottom: none;
      }
    }

    .options-btn-mobile {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .option-all {
      background: #ffffff;
      border: #ffffff;
      margin-right: 20px;
      height: 40px;
    }
    .option-all:hover {
      background-color: var(--k4n-secondary-color);
      border-radius: 12px;
    }
    .op-management-option-btn {
      background-color: var(--k4n-secondary-color);
      font-family: typography.$font-bold;
      font-size: 14px;
      line-height: 120%;
      color: var(--k4n-quaternary-color);
      box-shadow: 0px 4px 12px rgb(0 0 0 / 8%);
      border-radius: 12px;
      border-width: 0;
      padding: 11.5px 16px;
      margin-right: 10px;
      width: fit-content;
      height: 45px;
    }

    .op-management-option-btn-disabled {
      background-color: transparent;
      font-family: typography.$font-bold;
      font-size: 14px;
      line-height: 120%;
      color: #737373;
      border-radius: 12px;
      border-width: 0;
      padding: 11.5px 16px;
      margin-right: 10px;
      width: fit-content;
      height: 45px;
    }

    .op-management-option-btn-disabled:hover {
      background-color: var(--k4n-secondary-color);
      color: var(--k4n-quaternary-color);
    }
    .date {
      padding-left: 10px;
      font-family: typography.$font-bold;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: var(--k4n-primary-light-color);
    }
    .op-manager {
      width: 100%;
      margin-top: 10px;
      margin-bottom: 70px;
      position: relative;
      display: flex;
      flex-direction: row;
    }
    .new-btn {
      width: max-content;
      margin-left: auto;
      button {
        white-space: nowrap;
        font-size: 14px;
        border-radius: 12px;
      }
      @media screen and (max-width: 768px) {
        margin-left: auto;
      }
    }

    .new-btn-mobile {
      button {
        width: 40px;
        height: 40px;
        padding: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
      }
    }

    .op-status {
      width: 16px;
      height: 16px;
      border-radius: 50%;
    }

    .ml-10 {
      margin-left: 10px;
    }

    .op-table {
      overflow: auto;
    }

    .table-borderless > tbody > tr > td,
    .table-borderless > tbody > tr > th,
    .table-borderless > tfoot > tr > td,
    .table-borderless > tfoot > tr > th,
    .table-borderless > thead > tr > td,
    .table-borderless > thead > tr > th {
      border-bottom: 1px solid rgba(10, 2, 3, 0.1);
    }

    .actions-col {
      gap: 16px;
    }

    .table-column-title {
      font-family: typography.$font-bold;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 120%;
      color: var(--k4n-primary-color);
      white-space: nowrap;
    }

    .op-pagination {
      width: max-content;
      margin: 0 auto;
      padding-top: 20px;
    }
  }
}
