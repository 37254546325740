@use "../../../../../assets/scss/colors";
@use "../../../../../assets/scss/typography";

.pix-copy-paste-container {
  width: 100%;
  display: flex;
  height: auto;
  position: relative;
  top: 56px;
  bottom: 56px;
  flex-direction: column;
}

.pix-copy-paste-content {
  position: relative;
  margin-top: 15px;
  width: 100%;
  height: auto;
  min-height: 409px;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  padding: 48px 68px;
}

.pix-copy-paste-title {
  width: 100%;
  border-bottom: 1px solid #dadada;
}

.pix-copy-paste-h1 {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  color: var(--k4n-primary-color);
  margin-bottom: 24px;
}

.pix-copy-paste-txt {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  color: #0a0203;
  margin-bottom: 24px;
}

.pix-copy-paste-input {
  width: 384px;
  height: 55px;
  margin: 24px 0;
  border: 1px solid var(--k4n-secondary-color);
  border-radius: 16px;
  padding-left: 30px;
  padding-right: 30px;
}

.pix-copy-paste-input::placeholder {
  color: #0a0203;
  outline: none;
  border-radius: 16px;
}

.pix-copy-paste-input::-webkit-input-placeholder {
  color: #0a0203;
  outline: none;
  border-radius: 16px;
}

.pix-copy-paste-input::-moz-placeholder {
  color: #0a0203;
  outline: none;
  border-radius: 16px;
}

.pix-copy-paste-input:-ms-input-placeholder {
  color: #0a0203;
  outline: none;
  border-radius: 16px;
}

.pix-copy-paste-input:-moz-placeholder {
  color: #0a0203;
  outline: none;
  border-radius: 16px;
}

textarea:focus,
.pix-copy-paste-input:focus {
  color: #0a0203;
  border-radius: 16px;
  outline: none;
}

.pix-copy-paste-input:focus::placeholder {
  color: transparent;
}

.pix-border-top {
  border-top: 1px solid #dadada;
}
