@use "../../../assets/scss/colors";
@use "../../../assets/scss/typography";

.change-address-container {
  background-color: var(--k4n-primary-color);
  width: 100%;
  height: 100%;
  overflow: scroll;
}

.change-address-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  padding-bottom: 40px;
  margin: 10px;
}

.change-address-h1 {
  padding-top: 10%;
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 120%;
  text-align: center;
  color: var(--k4n-secondary-color);
}

.change-address-input-label {
  color: var(--k4n-secondary-color);
  font-family: typography.$font-bold;
  font-size: 0.9rem;
}

.change-address-input {
  display: block;
  color: #0a0203;
  font-family: typography.$font-main;
  caret-color: var(--k4n-secondary-color);
  border: none;
  border-radius: 28px;
  background-color: var(--k4n-primary-light-color);
  font-size: 1rem;
  outline: none;
  padding: 10px 20px;
  width: 100%;
  margin: 8px 0 16px;
}

.change-address-input:disabled {
  // background-color: var(--k4n-primary-color);
  padding: 10px 20px;
  background-color: #6291a4;
}

.change-address-btn-link {
  color: var(--k4n-secondary-color);
  margin-right: 5px;
  cursor: pointer;
  text-decoration: underline;
  background-color: transparent;
  border: none;
  outline: none;
  white-space: nowrap;
}

.change-address-btn-next {
  background-color: var(--k4n-secondary-color);
  background-image: url("../../../assets/images/arrow.svg");
  background-size: 36px 20px;
  background-repeat: no-repeat;
  background-position: center;
  width: 81px;
  height: 60px;
  outline: none;
  border: none;
  cursor: pointer;
  border-radius: 25px;
}

.change-address-btn-next.loading {
  background-image: url("../../../assets/images/spinner-white.svg");
  background-size: 50px 50px;
}

.change-address-row {
  // --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  // margin-right: 20px;
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.change-address-margin {
  margin-right: 10px;
}

.change-address-div {
  width: 570px;
}

@media screen and (max-width: 768px) {
  .change-address-div {
    width: 100%;
    width: -moz-available; /* WebKit-based browsers will ignore this. */
    width: -webkit-fill-available;
    width: fill-available;
    display: flex;
    justify-content: space-between;
  }
  .change-address-input {
    background-color: var(--k4n-primary-light-color);
  }
  .change-address-input:disabled {
    // background-color: var(--k4n-primary-color);
    background-color: #6291a4;
    padding: 10px 20px;
  }
}
