@use "../../../../../../../assets/scss/colors";
@use "../../../../../../../assets/scss/typography";

.bc-value-mobile {
  display: flex;
  margin-top: 3px;
  align-items: center;
}

.pt-10 {
  padding-top: 10px;
}
