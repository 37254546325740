@use "../../../../../../assets/scss/colors";
@use "../../../../../../assets/scss/typography";

.panel-mobile-content {
  margin-top: 20px;
  width: 100%;
  background: #ffffff;
  padding: 10% 10%;
  border-radius: 28px 28px 0 0;
  height: 100%;
}

.panel-mobile-title {
  width: 100%;
  height: 80px;
  border-bottom: 1px #dadada solid;
  display: flex;
  align-items: center;
}

.title-mobile-blue {
  font-family: typography.$font-bold;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 22px;
  color: var(--k4n-primary-light-color);
}

.transfer-mobile-value-div {
  width: 100%;
  height: 100px;
  // border-bottom: 1px #dadada solid;
  display: flex;
  align-items: center;
  gap: 20px;
}

.transfer-mobile-value-box {
  width: 204px;
  height: 55px;
  border: 1px solid transparent;
  border-radius: 16px;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  background-color: var(--k4n-secondary-light-color);
  align-items: center;
}

.transfer-mobile-value-label {
  color: black !important;
  border: none;
  font-family: typography.$font-main;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
}

.transfer-mobile-value-input {
  color: black !important;
  border: none;
  width: 100%;
  font-family: typography.$font-main;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  background-color: transparent;
}

.transfer-mobile-value-input::placeholder {
  font-family: typography.$font-main;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  color: #0a0203;
}

.info-span-bold-16 {
  font-family: typography.$font-main;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  color: #0a0203;
}

.info-span-grey-16 {
  font-family: typography.$font-main;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  color: #737373;
}

.transfer-mobile-step-1-btn {
  display: flex;
  height: 100px;
  align-items: flex-end;
  justify-content: flex-end;
}

.transfer-mobile-submit-btn {
  width: 95px;
  height: 70px;
  background: var(--k4n-secondary-color);
  border-radius: 28px;
  border-color: transparent;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.transfer-mobile-submit-btn:hover {
  opacity: 0.9;
}

.transfer-mobile-submit-btn-txt {
  font-family: typography.$font-bold;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  color: var(--k4n-primary-color);
}

.transfer-mobile-close-btn {
  width: 18px;
  height: 18px;
  margin-top: 5px;
  outline: none;
  border: none;
  color: var(--k4n-secondary-color);
  background-color: var(--k4n-secondary-color);
  mask-size: contain;
  mask-repeat: no-repeat;
  -webkit-mask-image: url("../../../../../../assets/images/close.svg");
  mask-image: url("../../../../../../assets/images/close.svg");
}

.transfer-mobile-wrapper {
  background-color: transparent;
  margin: 0;
  width: 100%;
  padding: 0;
  height: 100%;
}

.transfer-mobile-header {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--k4n-primary-color);
}
